//@ts-nocheck
import React from 'react';
import { Card, Row, Col, Image } from 'react-bootstrap';
import * as S from './styles';
import { toLowerCase } from 'js-convert-case';
const getCountryISO2 = require('country-iso-3-to-2');

export interface IAppCardList {
  name: string;
  value: string;
}

export interface IAppCardWidgetProps {
  appCardId: string;
  appCardImage?: string;
  appCardTitle?: string;
  appCardISO3?: string;
  appCardDescription?: string;
  appCardList?: IAppCardList[];
  onAppCardClick?(): void;
  hoverColor: string;
  backgroundColor: string;
  backgroundTitle?: any;
}

const getFirstLetterOfEachWord = (text: string) =>
  text?.match(/\b\w/g)?.join('');

export const AppCardWidget = ({
  appCardId,
  appCardTitle,
  appCardImage,
  appCardISO3,
  appCardDescription,
  appCardList,
  backgroundColor,
  hoverColor,
  backgroundTitle,
  onAppCardClick,
}: IAppCardWidgetProps) => {
  const _renderImageIf = () => {
    let count = 0;
    if (appCardImage) {
      return (
        <Image
          src={appCardImage}
          width={100}
          height={'auto'}
          style={{ objectFit: 'contain', marginTop: 0 }}
        />
      );
    }
    if (appCardISO3) {
      const flagSize = 85;
      appCardISO3 = getCountryISO2(appCardISO3);
      return (
        <div>
          <span
            className={`fi fi-${toLowerCase(appCardISO3)} fis`}
            style={{ fontSize: `${flagSize}px` }}
          ></span>
        </div>
      );
    }
    if (appCardTitle) {
      return (
        <div
          className="avatar d-flex align-items-center justify-content-center fw-bold rounded bg-secondary"
          style={S.AppCardTitleImageStyle}
        >
          <span>{getFirstLetterOfEachWord(appCardTitle)}</span>
        </div>
      );
    }
    return null;
  };
  let country: string = 'IN';

  return (
    <S.AppCard
      border="0"
      onClick={onAppCardClick?.bind(null, appCardTitle)}
      background={
        backgroundTitle === appCardTitle ? backgroundColor : '#fafafa'
      }
      hoverColor={
        backgroundTitle === appCardTitle ? backgroundColor : hoverColor
      }
    >
      <article className={'article'}>
        <Card.Body>
          <Row>
            <Col xs={6} className="align-self-start">
              <div>{_renderImageIf()}</div>
            </Col>
            <Col xs={6}>
              {appCardTitle && (
                <h5 className="mb-0 mt-0" style={S.HeadingStyle}>
                  {appCardTitle}
                </h5>
              )}
              {appCardDescription && (
                <S.DescriptionStyle>{appCardDescription}</S.DescriptionStyle>
              )}
              <div
                className="p-2 mt-2 d-flex justify-content-between rounded"
                style={S.SectionContainer}
              >
                {appCardList?.map((list, index) => (
                  <div
                    key={`app-card-kv-${index}`}
                    className="d-flex flex-column"
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <span style={S.SectionNameStyle}>{list?.name}</span>
                    <span className="fw-bold">{list?.value}</span>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </article>
    </S.AppCard>
  );
};

AppCardWidget.defaultProps = {};

export default AppCardWidget;
