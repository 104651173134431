export const terminateWpConfig = {
  FIELDS: [
    {
      type: 'select',
      compProps: {
        label: 'Choose Reason for Termination',
        id: 'reasonForTermination',
        options: [
          {
            label: 'Position no longer needs to be filled',
            id: 'PositionNoLongerNeedsToBeFilled',
          },
          {
            label: 'Applicant is no longer available.',
            id: 'ApplicantIsNoLongerAvailable.',
          },
          {
            label: 'Violation of employment terms and conditions',
            id: 'ViolationOfEmploymentTermsAndConditions',
          },
          {
            label: 'Failure to take up employment',
            id: 'FailureToTakeUpEmployment',
          },
          {
            label: 'Employee has changed employment',
            id: 'EmployeeHasChangedEmployment',
          },
        ],
        required: true,
      },
    },
    {
      type: 'textarea',
      compProps: {
        id: 'reason',
        label: 'Comments',
        placeholder: 'Enter the comments',
        errorText: 'comments is required',
      },
    },
  ],
  layout: [['reasonForTermination'], ['reason']],
};
