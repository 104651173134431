import rawCountries from './rawCountries';

function createCountryObject(countries: any, value: any) {
  const countryObject: any = {};
  countries.forEach((country: any) => {
    countryObject[country] = value;
  });
  return countryObject;
}

// Filter out Jamaica from rawCountries
const countriesOtherThanJamaica = rawCountries
  .filter((country: any) => country[0] !== 'Jamaica')
  .map((country: any) => country[0]);

const countryObject = createCountryObject(countriesOtherThanJamaica, [
  ['countryNaturalizationCertificate'],
]);
export const MarriageExemptiConfig = {
  nextStepLabel: 'Next',
  submitButtonText: 'Submit',
  previewLabel: '',
  userFormTitle: 'Enter Applicant Information',
  marriageExmpLabel: 'Marriage Exemption',
  marriageExmpRenewalLabel: 'Marriage Exemption - Renewal',
  // csmeAppealLabel: 'Marriage Exemption - Appeal',
  successIcon: 'https://i.ibb.co/RNvQN2X/success.png',
  errorIcon: 'https://i.ibb.co/GM7nsgp/icons8-error-48.png',
  // csmeSubLabel1: 'Enter Prospective Employee Details',
  // csmeSubLabel2: 'Enter Employer Details',
  acknowledgementText:
    'I certify to the best of my knowledge and belief, that the above information is correct and accept the responsibility for the support and repatriation expenses of the applicant and his family should the need arise.',
  progressBarSteps: [
    'Marriage Exemption',
    'Marriage Exemption',
    'Marriage Exemption',
    'Marriage Exemption',
    'Marriage Exemption',
    'Acknowledgement',
  ],
  fields: [
    [
      {
        type: 'text',
        compProps: {
          label: 'Last Name',
          id: 'lastName',
          placeholder: 'Enter Last Name(no special characters, no spaces)',
          required: false,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'First Name/Given Name',
          id: 'firstName',
          placeholder:
            'Enter First Name/Given Name(no special characters, no spaces)',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Middle Name',
          id: 'middleName',
          placeholder: 'Enter Middle Name',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Maiden name',
          id: 'maidenName',
          placeholder: 'Enter Maiden Name',
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Date of Birth',
          id: 'dateOfBirth',
          isAdult: true,
          required: true,
        },
      },
      {
        type: 'numbertext',
        compProps: {
          label: 'Taxpayer Registration Number (TRN)',
          id: 'expatriateTrn',
          placeholder: 'Enter Taxpayer Registration Number (TRN) ex-123456789',
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Country of Birth',
          id: 'placeOfBirth',
          placeholder: 'Enter Country of Birth',
          required: true,
        },
      },

      {
        type: 'countrySelect',
        compProps: {
          label: 'Nationality',
          id: 'nationality',
          placeholder: 'Enter Nationality',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'expPassportNo',
          placeholder:
            'Enter Passport Number(no special characters, no spaces)',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Bio-Data Page of Passport Copy',
          id: 'biodatapassport',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Birth Certificate',
          id: 'expbirthcertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Passport Issue Date',
          id: 'passportIssuanceDate',
          isPast: true,
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Passport Expiry Date',
          id: 'passportExpiryDate',
          isFuture: true,
          required: true,
          isPassport: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label: 'Address',
          id: 'expaddress1',
          placeholder: 'Enter Address',
          required: true,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Select document type to support name change',
          placeholder: 'Select document type',
          id: 'nameChangeDocType',
          required: true,
          options: [
            {
              label: 'A deed poll',
              id: 'deedpoll',
            },

            {
              label: 'Other documents',
              id: 'other',
            },
          ],
        },
      },
      // {
      //   type: 'text',
      //   compProps: {
      //     label: 'Postal Code',
      //     id: 'mailingPostalCode',
      //     placeholder: 'Enter Postal Code',
      //     required: true,
      //   },
      // },
      // {
      //   type: 'phoneNumber',
      //   compProps: {
      //     label: 'Telephone Contact',
      //     id: 'userTelephoneNumber',
      //     placeholder: 'Enter Telephone Number',
      //     required: true,
      //   },
      // },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'A deed poll',
          id: 'deedPoll1',
          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Other document to support name change',
          id: 'nameChange1',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'expEmail',
          placeholder: 'Enter Email address',
          required: true,
          // readOnly:true
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Phone Number (Mobile)',
          id: 'expPhoneNumber',
          placeholder: 'Enter Phone Number(Mobile)',
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          id: 'gender',
          direction: 'horizontal',
          label: 'Sex',
          value: 'Male',
          options: [
            {
              id: 'Male',
              label: 'Male',
            },
            {
              id: 'Female',
              label: 'Female',
            },
          ],
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Occupation',
          id: 'expOccupation',
          placeholder: 'Enter Occupation',
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          id: 'isLegalNameChange',
          direction: 'horizontal',
          label: 'Has there been a legal name change?',
          value: 'yes',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Were you Previously Married Yes/No',
          id: 'previouslyMarried',
          direction: 'horizontal',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Photograph',
          id: 'applicantphotograph',

          maxFiles: 1,
          supportedFormat: ['image/png', 'image/jpeg', 'image/jpg'],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Police Clearance Report',
          id: 'policeClearanceReport',
          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Divorce Certificate',
          id: 'divorceCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Death Certificate',
          id: 'deathCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'If Yes, Were you Divorced or Widowed?',
          id: 'divorcedOrWidowed',
          direction: 'horizontal',
          options: [
            {
              id: 'Divorced',
              label: 'Divorced',
            },
            {
              id: 'Widowed',
              label: 'Widowed',
            },
          ],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label:
            'Have you ever been refused a visa or denied entry to any country Yes/No',
          id: 'refusedVisa',
          direction: 'horizontal',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },

      //************************************** */

      {
        type: 'dynamicFields',
        compProps: {
          id: 'refusedvisadetail',
          cardType: 'Refusion Detail',
          label: 'Refusion Detail',
          required: true,
          attributeForm: {
            modalTitle: 'Add Refusion Info',
            editTitle: 'Edit Refusion Info',
            fields: [
              {
                type: 'countrySelect',
                compProps: {
                  label: 'Country',
                  id: 'refusedVisaCountry',
                  placeholder: 'Enter Country',
                  required: true,
                },
              },
              {
                type: 'textarea',
                compProps: {
                  rows: 1,
                  label: 'If Yes, state reason',
                  id: 'refusedVisaReason',
                  placeholder: 'State reason',
                  required: true,
                },
              },
            ],
            layout: [['refusedVisaCountry'], ['refusedVisaReason']],
          },
        },
      },
      //**************************************** */
      {
        type: 'radio',
        compProps: {
          label: 'Have you ever been refused a visa for Jamaica Yes/No',
          id: 'refusedVisaJamaica',
          direction: 'horizontal',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          rows: 1,
          label: 'If Yes, give details',
          id: 'refusedVisaJamaicaReason',
          placeholder: 'Enter Details',
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Do you have any criminal convictions in any country Yes/No',
          id: 'criminalConvictions',
          direction: 'horizontal',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          rows: 1,
          label: 'If Yes, state details',
          id: 'ifCriminalConviction',
          placeholder: 'State details',
          required: true,
        },
      },

      {
        type: 'radio',
        compProps: {
          label: 'Are you currently employed Yes/No',
          id: 'currentlyEmployed',
          direction: 'horizontal',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          rows: 1,
          label: 'If Yes, What is your monthly Salary?',
          id: 'monthlySalary',
          placeholder: 'Monthly salary',
          currency: 'J$',
          required: true,
        },
      },

      {
        type: 'radio',
        compProps: {
          label:
            'Are you Financially capable to Facilitate your Living accommodation in Jamaica',
          id: 'finCapable',
          direction: 'horizontal',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'If yes, upload Financial Statement/Financial Assets',
          id: 'financialStatement',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
    ],
    [
      //jamaica's infomartion config starts here

      {
        type: 'text',
        compProps: {
          label: 'Last Name',
          id: 'jamaicanLastName',
          placeholder: 'Enter Last Name',
          required: false,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'First Name/Given Name',
          id: 'jamaicanFirstName',
          placeholder: 'Enter First Name/Given Name',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Middle Name',
          id: 'jamaicanMiddleName',
          placeholder: 'Enter Middle Name',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Maiden name',
          id: 'jamaicanMaidenName',
          placeholder: 'Enter Maiden Name',
        },
      },

      {
        type: 'countrySelect',
        compProps: {
          label: 'Country of Birth',
          id: 'jamaicanPlaceOfBirth',
          placeholder: 'Enter Country of Birth',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Naturalization Certificate',
          id: 'countryNaturalizationCertificate',
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
          maxFiles: 1,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Nationality',
          id: 'jamaicanNationality',
          placeholder: 'Enter Nationality',
          readOnly: true,
          disabled: true,
        },
      },

      {
        type: 'select',
        compProps: {
          label: 'Type of Identification: ',
          id: 'typeOfIndentification',
          options: [
            {
              label: 'Passport',
              id: 'PASSPORT',
            },
            {
              label: 'Driver`s Licence ',
              id: 'LISCENSE',
            },
            {
              label: 'National ID',
              id: 'NATIONAL_ID',
            },
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Identification Copy',
          id: 'jamIdentificationProof',
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
          maxFiles: 1,
        },
      },

      {
        type: 'datepicker',
        compProps: {
          label: 'Date of Birth',
          id: 'jamaicanDateOfBirth',
          isPast: true,
          isAdult: true,
          required: true,
        },
      },

      {
        type: 'numbertext',
        compProps: {
          label: 'Taxpayer Registration Number (TRN)',
          id: 'jamaicanTrn',
          placeholder: 'Enter Taxpayer Registration Number (TRN)',
          required: true,
        },
      },
      {
        type: 'numbertext',
        compProps: {
          label: 'NIS No (National Insurance Scheme)',
          id: 'jamaicanNIS',
          placeholder: 'Enter NIS No',
          required: true,
        },
      },

      {
        type: 'radio',
        compProps: {
          id: 'jamaicanSex',
          direction: 'horizontal',
          label: 'Sex',
          value: 'Male',
          // readOnly: true,
          // disabled: true,
          options: [
            {
              id: 'Male',
              label: 'Male',
            },
            {
              id: 'Female',
              label: 'Female',
            },
          ],
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'Occupation',
          id: 'jamaicanOccupation',
          placeholder: 'Enter Occupation',
          required: true,
        },
      },

      {
        type: 'radio',
        compProps: {
          label: 'Previously Married Yes/No',
          id: 'jamaicanPreviouslyMarried',
          direction: 'horizontal',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Citizenship Proof',
          id: 'citizenshipProof',
          direction: 'horizontal',
          options: [
            {
              id: 'bc',
              label: 'Birth Certificate',
            },
            {
              id: 'nc',
              label: 'Naturalization Certificate',
            },
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Birth Certificate',
          id: 'birthCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Naturalization Certificate',
          id: 'natCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label: 'Street Address 1',
          id: 'jamaicanaddress1',
          placeholder: 'Enter Address 1',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Street Address 2',
          rows: 1,
          id: 'address2',
          placeholder: 'Enter Address 2',
          required: false,
        },
      },
      // {
      //   type: 'select',
      //   compProps: {
      //     label: 'Parish',
      //     id: 'parish',
      //     placeholder: 'Enter Parish',
      //     required: true,
      //     options: [
      //       {
      //         label: 'Clarendon',
      //         id: 'Clarendon',
      //       },
      //       {
      //         label: 'Hanover',
      //         id: 'Hanover',
      //       },
      //       {
      //         label: 'Kingston',
      //         id: 'Kingston',
      //       },
      //       {
      //         label: 'Manchester',
      //         id: 'Manchester',
      //       },
      //       {
      //         label: 'Portland',
      //         id: 'Portland',
      //       },
      //       {
      //         label: 'Saint Andrew',
      //         id: 'Saint Andrew',
      //       },
      //       {
      //         label: 'Saint Ann',
      //         id: 'Saint Ann',
      //       },
      //       {
      //         label: 'Saint Catherine',
      //         id: 'Saint Catherine',
      //       },
      //       {
      //         label: 'Saint Elizabeth',
      //         id: 'Saint Elizabeth',
      //       },
      //       {
      //         label: 'Saint Elizabeth',
      //         id: 'Saint Elizabeth',
      //       },
      //       {
      //         label: 'Saint Mary',
      //         id: 'Saint Mary',
      //       },
      //       {
      //         label: 'Saint Thomas',
      //         id: 'Saint Thomas',
      //       },
      //       {
      //         label: 'Trelawny',
      //         id: 'Trelawny',
      //       },
      //       {
      //         label: 'Westmoreland',
      //         id: 'Westmoreland',
      //       },
      //     ],
      //   },
      // },
      {
        type: 'select',
        compProps: {
          label: 'Parish',
          id: 'jamaicanparish',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'City/Town',
          id: 'jamaicancity',
          placeholder: 'Enter City/Town',
          required: true,
          options: [],
        },
      },
      // {
      //   type: 'select',
      //   compProps: {
      //     label: 'City',
      //     id: 'city',
      //     placeholder: 'Enter City',
      //     required: true,
      //     options: [],
      //   },
      // },

      {
        type: 'text',
        compProps: {
          label: 'Postal Code',
          id: 'jamaicanpostalCode',
          placeholder: 'Enter Postal Code',
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'If Yes, Were you Divorced or Widowed?',
          id: 'jamaicanDivorcedOrWidowed',
          direction: 'horizontal',
          options: [
            {
              label: 'Divorced',
              id: 'Divorced',
            },
            {
              label: 'Widowed',
              id: 'Widowed',
            },
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Divorce Certificate',
          id: 'jamaicandivorceCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Death Certificate',
          id: 'jamaicandeathCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Jamaican Spouse Photograph',
          id: 'jamaicanspousephotograph',

          maxFiles: 1,
          supportedFormat: ['image/png', 'image/jpeg', 'image/jpg'],
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'Jamaican Birth Certificate No',
          id: 'birthCertificateNo',
          placeholder: 'Enter Birth Certificate No issued in Jamaica',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Jamaican Naturalization Certificate No',
          id: 'naturalizationCertificateNo',
          placeholder: 'Enter Naturalization Certificate No issued in Jamaica',
          required: true,
        },
      },

      {
        type: 'radio',
        compProps: {
          label: 'Are you currently employed Yes/No',
          id: 'jamaicanCurrentlyEmployed',
          direction: 'horizontal',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'Employer Name',
          id: 'jamaicanEmployerName',
          placeholder: 'Enter Employer Name',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label: 'Address of Employer',
          id: 'addressOfEmployer',
          placeholder: 'Address of Employer',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'What is your monthly Salary?',
          id: 'jamaicanMonthlySalary',
          placeholder: 'Enter Monthly salary',
          currency: 'J$',
          required: true,
        },
      },

      {
        type: 'radio',
        compProps: {
          label: 'Do you receive Income from any other Source',
          id: 'jamaicanOtherIncome',
          direction: 'horizontal',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
        },
      },

      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'jamaicanEmailAddress',
          placeholder: 'Enter Email address',
          required: true,
        },
      },

      {
        type: 'phoneNumber',
        compProps: {
          label: 'Contact Number',
          id: 'jamaicanContactNumber',
          placeholder: 'Enter Contact Number',
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          id: 'jamaicanFinCapable',
          direction: 'horizontal',
          label:
            'Are you Fiancially Capable to Facilitate your Spouse`s living Accommodation in Jamaica Yes/No',
          value: 'yes',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },

      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label:
            'If No, state how your Spouse`s Living Accommodations will be Supported.',
          id: 'jamaincaSpouseLiving',
          placeholder:
            'State how your Spouse`s Living Accommodations will be Supported',
          required: true,
        },
      },
    ],
    [
      //marriage info fields start

      {
        type: 'numbertext',
        compProps: {
          label: 'Marriage Certificate No',
          id: 'marriageCertificateNo',
          placeholder: 'Enter Marriage Certificate No',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Marriage Certificate',
          id: 'marriageCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'image/jpeg',
            'application/pdf',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Marriage Affidavit',
          id: 'marriageAffidavit',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'image/jpeg',
            'application/pdf',
            'image/jpg',
          ],
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'Name of Marriage Officer',
          id: 'marriageOfficerName',
          placeholder: 'Enter Name of Marriage Officer',
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'Groom`s Name',
          id: 'groomsName',
          placeholder: 'Enter Groom`s Name',
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'Bride`s name',
          id: 'bridesName',
          placeholder: 'Enter Bride`s Name',
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'Place of Marriage',
          id: 'placeOfMarriage',
          placeholder: 'Place of Marriage',
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Date of Marriage',
          id: 'dateOfMarriage',
          required: true,
          isPast: true,
          isMarriage: true,
        },
      },

      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label: 'Address of the Couple',
          id: 'addressOfCouple',
          placeholder: 'Address of the Couple',
          required: true,
        },
      },

      {
        type: 'select',
        compProps: {
          label: 'Parish',
          id: 'coupleParish',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'City/Town',
          id: 'coupleCity',
          placeholder: 'Enter City/Town',
          required: true,
          options: [],
        },
      },
    ],
  ],
  layout: [
    {
      first: {
        label: 'Expatriate Information',
        data: [
          ['lastName', 'firstName'],
          ['middleName'],
          ['dateOfBirth', 'expatriateTrn'],
          ['placeOfBirth', 'nationality'],
          ['expbirthcertificate'],
          ['applicantphotograph'],
          ['policeClearanceReport'],
          ['expPassportNo'],
          ['biodatapassport'],
          ['passportIssuanceDate', 'passportExpiryDate'],
          ['expaddress1'],
          ['expEmail', 'expPhoneNumber'],
          ['gender', 'expOccupation'],
          ['isLegalNameChange'],
          ['previouslyMarried'],
          ['refusedVisa'],
          ['refusedVisaJamaica'],
          ['criminalConvictions'],
          ['currentlyEmployed'],
          ['finCapable'],
        ],
      },
      second: {
        label: 'Jamaican`s Information',
        data: [
          ['jamaicanLastName', 'jamaicanFirstName'],
          ['jamaicanMiddleName'],
          ['jamaicanPlaceOfBirth', 'jamaicanNationality'],
          ['typeOfIndentification', 'jamaicanDateOfBirth'],
          // ['jamaicanaddress1'],
          ['jamaicanparish', 'jamaicancity'],
          ['jamaicanTrn', 'jamaicanNIS'],
          ['jamaicanSex', 'jamaicanOccupation'],
          ['jamaicanspousephotograph'],
          ['jamaicanPreviouslyMarried'],
          ['citizenshipProof'],
          ['jamaicanCurrentlyEmployed'],
          ['jamaicanMonthlySalary', 'jamaicanOtherIncome'],
          ['jamaicanEmailAddress', 'jamaicanContactNumber'],
          ['jamaicanFinCapable'],
        ],
      },
      third: {
        label: 'Marriage Details',
        data: [
          ['marriageCertificateNo', 'marriageOfficerName'],
          ['marriageCertificate'],
          ['marriageAffidavit'],
          ['placeOfMarriage', 'dateOfMarriage'],
          ['addressOfCouple'],
          ['coupleParish', 'coupleCity'],
        ],
      },
    },
  ],

  dynamicLayout: {
    jamaicanPlaceOfBirth: createCountryObject(countriesOtherThanJamaica, [
      ['countryNaturalizationCertificate'],
    ]),
    previouslyMarried: {
      yes: [['divorcedOrWidowed']],
    },
    isLegalNameChange: {
      yes: [['nameChangeDocType']],
    },
    nameChangeDocType: {
      deedpoll: [['deedPoll1']],
      other: [['nameChange1']],
    },
    divorcedOrWidowed: {
      Divorced: [['divorceCertificate']],
      Widowed: [['deathCertificate']],
    },
    gender: {
      Female: [['maidenName']],
    },
    jamaicanSex: {
      Female: [['jamaicanMaidenName']],
    },

    refusedVisa: {
      yes: [['refusedvisadetail']],
    },

    refusedVisaJamaica: {
      yes: [['refusedVisaJamaicaReason']],
    },

    criminalConvictions: {
      yes: [['ifCriminalConviction']],
    },

    currentlyEmployed: {
      yes: [['monthlySalary']],
    },
    citizenshipProof: {
      bc: [['birthCertificateNo'], ['birthCertificate']],
      nc: [['naturalizationCertificateNo'], ['natCertificate']],
    },

    jamaicanPreviouslyMarried: {
      yes: [['jamaicanDivorcedOrWidowed']],
    },
    jamaicanDivorcedOrWidowed: {
      Divorced: [['jamaicandivorceCertificate']],
      Widowed: [['jamaicandeathCertificate']],
    },

    jamaicanCurrentlyEmployed: {
      yes: [['jamaicanEmployerName', 'addressOfEmployer']],
    },
    jamaicanFinCapable: {
      no: [['jamaincaSpouseLiving']],
    },
    finCapable: {
      yes: [['financialStatement']],
    },

    typeOfIndentification: {
      PASSPORT: [['jamIdentificationProof']],
      NATIONAL_ID: [['jamIdentificationProof']],
      LISCENSE: [['jamIdentificationProof']],
    },

    purposeOfRelocation: {
      SELF_EMPLOYMENT: [['natureOfProfessionalActivity']],
      EMPLOYMENT: [['occupation'], ['userNatureOfBusiness']],
    },
    isDependentExist: {
      yes: [['noOfChildren'], ['dependentInfo']],
    },
    employmentStatus: {
      STUDENT: [
        ['educationType'],
        ['degree'],
        ['institutionName'],
        ['institutionAddress'],
        ['studentTelephone'],
        ['studentEmailAddress'],
      ],
      EMPLOYMENT: [
        ['employerName'],
        ['employedAddress1'],
        ['employedAddress2'],
        ['employedParish'],
        ['employedCity'],
        ['employedCountry'],
        ['expPhoneNumber'],
        ['employedEmailAddress'],
        ['employedNatureOfBusiness'],
      ],
      SELF_EMPLOYMENT: [
        ['selfAddress1'],
        ['selfAddress2'],
        ['selfParish'],
        ['selfCity'],
        ['selfCountry'],
      ],
    },
  },
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          firstName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]{2,}([ ]+[a-zA-Z]+)*$',
              errorMessage: 'Name is invalid.',
            },
          ],
          lastName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]{2,}([ ]+[a-zA-Z]+)*$',
              errorMessage: 'lastname is not valid',
            },
          ],
          jamaicanTrn: [
            {
              type: 'matches',
              pattern: '^[0-9]{9}$',
              errorMessage: 'TRN should contain 9 numbers.',
            },
          ],
          expatriateTrn: [
            {
              type: 'matches',
              pattern: '^[0-9]{9}$',
              errorMessage: 'TRN should contain 9 numbers.',
            },
          ],
          expPassportNo: [
            {
              type: 'matches',
              pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
              errorMessage: 'Passport is not valid',
            },
          ],
          naturalizationCertificateNo: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z0-9]{1,}$',
              errorMessage: 'Naturalization certificate is invalid',
            },
          ],
          birthCertificateNo: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z0-9]{1,}$',
              errorMessage: 'birth certificate is invalid',
            },
          ],
        },
      },
    ],
  },
  expatraiteDocuments: {
    financialStatement: 'FINANCIAL_STATEMENT_PROOF',
    biodatapassport: 'PASSPORT_PROOF',
    expbirthcertificate: 'BIRTH_CERTIFICATE_PROOF',
    divorceCertificate: 'DIVORCE_CERTIFICATE_PROOF',
    applicantphotograph: 'APPLICANT_PHOTOGRAPH',
    deathCertificate: 'PREVIOUS_SPOUSE_DEATH_CERTIFICATE',
    policeClearanceReport: 'POLICE_CLEARANCE_CERTIFICATE',
    deedPoll1: 'DEED_POLL_COPY',
    nameChange1: 'NAME_CHANGE_PROOF',
  },
  jamaiacanDocuments: {
    jamaicandivorceCertificate: 'JAMAICAN_DIVORCE_CERTIFICATE',
    jamaicandeathCertificate: 'JAMAICAN_PREVIOUS_SPOUSE_DEATH_CERTIFICATE',
    jamIdentificationProof: 'IDENTITY_COPY_PROOF',
    jamaicanspousephotograph: 'JAMAICAN_SPOUSE_PHOTOGRAPH',
    natCertificate: 'NATUARALIZATION_CERTIFICATE',
    birthCertificate: 'JAMAICAN_BIRTH_CERTIFICATE',
  },
  marriageDocuments: {
    marriageCertificate: 'MARRIAGE_CERTIFICATE_PROOF',
    marriageAffidavit: 'MARRIAGE_AFFIDAVIT',
  },
  availableDocuments: {
    deathCertificate: 'PREVIOUS_SPOUSE_DEATH_CERTIFICATE',
    jamaicandeathCertificate: 'JAMAICAN_PREVIOUS_SPOUSE_DEATH_CERTIFICATE',
    jobTitleProof: 'JOB_TITLE_PROOF',
    taxCompilance: 'TAX_COMPILANCE',
    applicantphotograph: 'APPLICANT_PHOTOGRAPH',
    divorceCertificate: 'DIVORCE_CERTIFICATE_PROOF',
    jamaicandivorceCertificate: 'JAMAICAN_DIVORCE_CERTIFICATE',
    expbirthcertificate: 'BIRTH_CERTIFICATE_PROOF',
    byAdvertisementFile: 'BY_ADVERTISEMENT_FILE',
    stepTakenProof: 'STEP_TAKEN_PROOF',
    signatureProof: 'EMPLOYER_SIGNATURE',
    birthCertificateProof: 'BIRTH_CERTIFICATE_PROOF',
    nationalityProof: 'NAIONALITY_PROOF',
    occupationProof: 'OCCUPATION_PROOF',
    passportProof: 'PASSPORT_PROOF',
    qualificationProof: 'QUALIFICATION_PROOF',
    workExperienceProof: 'WORK_EXPERIENCE_PROOF',
    photograph: 'PHOTOGRAPH',
    policeClearanceCertificate: 'POLICE_CLEARANCE_CERTIFICATE',
    resumeProof: 'RESUME_PROOF',
    coveringLetterProof: 'COVERING_LETTER_PROOF',
    previousEmployerProof: 'PREVIOUS_EMPLOYER_PROOF',
    signatureDep: 'APPLICANT_SIGNATURE',
    previousEmployerProofDep: 'PREVIOUS_EMPLOYER_PROOF_DEP',
    candidateResumes: 'CANDIDATE_RESUME',
    financialStatement: 'FINANCIAL_STATEMENT_PROOF',
    jamIdentificationProof: 'IDENTITY_COPY_PROOF',
    marriageCertificate: 'MARRIAGE_CERTIFICATE_PROOF',
    marriageAffidavit: 'MARRIAGE_AFFIDAVIT',
    natCertificate: 'NATUARALIZATION_CERTIFICATE',
    birthCertificate: 'JAMAICAN_BIRTH_CERTIFICATE',
    biodatapassport: 'PASSPORT_PROOF',
    jamaicanspousephotograph: 'JAMAICAN_SPOUSE_PHOTOGRAPH',
    policeClearanceReport: 'POLICE_CLEARANCE_CERTIFICATE',
    deedPoll1: 'DEED_POLL_COPY',
    nameChange1: 'NAME_CHANGE_PROOF',
  },
  //   userFormFields: [
  //     {
  //       type: 'text',
  //       compProps: {
  //         id: 'userFirstName',
  //         label: 'First Name',
  //         placeholder: 'Enter first name',
  //         required: true,
  //         errorText: 'First name is required',
  //       },
  //     },
  //     {
  //       type: 'text',
  //       compProps: {
  //         id: 'userLastName',
  //         label: 'LastName',
  //         placeholder: 'Enter last name',
  //         required: true,
  //         errorText: 'Last name is required',
  //       },
  //     },
  //     {
  //       type: 'email',
  //       compProps: {
  //         id: 'expEmail',
  //         label: 'Email',
  //         required: true,

  //         placeholder: 'Enter email address',
  //         errorText: 'Email is required',
  //       },
  //     },
  //   ],
  //   userFormLayout: [['userFirstName', 'userLastName'], ['expEmail']],
};
