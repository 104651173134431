export const userListConfig = {
  url: 'https://kali-dev-dc.vlinder.io/digicert/user/find',
  bulkOptions: [
    {
      value: 'reset_password',
      label: 'Reset Password',
    },
    {
      value: 'delete_user',
      label: 'Delete User',
    },
    {
      value: 'suspend_user',
      label: 'Suspend User',
    },
    {
      value: 'invite_user',
      label: 'Invite User',
    },
  ],
  ultTableHeader: [
    'Organization Name',
    'Email',
    'Date Invited',
    'Status',
    'Action',
  ],
  ultUsers: [
    {
      id: '1',
      status: 'active',
      firstName: 'Harry',
      middleName: 'dae',
      lastName: 'Doe',
      email: 'harry@gmail.com',
      dateInvited: '22 June 2023',
    },
    {
      id: '2',
      status: 'active',
      firstName: 'Potter',
      middleName: 'hims',
      lastName: 'Doe',
      email: 'potter@gmail.com',
      dateInvited: '23 June 2023',
    },
  ],
  currentPage: 1,
  pageSize: 10,
  totalCount: 2,
  totalPages: 1,
  activePage: 1,
  role: 'admin',
};
