import { vappContainer, VApplication } from '../../../../../application';
import { logger as log } from '@common/logger';
import { StorageModule } from '@vlinder-web/storage-module-react';
import { UserProfilePageService } from '@/pages/UserProfile/services';

const MODULE_NAME = 'MyProfilePage.machines.states.getPage';

const getSrv = () => {
  return vappContainer.get<UserProfilePageService>(
    UserProfilePageService.displayName
  );
};

export const getPageContent = async (context: any, event: any) => {
  const currentState = 'GET_PAGE_CONTENT';
  const method = 'getPageContent';
  const storageSrv = StorageModule?.getInstance();
  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      const srv = getSrv();
      const content = await srv.agent.getProfile({ type: event?.type });

      if (content) {
        return resolve({
          key: 'RENDER_PAGE',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'RENDER_PAGE_ERROR',
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'RENDER_PAGE_ERROR',
        value: _msg,
      });
    }
  });
};
