export const dataConfig = {
  url: 'https://kali-dev-dc.vlinder.io/digicert/user/find',
  bulkOptions: [
    {
      value: 'reset_password',
      label: 'Reset Password',
    },
    {
      value: 'delete_user',
      label: 'Delete User',
    },
    {
      value: 'suspend_user',
      label: 'Suspend User',
    },
    {
      value: 'invite_user',
      label: 'Invite User',
    },
  ],
  ultTableHeader: [
    'Application ID',
    'Name',
    'Nationality',
    'Applied On',
    'Status',
    'Action',
  ],
  ultUsers: [],
  currentPage: 1,
  pageSize: 10,
  totalCount: 2,
  totalPages: 1,
  activePage: 1,
  role: 'admin',
};
