export const carricomConfig = {
  nextStepLabel: 'Next',
  submitButtonText: 'Submit',
  carricomLabel: 'CARRICOM CERTIFICATE',
  acknowledgementText:
    'I declare that the information given in this application is true and correct to the best of my knowledge and belief',
  previewLabel: 'Preview',
  progressBarSteps: [
    'CARRICOM CERTIFICATE',
    'CARRICOM CERTIFICATE',
    'CARRICOM CERTIFICATE',
    'CARRICOM CERTIFICATE',
    'CARRICOM CERTIFICATE',
    'CARRICOM CERTIFICATE',
    'Preview',
    'Acknowledgement',
  ],
  fields: [
    [
      {
        type: 'text',
        compProps: {
          label: 'Surname',
          id: 'surName',
          placeHolder: 'Enter Surname',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'First Name',
          id: 'firstName',
          placeHolder: 'Enter First Name',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Middle Name',
          id: 'middleName',
          placeHolder: 'Enter Middle Name',
        },
      },
      {
        type: 'datepicker',
        compProps: {
          id: 'dateOfApplication',
          label: 'Date of Application',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Address Line 1',
          rows: 1,
          id: 'address1',
          placeHolder: 'Enter Address Line 1',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Address Line 2',
          rows: 1,
          id: 'address2',
          placeHolder: 'Enter Address Line 2',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Street',
          id: 'street',
          placeHolder: 'Enter Street',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'City/Town',
          id: 'city',
          placeHolder: 'Enter City/Town',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Parish',
          id: 'parish',
          placeHolder: 'Enter Parish',
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone Contact',
          id: 'telephone',
          placeHolder: 'Enter Telephone Contact ',
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email',
          id: 'email',
          placeHolder: 'Enter Email',
        },
      },
      {
        type: 'radio',
        compProps: {
          id: 'sex',
          label: 'Sex',
          direction: 'horizontal',
          options: [
            {
              label: 'Male',
              id: 'male',
            },
            {
              label: 'Female',
              id: 'female',
            },
          ],
        },
      },
      {
        type: 'datepicker',
        compProps: { id: 'dateOfBirth', isAdult: true, label: 'Date of Birth' },
      },

      {
        type: 'text',
        compProps: {
          label: 'Nationality',
          id: 'nationality',
          placeHolder: 'Enter Nationality',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Country of Birth',
          id: 'countryOfBirth',
          placeHolder: 'Enter Country of Birth',
        },
      },

      {
        type: 'radio',
        compProps: {
          direction: 'horizontal',
          label: 'Marital Status',
          id: 'maritalStatus',
          options: [
            {
              label: 'Single',
              id: 'single',
            },
            {
              label: 'Married',
              id: 'married',
            },
            {
              label: 'Divorced',
              id: 'divorced',
            },
            {
              label: 'Widowed',
              id: 'widowed',
            },

            {
              label: 'Common law union',
              id: 'commonLawUnion',
            },
          ],
        },
      },
    ],
    [
      {
        type: 'radio',
        compProps: {
          direction: 'horizontal',
          label: 'Do you have any Dependents?',
          id: 'haveDependents',

          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          id: 'noOfDependents',
          label: 'How many Dependents in Number',
          placeHolder: 'How many Dependents in Number',
        },
      },
      {
        type: 'sectionTitle',
        compProps: {
          id: 'dependent1',
          label: 'Dependent 1',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name',
          id: 'name1',
          placeHolder: 'Enter Name',
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'Age',
          id: 'age1',
          placeHolder: 'Enter Age',
        },
      },
      {
        type: 'radio',
        compProps: {
          direction: 'horizontal',
          label: 'Sex',
          id: 'sex1',

          options: [
            {
              label: 'Male',
              id: 'male',
            },
            {
              label: 'Female',
              id: 'female',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Nationality',
          id: 'nationality1',
          placeHolder: 'Enter Nationality',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'passport1',
          placeHolder: 'Enter Passport Number',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Relationship to applicant',
          id: 'relation1',
          placeHolder: 'Enter Relationship to applicant',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Passport Attachment',
          id: 'passportProof1',
          maxFiles: 1,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Photograph',
          id: 'photograph1',
          maxFiles: 1,
        },
      },
      {
        type: 'sectionTitle',
        compProps: {
          id: 'dependent2',
          label: 'Dependent 2',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name',
          id: 'name2',
          placeHolder: 'Enter Name',
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'Age',
          id: 'age2',
          placeHolder: 'Enter Age',
        },
      },
      {
        type: 'radio',
        compProps: {
          direction: 'horizontal',
          label: 'Sex',
          id: 'sex2',

          options: [
            {
              label: 'Male',
              id: 'male',
            },
            {
              label: 'Female',
              id: 'female',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Nationality',
          id: 'nationality2',
          placeHolder: 'Enter Nationality',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'passport2',
          placeHolder: 'Enter Passport Number',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Relationship to applicant',
          id: 'relation2',
          placeHolder: 'Enter Relationship to applicant',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Passport Attachment',
          id: 'passportProof2',
          maxFiles: 1,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Photograph',
          id: 'photograph2',
          maxFiles: 1,
        },
      },
      {
        type: 'sectionTitle',
        compProps: {
          id: 'dependent3',
          label: 'Dependent 3',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name',
          id: 'name3',
          placeHolder: 'Enter Name',
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'Age',
          id: 'age3',
          placeHolder: 'Enter Age',
        },
      },
      {
        type: 'radio',
        compProps: {
          direction: 'horizontal',
          label: 'Sex',
          id: 'sex3',

          options: [
            {
              label: 'Male',
              id: 'male',
            },
            {
              label: 'Female',
              id: 'female',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Nationality',
          id: 'nationality3',
          placeHolder: 'Enter Nationality',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'passport3',
          placeHolder: 'Enter Passport Number',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Relationship to applicant',
          id: 'relation3',
          placeHolder: 'Enter Relationship to applicant',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Passport Attachment',
          id: 'passportProof3',
          maxFiles: 1,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Photograph',
          id: 'photograph3',
          maxFiles: 1,
        },
      },
      {
        type: 'sectionTitle',
        compProps: {
          id: 'dependent4',
          label: 'Dependent 4',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name',
          id: 'name4',
          placeHolder: 'Enter Name',
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'Age',
          id: 'age4',
          placeHolder: 'Enter Age',
        },
      },
      {
        type: 'radio',
        compProps: {
          direction: 'horizontal',
          label: 'Sex',
          id: 'sex4',

          options: [
            {
              label: 'Male',
              id: 'male',
            },
            {
              label: 'Female',
              id: 'female',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Nationality',
          id: 'nationality4',
          placeHolder: 'Enter Nationality',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'passport4',
          placeHolder: 'Enter Passport Number',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Relationship to applicant',
          id: 'relation4',
          placeHolder: 'Enter Relationship to applicant',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Passport Attachment',
          id: 'passportProof4',
          maxFiles: 1,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Photograph',
          id: 'photograph4',
          maxFiles: 1,
        },
      },
    ],
    [
      {
        type: 'sectionTitle',
        compProps: {
          id: 'education',
          label: 'Education',
        },
      },
      {
        type: 'select',
        compProps: {
          id: 'qualification',
          label: 'What is your highest educational qualification',
          options: [
            {
              label: 'Select..',
              id: '',
            },
            {
              label: 'Diploma (secondary level)',
              id: 'diplomaSecondary',
            },
            {
              label: 'Caribbean Vocational Qualification',
              id: 'caribbeanVocationalQualification',
            },
            {
              label: 'Diploma (tertiary level)',
              id: 'diplomaTertiary',
            },
            {
              label: 'Associate degree',
              id: 'associateDegree',
            },
            {
              label: 'University/ College degree',
              id: 'universityDegree',
            },
            {
              label: "Master's degree",
              id: 'masterDegree',
            },
            {
              label: 'Master of philosophy (M.Phil.)',
              id: 'masterOfPhilosophy',
            },
            {
              label: 'Doctor of philosophy (ph.D)',
              id: 'doctorOfPhilosophy',
            },
            {
              label: 'Others..',
              id: 'others',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          id: 'qualificationOthers',
          placeHolder: 'If others, please specify',
        },
      },
      {
        type: 'text',
        compProps: {
          id: 'nameOfHighestEducation',
          label: 'State name of highest educational qualification:',
          placeHolder: 'Enter name of highest educational qualification',
        },
      },
      {
        type: 'datepicker',
        compProps: { id: 'dateAwarded', label: 'Date Awarded' },
      },
      {
        type: 'text',
        compProps: {
          id: 'nameOfInstitution',
          placeHolder: 'Enter name of institution',
          label: 'Name of institution which awarded qualification:',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Institution Address Line 1',
          rows: 1,
          id: 'institutionAddress1',
          placeHolder: 'Enter Address Line 1',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Institution Address Line 2',
          rows: 1,
          id: 'institutionAddress2',
          placeHolder: 'Enter Address Line 2',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Street',
          id: 'institutionStreet',
          placeHolder: 'Enter Street',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'City/Town',
          id: 'institutionCity',
          placeHolder: 'Enter City/Town',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Parish',
          id: 'institutionParish',
          placeHolder: 'Enter Parish',
        },
      },
    ],
    [
      {
        type: 'sectionTitle',
        compProps: {
          id: 'employment',
          label: 'EMPLOYMENT',
        },
      },
      {
        type: 'radio',
        compProps: {
          direction: 'horizontal',
          label: 'Please indicate below your current employment status:',
          id: 'employmentStatus',
          options: [
            {
              label: 'Self Employed',
              id: 'selfEmployed',
            },
            {
              label: 'Employed',
              id: 'employed',
            },
            {
              label: 'Unemployed',
              id: 'unEmployed',
            },
            {
              label: 'Student',
              id: 'student',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Please specify your current Occupation',
          id: 'selfoccupation',
          placeHolder: 'Enter Occupation',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label:
            'If self-employed, please state main address from which business activities are conducted:',
          id: 'selfofficeAddress',
          placeHolder: 'Enter address',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Please describe nature of employment',
          id: 'selfnatureOfEmployment',
          placeHolder: 'Please describe nature of employment',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Please specify your current Occupation',
          id: 'occupation',
          placeHolder: 'Enter Occupation',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Occupation Proof Copy',
          id: 'currentOccupationProof',

          maxFiles: 1,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name Of employer',
          id: 'nameOfEmployer',
          placeHolder: 'Enter Name Of employer',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Employer Address Line 1',
          rows: 1,
          id: 'employerAddress1',
          placeHolder: 'Enter Address Line 1',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Employer Address Line 2',
          rows: 1,
          id: 'employerAddress2',
          placeHolder: 'Enter Address Line 2',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Street',
          id: 'employerStreet',
          placeHolder: 'Enter Street',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'City/Town',
          id: 'employerCity',
          placeHolder: 'Enter City/Town',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Parish',
          id: 'employerParish',
          placeHolder: 'Enter Parish',
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone Number',
          id: 'telephoneOfEmployer',
          placeHolder: 'Enter Telephone Number ',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Fax Number',
          id: 'faxNumberOfEmployer',
          placeHolder: 'Enter Fax Number',
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'emailOfEmployer',
          placeHolder: 'Enter your Email address',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Please describe nature of employment',
          rows: 2,
          id: 'natureOfEmployment',
          placeHolder: 'Enter Please describe nature of employment',
        },
      },
      {
        type: 'select',
        compProps: {
          id: 'educationalCourse',
          label:
            'Select educational institution, type and course of educational programme:',
          options: [
            {
              label: 'Select..',
              id: '',
            },
            {
              label: 'Academic degree programme',
              id: 'academicDegree',
            },
            {
              label: 'Vocational training',
              id: 'vocationalTraining',
            },

            {
              label: 'Others..',
              id: 'others',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          id: 'educationalCourseOthers',
          placeHolder: 'If others, please specify',
        },
      },
      {
        type: 'text',
        compProps: {
          label:
            'Specify degree or certification to be awarded on completion of educational programme',

          id: 'degreeOrCertification',
          placeHolder: 'Enter degree or certification',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Educational Institution Name',

          id: 'nameOfEducationalInstitution',
          placeHolder: 'Enter Educational Institution Name',
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Educational Institution Address',
          rows: 1,
          id: 'addressOfEducationalInstitution',
          placeHolder: 'Enter Educational Institution Address',
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Enter Educational  Telephone Number',

          id: 'telephoneOfEducationalInstitution',
          placeHolder: 'Enter Educational Telephone Number ',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Enter Educational Institution Fax Number',
          id: 'faxNumberOfEducationalInstitution',
          placeHolder: 'Enter Educational Institution Enter Fax Number',
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Enter Educational Institution Email Address',
          id: 'emailOfEducationalInstitution',
          placeHolder: 'Enter Educational Institution Email address',
        },
      },
    ],
    [
      {
        type: 'sectionTitle',
        compProps: {
          id: 'destination',
          label: 'Destination Caribbean Community State',
        },
      },
      {
        type: 'radio',
        compProps: {
          label:
            'Please specify the purpose for the relocation to the Caribbean Community state',
          id: 'relocationEmploymentStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Self Employment',
              id: 'selfEmployment',
            },
            {
              label: 'Employment',
              id: 'employment',
            },
            {
              label: 'Study',
              id: 'study',
            },
            {
              label: 'Family / Personal Relationship',
              id: 'familyRelation',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label:
            'Please specify the nature of professional or business activity you intend to undertake in the Carribean Community State',
          id: 'natureOfProfessional',
          placeHolder:
            'Enter the nature of professional or business activity you intend to undertake',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Occupation',
          id: 'relocationOccupation',
          placeHolder: 'Enter Occupation',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Occupation Proof Copy',
          id: 'relocationOccupationProof',

          maxFiles: 1,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name Of Business or activity',
          id: 'nameOfBusiness',
          placeHolder: 'Enter Name Of Business or activity',
        },
      },
    ],
    [
      {
        type: 'sectionTitle',
        compProps: {
          id: 'documents',
          label: 'Documents in support of Application',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Birth Certificate Copy',
          id: 'birthCertificateProof',

          maxFiles: 1,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Nationality Copy',
          id: 'nationalityProof',

          maxFiles: 1,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Passport Copy',
          id: 'passportProof',

          maxFiles: 1,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Qualification Certificate Copy',
          id: 'qualificationProof',

          maxFiles: 1,
        },
      },
      {
        type: 'sectionTitle',
        compProps: {
          id: 'declaration',
          label: 'Declaration and Signature',
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Signature of the applicant',
          id: 'signatureOfApplicant',

          maxFiles: 1,
        },
      },
      {
        type: 'checkBox',
        compProps: {
          id: 'finalAcknowledgementCheck',
          options: [
            {
              id: 'yes',
              label:
                'I declare that the information given in this application is true and correct to the best of my knowledge and belief',
            },
          ],
        },
      },
    ],
  ],
  layout: [
    [
      ['surName', 'firstName'],
      ['middleName', 'dateOfApplication'],
      ['address1', 'address2'],
      ['street', 'city'],
      ['parish'],
      ['telephone', 'email'],
      ['sex', 'dateOfBirth'],
      ['nationality', 'countryOfBirth'],
      ['maritalStatus:12', ''],
    ],
    [['haveDependents']],
    [
      ['education'],
      ['qualification'],
      ['nameOfHighestEducation'],
      ['dateAwarded', 'nameOfInstitution'],
      ['institutionAddress1', 'institutionAddress2'],
      ['institutionStreet', 'institutionCity'],
      ['institutionParish'],
    ],
    [['employment'], ['employmentStatus']],
    [['destination'], ['relocationEmploymentStatus']],
    [
      ['documents'],
      ['birthCertificateProof'],
      ['nationalityProof'],
      ['passportProof'],
      ['qualificationProof'],
      ['declaration'],
      ['signatureOfApplicant'],
      ['finalAcknowledgementCheck'],
    ],
  ],
  dynamicLayout: {
    haveDependents: {
      yes: [
        ['noOfDependents'],
        ['dependent1'],
        ['name1', 'age1'],
        ['sex1', 'nationality1'],
        ['passport1', 'relation1'],
        ['passportProof1', 'photograph1'],
        ['dependent2'],
        ['name2', 'age2'],
        ['sex2', 'nationality2'],
        ['passport2', 'relation2'],
        ['passportProof2', 'photograph2'],
        ['dependent3'],
        ['name3', 'age3'],
        ['sex3', 'nationality3'],
        ['passport3', 'relation3'],
        ['passportProof3', 'photograph3'],
        ['dependent4'],
        ['name4', 'age4'],
        ['sex4', 'nationality4'],
        ['passport4', 'relation4'],
        ['passportProof4', 'photograph4'],
      ],
    },
    qualification: {
      others: [['qualificationOthers']],
    },
    employmentStatus: {
      employed: [
        ['occupation'],
        ['currentOccupationProof'],
        ['nameOfEmployer'],
        ['employerAddress1'],
        ['employerAddress2'],
        ['employerStreet'],
        ['employerCity'],
        ['employerParish'],
        ['telephoneOfEmployer'],
        ['faxNumberOfEmployer'],
        ['emailOfEmployer'],
        ['natureOfEmployment'],
      ],
      selfEmployed: [
        ['selfoccupation'],
        ['selfofficeAddress'],
        ['selfnatureOfEmployment'],
      ],
      student: [
        ['educationalCourse'],
        ['degreeOrCertification'],
        ['nameOfEducationalInstitution'],
        ['addressOfEducationalInstitution'],
        ['telephoneOfEducationalInstitution'],
        ['faxNumberOfEducationalInstitution'],
        ['emailOfEducationalInstitution'],
      ],
    },
    relocationEmploymentStatus: {
      selfEmployment: [['natureOfProfessional']],
      employment: [
        ['relocationOccupation'],
        ['relocationOccupationProof'],
        ['nameOfBusiness'],
      ],
    },
    educationalCourse: {
      others: [['educationalCourseOthers']],
    },
  },
};
