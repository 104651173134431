export const loadingConfig = {
  GetPageLoading: {
    image: '',
    titleNormalText: 'Loading',
    titleBoldText: '...',
    description: 'This might take few seconds, Please wait !',
  },
  resetPassword: {
    image: '',
    titleNormalText: 'Resetting',
    titleBoldText: 'your password...',
    description: 'This might take few seconds, Please wait !',
  },
};
