export const rowSignupFormData = {
  fields: [
    {
      type: 'password',
      compProps: {
        id: 'password',
        label: 'Password',
        required: true,
        placeholder: 'Enter your password',
        errorText: 'Password is required',
      },
    },
    {
      type: 'password',
      compProps: {
        id: 'confirmPassword',
        label: 'Confirm Password',
        required: true,
        placeholder: 'Re-enter your password',
        errorText: 'Password is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'firstName',
        label: 'First Name',
        required: true,
        placeholder: 'Enter your name',
        errorText: 'Name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'lastName',
        label: 'Last Name',
        required: true,
        placeholder: 'Enter your last name',
        errorText: 'Last Name is required',
      },
    },
    {
      type: 'select',
      compProps: {
        label: 'User Type',
        id: 'userType',
        required: true,
        options: [
          {
            label: 'Owner',
            id: 'OWNER',
          },
        ],
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'orgName',
        label: 'Business Name',
        required: true,
        placeholder: 'Enter your Business name',
        errorText: 'Business Name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'trn',
        label: 'TRN',
        required: true,
        placeholder:
          'Enter your TRN , should not contain any special characters ex: 123456789',
        errorText: 'TRN is required',
      },
    },
    {
      type: 'phoneNumber',
      compProps: {
        id: 'phoneNumber',
        label: 'Mobile Number',
        required: true,
        placeholder:
          'Select your country code first and then Enter mobile Number',
        errorText: 'Mobile number is required',
      },
    },
    {
      type: 'email',
      compProps: {
        id: 'email',
        label: 'Email',
        required: true,
        placeholder: 'Enter your email address',
        errorText: 'Email is required',
      },
    },
  ],
  layout: [
    ['orgName'],
    ['lastName', 'firstName'],
    ['userType'],
    ['trn'],
    ['phoneNumber'],
    ['email'],
    ['password'],
    ['confirmPassword'],
  ],
};
