import { logger as log } from '@common/logger';
import { vappContainer } from '@/application';
import { SignInPageService } from '@/pages/SignIn';
import { StorageModule } from '@vlinder-web/storage-module-react';

import toast from 'react-hot-toast';
import { AppNavigatorService } from '@/AppNavigator';
const MODULE_NAME = 'profile.machines.states.signInUser';

const getSrv = () => {
  return vappContainer.get<SignInPageService>(SignInPageService.displayName);
};

const getAppSrv = () => {
  return vappContainer.get<AppNavigatorService>(
    AppNavigatorService.displayName
  );
};

export const signInUser = async (context: any, event: any) => {
  const currentState = 'SIGN_IN_USER';
  const method = 'signIn';
  const storageSrv = StorageModule.getInstance();

  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      const srv = getSrv();
      const content = await srv.signInUser(event?.payload);
      console.log(`signInUser service content:`, content);
      //window.open('/profile/organization-profile', '_self');
      if (
        (content && content?.accessToken) ||
        (content && content?.signInToken)
      ) {
        srv.setUserEmail(event?.payload?.email);
        if (content?.emailVerified) {
          if (event?.payload?.rememberMe) {
            storageSrv.set(
              'userProfile',
              {
                ...content,
                accessToken: content?.accessToken,
              },
              'local'
            );
          } else {
            storageSrv.set(
              'userProfile',
              {
                ...content,
                accessToken: content?.accessToken,
              },
              'session'
            );
          }
        }
        return resolve({
          key: 'CHECK_TOKEN_TYPE',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = content?.error;
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        toast.error(msg);
        return reject({
          key: 'SIGN_IN_USER_FAILED',
          value: content,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err}`);
      let _msg = err?.replace('UnauthorizedError:', '');
      toast.error(_msg);
      return reject({
        key: 'SIGN_IN_USER_FAILED',
        value: _msg,
      });
    }
  });
};
