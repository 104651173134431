import { vappContainer, VApplication } from '../../../../../application';
import { logger as log } from '@common/logger';
import { CredentialListPageService } from '../../../services';

const MODULE_NAME = 'profile.machines.states.activateUser';

const getSrv = () => {
  return vappContainer.get<CredentialListPageService>(
    CredentialListPageService.displayName
  );
};

export const notifyUser = async (context: any, event: any) => {
  const currentState = 'ACTIVATE_USER';
  const method = 'notifyUser';

  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      const srv = getSrv();
      const content = await srv.agent.notifyUser(event?.payload);
      if (content) {
        return resolve({
          key: 'NOTIFY_USER_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Failed to notify user';
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'NOTIFY_USER_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'NOTIFY_USER_FAILED',
        value: _msg,
      });
    }
  });
};
