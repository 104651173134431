export const settingsConfig = {
  tableHeaders: [
    {
      key: 'Email',
      filterTypes: [
        {
          label: 'Contains',
          type: 'text',
          id: 'email',
        },
        {
          label: 'Equal',
          type: 'text',
          id: 'email',
        },
      ],
    },
    {
      key: 'First Name',
      filterTypes: [
        {
          label: 'ContainsCI',
          type: 'text',
          id: 'firstName',
        },
      ],
    },
    {
      key: 'Last Name',
      filterTypes: [
        {
          label: 'ContainsCI',
          type: 'text',
          id: 'lastName',
        },
      ],
    },
  ],
};
