export const featuresSection = {
  image: '',
  title: 'Organization',
  subtitle: 'Your Organization Profile',
  buttons: [
    {
      key: 'edit',
      title: 'Edit Profile',
      color: 'primary',
    },
  ],
  applicantItems: [
    {
      title: 'First Name',
      subTitle: '-',
    },
    {
      title: 'Middle Name',
      subTitle: '-',
    },
    {
      title: 'Last Name',
      subTitle: '-',
    },
    {
      title: 'Email',
      subTitle: '-',
    },
    {
      title: 'Address Line 1',
      subTitle: '-',
    },
    {
      title: 'Address Line 2',
      subTitle: '-',
    },
    {
      title: 'City/Town',
      subTitle: '-',
    },
    {
      title: 'Country of Birth',
      subTitle: '-',
    },
    {
      title: 'Date of Birth',
      subTitle: '-',
    },
    {
      title: 'Place of Birth',
      subTitle: '-',
    },
    {
      title: 'Gender',
      subTitle: '-',
    },
    {
      title: 'Marital Status',
      subTitle: '-',
    },
    {
      title: 'Occupation',
      subTitle: '-',
    },
    {
      title: 'Passport Number',
      subTitle: '-',
    },
    {
      title: 'Passport Expiry',
      subTitle: '-',
    },
    {
      title: 'Primary Contact No',
      subTitle: '-',
    },
    {
      title: 'Operating Address',
      subTitle: '-',
    },
  ],
  companyListItems: [
    {
      title: 'Nature of Business',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/street-icon.png',
    },
    {
      title: 'Company Type',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/street-icon.png',
    },
    {
      title: 'Taxpayer Registration No',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/country-icon.png',
    },

    {
      title: 'Website',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/country-icon.png',
    },
    {
      title: 'Street 1',
      subTitle: '',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/street-icon.png',
    },
    {
      title: 'Street 2',
      subTitle: '',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/street-icon.png',
    },
    {
      title: 'City/Town',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/city-icon.png',
    },
    {
      title: 'Parish',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/country-icon.png',
    },

    {
      title: 'Postal Code',
      subTitle: '-',
      // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    },

    {
      title: 'Phone Number',
      subTitle: '-',
      //   // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    },
    {
      title: 'First Name',
      subTitle: '-',
      //   // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    },
    {
      title: 'Last Name',
      subTitle: '-',
      //   // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    },
    {
      title: 'Country Of Birth',
      subTitle: '-',
      //   // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    },
    {
      title: 'Date of Birth',
      subTitle: '-',
      //   // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    },
    {
      title: 'Marital Status',
      subTitle: '-',
      //   // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    },
    {
      title: 'Nationality',
      subTitle: '-',
      //   // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    },
    {
      title: 'Occupation',
      subTitle: '-',
      //   // icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    },
  ],

  listItems: [
    {
      title: 'Business Name',
      subTitle: '',
      icon: 'https://i.ibb.co/4F971K5/strategy-development.png',
    },
    {
      title: 'Company Registration No',
      subTitle: '-',
      icon: 'https://i.ibb.co/VxYMYV9/machine.png',
    },

    {
      title: 'Incorporation Date',
      subTitle: '-',
      icon: 'ttps://i.ibb.co/48t2Jzj/calendar-2.png',
    },
  ],
  orgListItems: [
    {
      title: 'Business Name',
      subTitle: '',
      icon: 'https://i.ibb.co/4F971K5/strategy-development.png',
    },
    {
      title: 'Company Registration No',
      subTitle: '-',
      icon: 'https://i.ibb.co/VxYMYV9/machine.png',
    },
    {
      title: 'Application No',
      subTitle: '-',
      icon: 'https://i.ibb.co/VxYMYV9/machine.png',
    },

    {
      title: 'Incorporation Date',
      subTitle: '-',
      icon: 'https://i.ibb.co/48t2Jzj/calendar-2.png',
    },
    {
      title: 'Created At',
      subTitle: '-',
      icon: 'https://i.ibb.co/48t2Jzj/calendar-2.png',
    },
    {
      title: 'Nature of Business',
      subTitle: '',
      icon: 'https://i.ibb.co/6B2ZPnm/app.png',
    },
    {
      title: 'TCC No',
      subTitle: '-',
      icon: 'https://i.ibb.co/vjtFYFL/certificate.png',
    },
    {
      title: 'TCC Expiry Date',
      subTitle: '-',
      icon: 'https://i.ibb.co/Pr8cg33/deadline.png',
    },
    // {
    //   title: 'Email',
    //   subTitle: '-',
    //   icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/email-icon.png',
    // },
    // {
    //   title: 'Phone Number',
    //   subTitle: '-',
    //   icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/phone-icon.png',
    // },
    // {
    //   title: 'Address 1',
    //   subTitle: '-',
    //   icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/street-icon.png',
    // },
    // {
    //   title: 'Address 2',
    //   subTitle: '-',
    //   icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/street-icon.png',
    // },
    // {
    //   title: 'City',
    //   subTitle: '-',
    //   icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/city-icon.png',
    // },
    // {
    //   title: 'Country',
    //   subTitle: '-',
    //   icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/country-icon.png',
    // },
    // {
    //   title: 'Website',
    //   subTitle: '-',
    //   icon: 'https://static.thenounproject.com/png/3437694-200.png',
    // },
  ],
};
