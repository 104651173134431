export const userListConfig = {
  url: 'https://kali-dev-dc.vlinder.io/digicert/user/find',
  bulkOptions: [
    {
      value: 'reset_password',
      label: 'Reset Password',
    },
    {
      value: 'delete_user',
      label: 'Delete User',
    },
    {
      value: 'suspend_user',
      label: 'Suspend User',
    },
    {
      value: 'invite_user',
      label: 'Invite User',
    },
  ],
  ultTableHeader: [
    'Application ID',
    'Action',
    'Application Type',
    'Name',
    'Saved On',
    'Status',
  ],
  ultUsers: [
    {
      id: '1',
      status: 'inProgress',
      firstName: 'Harry',
      lastName: 'Doe',
      applicationType: 'New Work Permit',
      applicationId: '10002',
      savedOn: '22 June 2023',
    },
    {
      id: '2',
      status: 'completed',
      firstName: 'Potter',
      lastName: 'Doe',
      applicationType: 'New Work Permit',
      applicationId: '10003',
      savedOn: '23 June 2023',
    },
  ],
  currentPage: 1,
  pageSize: 10,
  totalCount: 2,
  totalPages: 1,
  activePage: 1,
  role: 'admin',
};
