export const rightFaqConfig = {
  selectCountry: [
    // {
    //   title: 'Why type is required',
    //   description:
    //     'Applicant is one who applies for Work Permit Application. Employer or a representative of a company or organization who manages the portal.',
    // },
    {
      title: 'Who is Applicant ?',
      description:
        'Applicant is one who applies Work Permit Application for himself. An applicant can also apply for csme and marriage exemption certificates',
    },
    {
      title: 'Who is Employer ?',
      description:
        'The portal is managed by employers or representatives of companies and organizations. There are two types of employers:1. For Established Companies: These employers can apply for work permits for individuals under an already established company. 2. For Rights of Establishment: This category includes individuals who hold companies in a Caribbean country and wish to establish the same company in Jamaica. They can apply for work permits specific to the new establishment.',
    },
    {
      title: 'What is Rights Of Establishment ?',
      description:
        'Rights of Establishment is the one who hold the company in carribean country and want to establish the same company in Jamaica',
    },
  ],
};
