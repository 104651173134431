export const config = {
  title: 'Home Insurance Policy',
  subtitle: 'B2CGS50000123',
  buttons: [
    {
      key: 'updatePayment',
      title: 'Collect Full Payment',
      color: 'secondary',
    },
    {
      key: 'cancel',
      title: 'Cancel Policy',
      color: 'primary',
    },
  ],
  partialButton: [
    {
      key: 'updatePartial',
      title: 'Collect Full Payment',
      color: 'secondary',
    },
    {
      key: 'cancel',
      title: 'Cancel Policy',
      color: 'primary',
    },
  ],
  listItems: [
    {
      key: 'policyName',
      title: 'Policy Name',
      subTitle: '-',
      icon: 'https://cdn-icons-png.flaticon.com/512/3712/3712215.png',
    },
    {
      key: 'coverage',
      title: 'Coverage',
      subTitle: '-',
      icon: 'https://cdn.iconscout.com/icon/premium/png-256-thumb/insurance-policy-5423901-4526630.png',
    },
    {
      key: 'premium',
      title: 'Premium',
      subTitle: '-',
      icon: 'https://cdn-icons-png.flaticon.com/512/5455/5455381.png',
    },
    {
      key: 'country',
      title: 'Insured Country',
      subTitle: '',
      icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/country-icon.png',
    },
  ],
  partialPayment: [
    {
      key: 'partialPaid',
      title: 'Partially Paid',
      subTitle: '-',
      icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/payment-icon.png',
    },
    {
      key: 'premiumDue',
      title: 'Premium Due',
      subTitle: '-',
      icon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/due-date-icon.png',
    },
  ],
  purchaser: {
    title: 'Proposer Detail',
  },
  policyDetails: {
    title: 'Policy Detail',
    listItems: [
      {
        title: 'Policy Option',
        value: 'Option T',
      },
      {
        title: 'Coverage Amount',
        value: 'EC$ 30,000.00',
      },
      {
        title: 'Annual Premium',
        value: 'EC$ 1,500',
      },
      {
        title: 'Premium Paid',
        value: 'Apr 15 2023',
      },
      {
        title: 'Email Address',
        value: 'john.doe@gmail.com',
      },
      {
        title: 'Payouts',
        value: 'NA',
      },
      {
        title: 'Coverage Balance',
        value: 'EC$ 30,000',
      },
    ],
  },
  beneficiaryDetails: {
    title: 'Beneficiary Detail',
    listItems: [
      {
        title: 'Full Name',
        value: 'Merina',
      },
      {
        title: 'Street Address',
        value: 'vv street',
      },
      {
        title: 'City/Town',
        value: 'Berat',
      },
      {
        title: 'Country',
        value: 'Albania',
      },
      {
        title: 'Email Address',
        value: 'merina.doe@gmail.com',
      },
      {
        title: 'Telephone',
        value: '+355-123-456-780',
      },
    ],

    items: [
      {
        title: 'Jan 2023',
        cardTitle: 'Provisional Policies Created',
        cardSubtitle: 'Lorem ipsum dolor sit amet',
        cardDetailedText:
          'Vel corporis expedita est quasi tempora ab perspiciatis quae et Quis aspernatur est temporibus dolores ea eveniet distinctio eum modi nesciunt.',
      },
      {
        title: 'Jan 2023',
        cardTitle: 'Policy payment Completed',
        cardSubtitle: 'Lorem ipsum dolor sit amet',
        cardDetailedText:
          'Vel corporis expedita est quasi tempora ab perspiciatis quae et Quis aspernatur est temporibus dolores ea eveniet distinctio eum modi nesciunt.',
      },
      {
        title: 'Jan 2023',
        cardTitle: 'Hurricane Occured',
        cardSubtitle: 'Lorem ipsum dolor sit amet',
        cardDetailedText:
          'Vel corporis expedita est quasi tempora ab perspiciatis quae et Quis aspernatur est temporibus dolores ea eveniet distinctio eum modi nesciunt.',
      },
      {
        title: 'Jan 2023',
        cardTitle: 'Payment has been sent',
        cardSubtitle: 'Lorem ipsum dolor sit amet',
        cardDetailedText:
          'Vel corporis expedita est quasi tempora ab perspiciatis quae et Quis aspernatur est temporibus dolores ea eveniet distinctio eum modi nesciunt.',
      },
    ],
  },

  carouselItems: [
    {
      type: 'pdf',
      poster:
        'https://s3.ap-south-1.amazonaws.com/io.vlinder.logos/karantaka_gov.png',
      src: 'https://s3.ap-south-1.amazonaws.com/io.klefki.kcoop/coop_v2.pdf',
    },
    {
      type: 'pdf',
      poster:
        'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/pdf-doc-illustration.png',
      src: 'https://s3.ap-south-1.amazonaws.com/io.klefki.kcoop/coop_evidence_v2.pdf',
      title: 'Agreement Deed',
    },
  ],
  timelineDetails: {
    title: 'Timeline',
    listItems: [
      {
        title: 'Jan 2023',
        cardTitle: 'Provisional Policies Created',
        cardSubtitle: 'Lorem ipsum dolor sit amet',
        cardDetailedText:
          'Vel corporis expedita est quasi tempora ab perspiciatis quae et Quis aspernatur est temporibus dolores ea eveniet distinctio eum modi nesciunt.',
      },
      {
        title: 'Jan 2023',
        cardTitle: 'Policy payment Completed',
        cardSubtitle: 'Lorem ipsum dolor sit amet',
        cardDetailedText:
          'Vel corporis expedita est quasi tempora ab perspiciatis quae et Quis aspernatur est temporibus dolores ea eveniet distinctio eum modi nesciunt.',
      },
      {
        title: 'Jan 2023',
        cardTitle: 'Hurricane Occured',
        cardSubtitle: 'Lorem ipsum dolor sit amet',
        cardDetailedText:
          'Vel corporis expedita est quasi tempora ab perspiciatis quae et Quis aspernatur est temporibus dolores ea eveniet distinctio eum modi nesciunt.',
      },
      {
        title: 'Jan 2023',
        cardTitle: 'Payment has been sent',
        cardSubtitle: 'Lorem ipsum dolor sit amet',
        cardDetailedText:
          'Vel corporis expedita est quasi tempora ab perspiciatis quae et Quis aspernatur est temporibus dolores ea eveniet distinctio eum modi nesciunt.',
      },
    ],
  },
  useOfProceedsDetail: {
    title: 'Use of Proceeds',
  },
  dateIcon: 'https://s3.ap-south-1.amazonaws.com/io.vlinder.tnt-demo/date.png',
  orgIcon:
    'https://s3.ap-south-1.amazonaws.com/io.vlinder.tnt-demo/organization.png',
  locationIcon:
    'https://s3.ap-south-1.amazonaws.com/io.vlinder.tnt-demo/location.png',
  discountIcon:
    'https://s3.ap-south-1.amazonaws.com/io.klefki.parametro/discount-icon.png',
  policyHolder: [
    {
      key: 'firstName',
      title: 'First Name',
      subTitle: '-',
    },
    {
      key: 'lastName',
      title: 'Last Name',
      subtitle: '-',
    },
    {
      key: 'vuid',
      title: 'Account ID',
      subtitle: '-',
    },
    {
      key: 'email',
      title: 'Email',
      subtitle: '-',
    },
    {
      key: 'occupation',
      title: 'Occupation',
      subtitle: '-',
    },
    {
      key: 'primaryContactNo',
      title: 'Primary Contact No.',
      subtitle: '-',
    },
    {
      key: 'address',
      title: 'Address',
      subtitle: '-',
    },
  ],
};
