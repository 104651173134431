import { Card } from "react-bootstrap";
import styled from "styled-components";



export const MyCard=styled(Card)`
    transition:0.3s ease-in-out;
    &:hover{
        h2,h5{
            color:white !important;
        }
        background-color:#25AE8D !important;
        color:gray !important;
        box-shadow:${props=>props?.isShadow ? '0px 0px 10px grey !important' : '0 1px 3px 0 rgba(0,0,0,0.1), 0 1px 2px 0 rgba(0,0,0,0.06)'};
    }
`;