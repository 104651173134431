export const loadingConfig = {
  GetPageLoading: {
    image: 'https://i.ibb.co/CbVc1f2/Ministry-New-Logo-cropped-PNG-1.png',
    titleNormalText: 'Loading',
    titleBoldText: '...',
    description: 'This might take few seconds, Please wait !',
  },
  signInUser: {
    image: 'https://i.ibb.co/CbVc1f2/Ministry-New-Logo-cropped-PNG-1.png',
    titleNormalText: 'Signing into',
    titleBoldText: 'your account...',
    description: 'This might take few seconds, Please wait !',
  },
};
