export const rightFaqConfig = {
  data: [
    {
      title: 'What does the "Proceed with Invite" button mean',
      description:
        'The "Proceed with Invite" button is a feature within an application process that allows an employer to initiate an invitation for an applicant to complete their part of the application. After the employer has filled out their portion of the application and wishes to invite the applicant to provide their information or complete specific tasks, they can click on the "Proceed with Invite" button. This action triggers an invitation notification to the applicant, prompting them to log in or access the application platform to fulfill their part of the application. It streamlines the application process by separating responsibilities between the employer and the applicant.',
    },
    {
      title: 'What does the "Continue with Applicant Form" button mean',
      description:
        'The "Continue with Applicant Form" button offers employers a convenient option to directly fill out the applicant\'s portion of the form without sending an invitation to the applicant. In cases where the employer decides not to invite the applicant to complete their part of the application, they can use this button to proceed with providing the necessary information on behalf of the applicant',
    },
  ],
  userInviteInfo: [
    {
      title: 'Why do we need applicant information?',
      description:
        "Entering the applicant's first name, last name, and email initiates the process of inviting the applicant to complete their part of the application. By providing this information, you are triggering an invitation to be sent to the applicant's email address. The invitation will include a link that leads the applicant to the application form, where they can fill out their details and provide the necessary information. ",
    },
  ],
};
