export const headerConfig = {
  forgotPassword: {
    title: 'Forgot your password?',
    iconURL:
      'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/email-new.png',
    logo: 'https://i.ibb.co/z71dLKh/Ministry-New-Logo-JPEG.jpg',
    subHeadingText:
      'Just type in your email and we will send you instructions to reset your password!',
  },
  forgotPasswordSuccess: {
    title: 'Forgot your password?',
    iconURL:
      'https://s3.ap-south-1.amazonaws.com/io.vlinder.icons/email-new.png',
    logo: 'https://i.ibb.co/z71dLKh/Ministry-New-Logo-JPEG.jpg',
    subHeadingText:
      'We have emailed instructions to your registered email id ${email} to reset your password!',
  },
};
