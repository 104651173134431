export const loadingConfig = {
  loadingUsers: {
    titleNormalText: 'Retrieving your',
    titleBoldText: 'Credentials...',
    description: 'This might take few seconds, Please wait !',
  },
  inviteUser: {
    image:
      'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/fhp_loading_illustration_2.png',
    titleNormalText: 'Inviting user',
    description: 'This might take few seconds, Please wait !',
  },
  usersEmpty: {
    titleNormalText: 'Credentials',
    titleBoldText: 'Not Found !',
    description: 'No credentials yet',
  },
  suspendUser: {
    image:
      'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/fhp_loading_illustration_2.png',
    titleNormalText: 'Updating admin user status',
    description: 'This might take few seconds, Please wait !',
  },
  deleteUser: {
    image:
      'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/fhp_loading_illustration_2.png',
    titleNormalText: 'Deleting admin user',
    description: 'This might take few seconds, Please wait !',
  },
};
