export const loadingConfig = {
  getAppCanCreate: {
    titleNormalText: 'Loading form,',
    titleBoldText: 'Please wait...',
    description: 'This might take few seconds, Please wait !',
  },
  getApplication: {
    titleNormalText: 'Retrieving your',
    titleBoldText: 'application...',
    description: 'This might take few seconds, Please wait !',
  },
  getDocuments: {
    titleNormalText: 'Retrieving your',
    titleBoldText: 'Documents...',
    description: 'This might take few seconds, Please wait !',
  },
  saveApplication: {
    titleNormalText: 'Saving your',
    titleBoldText: 'application...',
    description: 'This might take few seconds, Please wait !',
  },
  resubmitApplication: {
    titleNormalText: 'Submitting your',
    titleBoldText: 'application...',
    description: 'This might take few seconds, Please wait !',
  },

  loadingCreateNewPermit: {
    image: '',
    titleNormalText: 'Creating ',
    titleBoldText: 'New Work Permit...',
    description: 'This might take few seconds, Please wait !',
  },
  updateProfile: {
    image: '',
    titleNormalText: 'Updating your',
    titleBoldText: 'organization profile...',
    description: 'This might take few seconds, Please wait !',
  },
  inviteUser: {
    image: '',
    titleNormalText: 'Inviting user',
    description: 'This might take few seconds, Please wait !',
  },
  loadingUsers: {
    image: '',
    titleNormalText: 'Retrieving your',
    titleBoldText: 'users list...',
    description: 'This might take few seconds, Please wait !',
  },
  notAuthorized: {
    image: '',
    titleNormalText:
      'Please update the Organization/Employer Details under Organization Page',
    titleBoldText: '',
    description: 'Your Profile is not yet approved',
  },
  tccExpired: {
    image: '',
    titleNormalText: 'Tax Clearance Certificate Expired',
    titleBoldText: '',
    description:
      'Please update your Tax Clearance Certificate in the organization profile or contact the administrator.',
  },
  usersEmpty: {
    image: '',
    titleNormalText: 'Users',
    titleBoldText: 'Not found !',
    description: 'Inviting new users feature to manage this portal',
  },
  applicationEmpty: {
    image: '',
    titleNormalText: 'Applications',
    titleBoldText: 'Not found !',
    description: 'No applications Found',
  },
  loadingApplication: {
    image: '',
    titleNormalText: 'Retrieving your',
    titleBoldText: 'application...',
    description: 'This might take few seconds, Please wait !',
  },
  loadingApplications: {
    image: '',
    titleNormalText: 'Retrieving your',
    titleBoldText: 'applications...',
    description: 'This might take few seconds, Please wait !',
  },
};
