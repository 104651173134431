import { useState } from 'react';

import * as S from './styles';
import {
  InformationCircleIcon,
  MinusCircleIcon,
  PlusCircleIcon,
} from '@heroicons/react/outline';
import { OverlayTrigger, Popover } from 'react-bootstrap';

interface IFontSliderProps {
  infoText?: string;
  maxLimit?: number;
  minLimit?: number;
  fontScale: number;
  updateFontScale?(font: number): void;
}
export const FontSlider = (props: IFontSliderProps) => {
  const { fontScale, updateFontScale, infoText, maxLimit, minLimit } = props;

  const popoverHoverFocus = (
    <Popover id="popover-trigger-hover-focus" style={S.InfoStyle}>
      {infoText}
    </Popover>
  );
  const range = maxLimit! - minLimit! + 1;

  return (
    <S.Container className="d-flex align-items-center">
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={popoverHoverFocus}
      >
        <InformationCircleIcon width={'20px'} style={S.IconStyle} />
      </OverlayTrigger>
      <S.FontSliderContainer>
        <S.MinusContainer
          onClick={() => {
            if (fontScale !== minLimit) {
              updateFontScale && updateFontScale(fontScale - 1);
            }
          }}
        >
          <MinusCircleIcon width={'25px'} height={'25px'} />
        </S.MinusContainer>

        {/* @ts-ignore */}
        <S.CurrentItem fontScale={fontScale} />
        {new Array(range).fill('*')?.map((item, key) => {
          return (
            <S.RoundDot
              key={key}
              onClick={() =>
                updateFontScale &&
                updateFontScale(key - (maxLimit! - minLimit!) / 2)
              }
            />
          );
        })}

        <S.PlusContainer
          onClick={() => {
            if (fontScale !== maxLimit) {
              updateFontScale && updateFontScale(fontScale + 1);
            }
          }}
        >
          <PlusCircleIcon width={'25px'} height={'25px'} />
        </S.PlusContainer>
      </S.FontSliderContainer>
    </S.Container>
  );
};

FontSlider.defaultProps = {
  infoText:
    'This slider adjusts the font size of the text on the form. Use the slider or the plus and minus buttons to change the font size.',
  maxLimit: 3,
  minLimit: -3,
};
