export const loadingConfig = {
  createSession: {
    // image:
    //   'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/fhp_loading_illustration_2.png',
    titleNormalText:
      'Please wait... Your payment is currently being processed.',
    description: 'Do not refresh or close your browser.',
  },

  getFee: {
    titleNormalText: 'Retrieving your',
    titleBoldText: 'payment details...',
    description: 'This might take few seconds, Please wait !',
  },
  paymentProcessing: {
    text: 'Payment processing. Please do not refresh the page or click "Back" or "Close" button of your browser.  s',
  },
};
