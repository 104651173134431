export const footer = {
  footerBrandLogo: 'https://i.ibb.co/LQBgRZk/logo.png',
  footerBrandTitle: 'Parametro',
  footerBrandTitleStyle: {
    fontFamily: 'muli',
  },
  footerBrandDescription:
    'Simplifying Issue -> Share -> Verify process for everyone',
  footerUsefulLinkLabel: 'Company',
  footerUsefulLinks: [
    {
      name: 'About us',
      link: '#',
    },
    {
      name: 'Contact us',
      link: '#about',
    },
    {
      name: 'Careers',
      link: '/terms',
    },
  ],
  footerServiceLinkLabel: 'Other Links',
  footerServiceLinks: [
    {
      name: 'Privacy Policy',
      link: '#',
      target: '_blank',
    },
    {
      name: 'Terms and Condition',
      link: '#',
      target: '_blank',
    },
    {
      name: "FAQ's",
      link: '#',
      target: '_blank',
    },
  ],
  footerContactLabel: 'Need Help ? ',
  footerContactLinks: [
    {
      name: 'Email:',
      value: 'hello@parametro.com',
    },
    {
      name: 'Phone:',
      value: '+1 123 4567 8901',
    },
  ],
  footerCopyright: {
    brandTitle: 'vlinder',
    brandLogo:
      'https://s3.ap-south-1.amazonaws.com/io.vlinder.logos/parametro-logo.png',
    brandLink: 'https://www.vlinder.io',
    target: '_blank',
    copyrightTitle: 'vlinder',
    startYear: '2018',
  },
  socialLinks: [
    {
      name: 'facebook',
      link: '#',
    },
    {
      name: 'linkedin',
      link: '#',
    },
    {
      name: 'instagram',
      link: '#',
    },
  ],
};
