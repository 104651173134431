import React, { useEffect } from 'react';
import { logger as log } from '@common/logger';

import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';

import { actions } from './actions';
import { MACHINE_CONFIG } from './config';
import { services } from './services';

export const getFee = React.createContext({});
export let getFeeMachine: any;

const MODULE_NAME = 'machines.getFee';

export const useSMGetFee = (_context: any) => {
  let _machineConfig: any = MACHINE_CONFIG;
  if (_machineConfig && _machineConfig?.context) {
    _machineConfig.context = {
      ..._machineConfig.context,
      ...(_context || {}),
    };
  }
  getFeeMachine = createMachine(_machineConfig, {
    services,
    actions,
  });
  const [state, send] = useMachine(getFeeMachine);

  useEffect(() => {
    send('START', {});
  }, []);

  return {
    getFeeMachine,
    getFeeState: state,
    getFeeSend: send,
  };
};
