export const resubmitConfig = {
  submitButtonText: 'Submit',
  previewLabel: '',
  userFormTitle: 'Enter Applicant Information',
  workPermitLabel: 'Resubmit Work Permit Application - New',
  workPermitRenewalLabel: 'Resubmit Work Permit Application - Renewal',
  successIcon: 'https://i.ibb.co/RNvQN2X/success.png',
  errorIcon: 'https://i.ibb.co/GM7nsgp/icons8-error-48.png',
  workPermitSubLabel1: 'Enter Prospective Employee Details',
  workPermitSubLabel2: 'Enter Employer Details',
  acknowledgementText:
    'I certify to the best of my knowledge and belief, that the above information is correct and accept the responsibility for the support and repatriation expenses of the applicant and his family should the need arise.',
  progressBarSteps: [
    'Resubmit Application',
    'Resubmit Application',
    'Resubmit Application',
    'Resubmit Application',
    'Resubmit Application',
    'Acknowledgement',
  ],
  resubmittedSuccess: {
    title: 'Success!',
    icon: 'https://i.ibb.co/YTcSTsT/success-wp-1.png',
    description: ['Application has been resubmitted successfully'],
    acknowledgementButtonText: 'Close',
  },
  resubmittedFailure: {
    title: 'Uh oh.',
    icon: 'https://i.ibb.co/h7twZRf/failure-wp.png',
    description: ['Something wierd happened', 'Keep calm and try again'],
    acknowledgementButtonText: 'Close',
  },
};
