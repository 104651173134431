import { logger as log } from '@common/logger';
import { OrganisationProfilePageService } from '../../../services';
import { vappContainer } from '@/application';

const MODULE_NAME = 'profile.machines.states.createProfile';

const getSrv = () => {
  return vappContainer.get<OrganisationProfilePageService>(
    OrganisationProfilePageService.displayName
  );
};

export const createProfile = async (context: any, event: any) => {
  const currentState = 'CREATE_PROFILE';
  const method = 'createProfile';

  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      const srv = getSrv();
      const content = await srv.agent.createProfile(event?.payload);
      console.log("companysetperror",content)
      if (content) {
        return resolve({
          key: 'CREATE_PROFILE_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        console.log("companysetperror-->in state",context);
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'CREATE_PROFILE_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      console.log("companysetperror-->in state 2",context);
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'CREATE_PROFILE_FAILED',
        value: _msg,
      });
    }
  });
};
