import styled, { keyframes } from 'styled-components';

export const RoleCard = styled.div`
  transition: 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px lightgray !important;
  }
`;

const keyFrame = keyframes`
to {
    transform: rotate(360deg);
  }
`;

export const CustomLoader = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: inherit;
  }

  &::before {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #ff00cc 0%, #333399 100%);
    animation: ${keyFrame} 2s infinite linear;
  }

  &::after {
    width: 85%;
    height: 85%;
    background-color: white;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
