import { Container, injectable } from 'inversify';
import { EventBusModule } from '@common/event-bus-module';
import { StorageModule } from '@common/storage-module';
import { ProductLandingPageService } from '@pages/LandingPage/services/index';
import { AppNavigatorService } from '@/AppNavigator/services/index';
import { VendingMachinePageService } from '@pages/VendingMachinePage/services';
import { SignInPageService } from '@/pages/SignIn';
import { RegisterPageService } from '@/pages/Register';
import { SignUpPageService } from '@/pages/SignUp';
import { OrganisationProfilePageService } from '@/pages/OrganisationProfile';
import { ProfilePageService } from '@/pages/Profile';
import { AdminPageService } from '@/pages/Admin';
import { EmployeePageService } from '@/pages/Employee';
import { SubmittedAppPageService } from '@/pages/SubmittedApplications';
import { RejectedAppPageService } from '@/pages/RejectedApplications';
import { ApplicationStatusPageService } from '@/pages/ApplicationStatus';
import { ApplicationFormPageService } from '@/pages/ApplicationForm/services';
import { DraftAppPageService } from '@/pages/DraftApplications';
import { JwtModule } from '@common/jwt-module';
import { MasterAdminInvitePageService } from '@/pages/MasterAdminInvite';
import { ResetPasswordPageService } from '@/pages/ResetPassword/services';
import { ForgotPasswordPageService } from '@/pages/ForgotPassword/services';

import { StatusTimelinePageService } from '@/pages/StatusTimeLine';

import { ApplicationDetailPageService } from '@/pages/ApplicationDetail';

import { PaymentPageService } from '@/pages/Payment';

import { WorkPermitListPageService } from '@/pages/WorkPermitList';
import { ApprovedAppPageService } from '@/pages/ApprovedApplications';
import { MissingInfoPageService } from '@/pages/MissingInfo';
import { PendingPaymentPageService } from '@/pages/PendingPayments';
import { CSMECertificateListPageService } from '@/pages/CSMECertificateList';
import { MarriageCertificateListPageService } from '@/pages/MarriageCertificatesList';
import { GenericReportPageService } from '@/pages/GenericReport';
import { SwitchRolesPageService } from '@/pages/SwitchRoles/services';
import { UserListPageService } from '@/pages/UsersList';
import { UserProfilePageService } from '@/pages/UserProfile';
import { MasterReportPageService } from '@/pages/MasterReport';
import { CredentialListPageService } from '@/pages/CredentialList';
import { PaymentTransactionPageService} from "@/pages/PaymentTransactions";
import { CredentialDetailPageService } from '@/pages/CredentialDetail';

const vappContainer = new Container();

//Modules
vappContainer
  .bind<EventBusModule>(EventBusModule.displayName)
  .to(EventBusModule)
  .inSingletonScope();
vappContainer
  .bind<StorageModule>(StorageModule.displayName)
  .to(StorageModule)
  .inSingletonScope();

//Services
vappContainer
  .bind<ProductLandingPageService>(ProductLandingPageService.displayName)
  .to(ProductLandingPageService)
  .inSingletonScope();
vappContainer
  .bind<AppNavigatorService>(AppNavigatorService.displayName)
  .to(AppNavigatorService)
  .inSingletonScope();
vappContainer
  .bind<VendingMachinePageService>(VendingMachinePageService.displayName)
  .to(VendingMachinePageService)
  .inSingletonScope();
vappContainer
  .bind<SignInPageService>(SignInPageService.displayName)
  .to(SignInPageService)
  .inSingletonScope();
vappContainer
  .bind<RegisterPageService>(RegisterPageService.displayName)
  .to(RegisterPageService)
  .inSingletonScope();
vappContainer
  .bind<SignUpPageService>(SignUpPageService.displayName)
  .to(SignUpPageService)
  .inSingletonScope();
vappContainer
  .bind<OrganisationProfilePageService>(
    OrganisationProfilePageService.displayName
  )
  .to(OrganisationProfilePageService)
  .inSingletonScope();
vappContainer
  .bind<ProfilePageService>(ProfilePageService.displayName)
  .to(ProfilePageService)
  .inSingletonScope();
vappContainer
  .bind<AdminPageService>(AdminPageService.displayName)
  .to(AdminPageService)
  .inSingletonScope();
vappContainer
  .bind<EmployeePageService>(EmployeePageService.displayName)
  .to(EmployeePageService)
  .inSingletonScope();
vappContainer
  .bind<SubmittedAppPageService>(SubmittedAppPageService.displayName)
  .to(SubmittedAppPageService)
  .inSingletonScope();
vappContainer
  .bind<RejectedAppPageService>(RejectedAppPageService.displayName)
  .to(RejectedAppPageService)
  .inSingletonScope();
vappContainer
  .bind<ApplicationStatusPageService>(ApplicationStatusPageService.displayName)
  .to(ApplicationStatusPageService)
  .inSingletonScope();
vappContainer
  .bind<CredentialListPageService>(CredentialListPageService.displayName)
  .to(CredentialListPageService)
  .inSingletonScope();
vappContainer
  .bind<ApplicationFormPageService>(ApplicationFormPageService.displayName)
  .to(ApplicationFormPageService)
  .inSingletonScope();
vappContainer
  .bind<DraftAppPageService>(DraftAppPageService.displayName)
  .to(DraftAppPageService)
  .inSingletonScope();
vappContainer
  .bind<MasterAdminInvitePageService>(MasterAdminInvitePageService.displayName)
  .to(MasterAdminInvitePageService)
  .inSingletonScope();
vappContainer
  .bind<ResetPasswordPageService>(ResetPasswordPageService.displayName)
  .to(ResetPasswordPageService)
  .inSingletonScope();
vappContainer
  .bind<ForgotPasswordPageService>(ForgotPasswordPageService.displayName)
  .to(ForgotPasswordPageService)
  .inSingletonScope();
vappContainer
  .bind<StatusTimelinePageService>(StatusTimelinePageService.displayName)
  .to(StatusTimelinePageService)
  .inSingletonScope();
vappContainer
  .bind<ApplicationDetailPageService>(ApplicationDetailPageService.displayName)
  .to(ApplicationDetailPageService)
  .inSingletonScope();
vappContainer
  .bind<PaymentPageService>(PaymentPageService.displayName)
  .to(PaymentPageService)
  .inSingletonScope();
vappContainer
  .bind<WorkPermitListPageService>(WorkPermitListPageService.displayName)
  .to(WorkPermitListPageService)
  .inSingletonScope();
vappContainer
  .bind<ApprovedAppPageService>(ApprovedAppPageService.displayName)
  .to(ApprovedAppPageService)
  .inSingletonScope();
vappContainer
  .bind<MissingInfoPageService>(MissingInfoPageService.displayName)
  .to(MissingInfoPageService)
  .inSingletonScope();
vappContainer
  .bind<PendingPaymentPageService>(PendingPaymentPageService.displayName)
  .to(PendingPaymentPageService)
  .inSingletonScope();
vappContainer
  .bind<MarriageCertificateListPageService>(
    MarriageCertificateListPageService.displayName
  )
  .to(MarriageCertificateListPageService);
vappContainer
  .bind<GenericReportPageService>(GenericReportPageService.displayName)
  .to(GenericReportPageService)
  .inSingletonScope();
  vappContainer
  .bind<PaymentTransactionPageService>(PaymentTransactionPageService.displayName)
  .to(PaymentTransactionPageService)
  .inSingletonScope();

vappContainer
  .bind<CSMECertificateListPageService>(
    CSMECertificateListPageService.displayName
  )
  .to(CSMECertificateListPageService)
  .inSingletonScope();
vappContainer
  .bind<SwitchRolesPageService>(SwitchRolesPageService.displayName)
  .to(SwitchRolesPageService)
  .inSingletonScope();
vappContainer
  .bind<UserListPageService>(UserListPageService.displayName)
  .to(UserListPageService)
  .inSingletonScope();
vappContainer
  .bind<UserProfilePageService>(UserProfilePageService.displayName)
  .to(UserProfilePageService)
  .inSingletonScope();
vappContainer
  .bind<MasterReportPageService>(MasterReportPageService.displayName)
  .to(MasterReportPageService)
  .inSingletonScope();
vappContainer
  .bind<CredentialDetailPageService>(CredentialDetailPageService.displayName)
  .to(CredentialDetailPageService)
  .inSingletonScope();
vappContainer.bind<JwtModule>(JwtModule.displayName).to(JwtModule)
  .inSingletonScope;


export { vappContainer };
