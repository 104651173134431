export const loadingConfig = {
  loadingUsers: {
    image: '',
    titleNormalText: 'Retrieving your',
    titleBoldText: 'users list...',
    description: 'This might take few seconds, Please wait !',
  },
  inviteUser: {
    image: '',
    titleNormalText: 'Inviting user',
    description: 'This might take few seconds, Please wait !',
  },
  usersEmpty: {
    image: '',
    titleNormalText: 'Users',
    titleBoldText: 'Not found !',
    description: 'Inviting new users feature to manage this portal',
  },
  suspendUser: {
    image: '',
    titleNormalText: 'Updating admin user status',
    description: 'This might take few seconds, Please wait !',
  },
  deleteUser: {
    image: '',
    titleNormalText: 'Deleting admin user',
    description: 'This might take few seconds, Please wait !',
  },
};
