import React, { useState } from 'react';
import * as S from './styles';
export const CommentCard = (props: { comment: any; time?: any }) => {
  const { comment } = props;
  return (
    <>
      {/* <div style={{minHeight:"10vh",border:"0.1px solid grey",borderRadius:"5px",padding:"5px",margin:"7px"}}>
        {comment}
        <div style={{position:"relative",bottom:"1px",fontSize:"10pt"}}>{time}</div>
    </div> */}
      <div
        style={{
          boxShadow: '0 1px 2px 0 #0D8ABC, 0 1px 3px 0 #1F2937',
          backgroundColor: 'white',
          minHeight: '10vh',
          borderRadius: '6px 7px 4px 14px',
          padding: '10px',
          margin: '10px',
        }}
      >
        <div style={{ overflow: 'auto' }}>
          <h6>{comment}</h6>
        </div>
      </div>
    </>
  );
};
