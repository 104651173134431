export const updateProfile = {
  showModal: true,
  createModalLabel: 'Edit Organization Info',
  nameLabel: 'Organization Name',
  descriptionLabel: 'Description',
  emailLabel: 'Email Address',
  phoneNumberLabel: 'Contact No.',
  addressLabel: 'Address',
  websiteLabel: 'Website',
  submitButtonText: 'Edit Organization',
  logoLabel: 'Organization Logo',
};
