import React, { useEffect } from 'react';
import { logger as log } from '@common/logger';

import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';

import { actions } from './actions';
import { MACHINE_CONFIG } from './config';
import { services } from './services';

export const usersListPageContext = React.createContext({});
export let usersListPageMachine: any;

const MODULE_NAME = 'machines.pageContent';

export const useSMUsersListPageGetContent = (_context: any) => {
  let _machineConfig: any = MACHINE_CONFIG;
  if (_machineConfig && _machineConfig?.context) {
    _machineConfig.context = {
      ..._machineConfig.context,
      ...(_context || {}),
    };
  }
  usersListPageMachine = createMachine(_machineConfig, { services, actions });
  const [state, send] = useMachine(usersListPageMachine);

  useEffect(() => {
    console.log('machine came');
    send({ type: 'START' });
  }, []);

  return {
    usersListPageMachine,
    usersListPageState: state,
    usersListPageSend: send,
  };
};
