export const loadingConfig = {
  loadingUsers: {
    titleNormalText: 'Retrieving your',
    titleBoldText: 'Payment Transaction list...',
    description: 'This might take few seconds, Please wait !',
  },
  usersEmpty: {
    titleNormalText: 'Payment Transaction',
    titleBoldText: 'Not found !',
    description: '',
  },
  deleteDraft: {
    titleNormalText: 'Deleting Draft',
    description: 'This might take few seconds, Please wait !',
  },
  downloadReport: {
    titleNormalText: 'Request in progress',
    description: 'This might take few seconds, Please wait !',
  },
};
