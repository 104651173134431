export const loadingConfig = {
  loadingUsers: {
    titleNormalText: 'Retrieving ',
    titleBoldText: 'Admin list...',
    description: 'This might take few seconds, Please wait !',
  },
  inviteUser: {
    titleNormalText: 'Inviting Admin',
    description: 'This might take few seconds, Please wait !',
  },
  usersEmpty: {
    image: '',
    titleNormalText: 'Admin Users',
    titleBoldText: 'Not found !',
    description: 'Inviting new users feature to manage this portal',
  },
  suspendUser: {
    image: '',
    titleNormalText: 'Updating admin user status',
    description: 'This might take few seconds, Please wait !',
  },
  deleteUser: {
    image: '',
    titleNormalText: 'Deleting user',
    description: 'This might take few seconds, Please wait !',
  },
  notAuthorized: {
    image: '',
    titleNormalText:
      'Please update the Organization/Employer Details under Organization Page',
    titleBoldText: '',
    description: 'Your Profile is not yet approved',
  },
};
