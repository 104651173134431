export const APPLICATION_TYPE: any = {
  NEW_WORK_PERMIT: 'New Work Permit',
  RENEW_WORK_PERMIT: 'Renew Work Permit',
  WORK_PERMIT_APPEAL: 'Work Permit Appeal',
  NEW_CSME_CERTIFICATE: 'CSME Certificate',
  RENEW_MARRIAGE_EXEMPTION: 'Renew Marriage Exemption',
  CSME_VERIFICATION: 'CSME Verification',
  MARRIAGE_EXEMPTION: 'Marriage Exemption',
  NEW_RIGHTS_OF_ESTABLISHMENT: 'Rights of Establishment',
  CARICOM_NEW_WORK_PERMIT: 'New Work Permit',
  CARICOM_RENEW_WORK_PERMIT: 'Renew Work Permit',
};

export const CREDENTIAL_TYPE:any = {
  WORK_PERMIT: 'e - Work Permit',
  CSME_CERTIFICATE: 'e - CSME Certificate',
  MARRIAGE_EXEMPTION_CERTIFICATE: 'e - Marriage Exemption',
  RIGHTS_OF_ESTABLISHMENT: 'e - Right of Establishment',
};
