export const loadingConfig = {
  loadingUsers: {
    image: '',
    titleNormalText: 'Retrieving ',
    titleBoldText: 'applications...',
    description: 'This might take few seconds, Please wait !',
  },
  inviteUser: {
    image: '',
    titleNormalText: 'Inviting user',
    description: 'This might take few seconds, Please wait !',
  },
  usersEmpty: {
    titleNormalText: 'Applications',
    titleBoldText: 'Not found !',
    description: 'Kindly create an application',
  },
  suspendUser: {
    image: '',
    titleNormalText: 'Updating admin user status',
    description: 'This might take few seconds, Please wait !',
  },
  deleteUser: {
    image: '',
    titleNormalText: 'Deleting admin user',
    description: 'This might take few seconds, Please wait !',
  },
};
