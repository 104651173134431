export const settingsConfig = {
  searchStatusFilter: [
    {
      label: 'Select Status',
      value: '',
    },
    {
      value: 'APPLICATION_INCOMPLETE',
      label: 'Employer Application Incomplete',
    },
    {
      value: 'APPLICANT_DRAFT',
      label: 'Applicant Application Incomplete',
    },
  ],
  tableHeaders: [
    {
      key: 'Email',
      filterTypes: [
        {
          label: 'Contains',
          type: 'text',
          id: 'email',
        },
        {
          label: 'Equal',
          type: 'text',
          id: 'email',
        },
      ],
    },
    {
      key: 'First Name',
      filterTypes: [
        {
          label: 'ContainsCI',
          type: 'text',
          id: 'firstName',
        },
      ],
    },
    {
      key: 'Last Name',
      filterTypes: [
        {
          label: 'ContainsCI',
          type: 'text',
          id: 'lastName',
        },
      ],
    },
  ],
};
