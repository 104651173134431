import { parse } from 'tldts';
import emailProviders from 'email-providers/all.json';

// const isPersonalEmail = (email: string): boolean => {
//   const domain = parse(email).domain;
//   return domain ? emailProviders.includes(domain) : false;
// };
export const workPermitConfig = {
  nextStepLabel: 'Next',
  submitButtonText: 'Submit',
  previewLabel: '',
  userFormTitle: 'Enter Applicant Information',
  workPermitLabel: 'Work Permit Application - New',
  workPermitRenewalLabel: 'Work Permit Application - Renewal',
  workPermitAppealLabel: 'Work Permit Application - Appeal',
  successIcon: 'https://i.ibb.co/RNvQN2X/success.png',
  errorIcon: 'https://i.ibb.co/GM7nsgp/icons8-error-48.png',
  workPermitSubLabel1: 'Enter Prospective Employee Details',
  workPermitSubLabel2: 'Enter Employer Details',
  acknowledgementText:
    'I certify to the best of my knowledge and belief, that the above information is correct and accept the responsibility for the support and repatriation expenses of the applicant and his family should the need arise.',
  progressBarSteps: [
    'Work Permit Application - New',
    'Work Permit Application - New',
    'Work Permit Application - New',
    'Work Permit Application - New',
    'Work Permit Application - New',
    'Acknowledgement',
  ],
  fields: [
    [
      {
        type: 'radio',
        compProps: {
          label: 'Do you have a Last Name or Surname?',
          id: 'isLastNameExist',
          direction: 'horizontal',
          required: true,
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Last Name',
          id: 'lastName',
          placeholder: 'Enter Last Name(no special characters, no spaces)',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Maiden name',
          id: 'maidenName',
          placeholder: 'Enter Maiden Name(no special characters, no spaces)',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'First Name (Given Name)',
          id: 'firstName',
          placeholder: 'Enter First Name(no special characters, no spaces)',
          required: true,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'Middle Name',
          id: 'middleInitial',
          placeholder: 'Enter Middle Name(no special characters, no spaces)',
          required: false,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Alias/Other Names',
          id: 'alias',
          placeholder: 'Enter Alias(no special characters, no spaces)',
          required: false,
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'userEmail',
          placeholder: 'Enter Email address',
          required: true,
          disabled: true,
          readOnly: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Gender',
          id: 'gender',
          direction: 'horizontal',
          required: true,
          options: [
            {
              label: 'Male',
              id: 'male',
            },
            {
              label: 'Female',
              id: 'female',
            },
          ],
        },
      },
      {
        type: 'datepicker',
        compProps: {
          id: 'dateOfBirth',
          label: 'Date of Birth',
          required: true,
          isAdult: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Address Line 1',
          rows: 1,
          id: 'address1',
          placeholder: 'Enter Address Line 1',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Address Line 2',
          rows: 1,
          id: 'address2',
          placeholder: 'Enter Address Line 2',
          required: false,
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'State/Province',
          id: 'parish',
          placeholder: 'Enter State/Province',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'City/Town',
          id: 'city',
          placeholder: 'Enter City/Town',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Zip/Postal Code',
          id: 'postalCode',
          placeholder: 'Enter Zip/Postal Code',
          required: false,
        },
      },

      {
        type: 'countrySelect',
        compProps: {
          label: 'Country',
          id: 'country',
          placeholder: 'Select Country',
          required: true,
          excludeCountries: ['jm'],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Place of Birth',
          id: 'placeOfBirth',
          placeholder:
            'Enter Place of Birth(no numbers ,no special characters)',
          required: false,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Other Nationality',
          id: 'otherNationality',
          placeholder: 'Enter Other Nationality',
          required: false,
          excludeCountries: ['jm'],
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Country of Birth',
          id: 'countryOfBirth',
          placeholder: 'Enter Country of Birth',
          required: true,
          excludeCountries: ['jm'],
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Nationality',
          id: 'nationality',
          placeholder: 'Enter Nationality',
          required: true,
          excludeCountries: ['jm'],
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Marital Status',
          id: 'maritalStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Single',
              id: 'single',
            },
            {
              label: 'Divorced',
              id: 'divorced',
            },
            {
              label: 'Widowed',
              id: 'widowed',
            },
            {
              label: 'Married',
              id: 'married',
            },
            {
              label: 'Separated',
              id: 'separated',
            },
          ],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Do you have Children / Dependents',
          id: 'isAddChildren',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'no',
              id: 'no',
            },
          ],
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'Number of Dependents',
          id: 'noOfChildren',
          placeholder: 'Enter Number of Children / Dependents',
          required: false,
        },
      },
      {
        type: 'dynamicFields',
        compProps: {
          id: 'dependentInfo',
          cardType: 'Dependent',
          label: 'Dependents Info',
          lookUpField: 'noOfChildren',
          required: false,
          errorText: 'Please enter all Children / Dependent info',
          attributeForm: {
            modalTitle: 'Add Dependent Info',
            editTitle: 'Edit Dependent Info',
            fields: [
              {
                type: 'select',
                compProps: {
                  label: 'Dependent Type',
                  id: 'dependentType',
                  options: [
                    {
                      label: 'Minor Child',
                      id: 'MINOR_CHILD',
                    },
                    {
                      label: 'Spouse ',
                      id: 'SPOUSE',
                    },
                    {
                      label: 'Other',
                      id: 'OTHER',
                    },
                  ],
                  required: true,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Name',
                  id: 'dependentName',
                  placeholder: 'Enter Name',
                  required: true,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Relationship',
                  id: 'dependentRelationship',
                  placeholder: 'Enter Relationship',
                  required: true,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Passport Number',
                  id: 'dependentPassport',
                  placeholder:
                    'Enter Passport Number(no special characters,no spaces )',
                  required: true,
                },
              },
            ],
            layout: [
              ['dependentType'],
              ['dependentName'],
              ['dependentRelationship'],
              ['dependentPassport'],
            ],
            validatorConfig: {
              ruleSets: [
                {
                  fields: {
                    dependentPassport: [
                      {
                        type: 'matches',
                        pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
                        errorMessage: 'Passport is not valid',
                      },
                    ],
                    dependentName: [
                      {
                        type: 'matches',
                        pattern: '^[A-Za-z ]+$',
                        errorMessage: 'Name is invalid.',
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'TRN',
          id: 'trn',
          placeholder:
            'Enter your TRN(no special characters,no space) ex: 123456789',
          required: false,
          // disabled:true
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Passport Issue Date',
          id: 'passportIssuanceDate',
          isPast: true,
          required: true,
        },
      },

      {
        type: 'fileDropZone',
        compProps: {
          label: 'Resume',
          id: 'resumeProof',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'dynamicFields',
        compProps: {
          id: 'qualification',
          cardType: 'Qualification',
          label: 'Qualification (In support of Work Permit Application)',
          required: true,
          attributeForm: {
            modalTitle: 'Add Qualification',
            editTitle: 'Edit Qualification',
            fields: [
              // {
              //   type: 'text',
              //   compProps: {
              //     label: 'Qualification',
              //     id: 'qualificationName',
              //     placeholder: 'Enter Qualification',
              //     required: true,
              //   },
              // },
              {
                type: 'select',
                compProps: {
                  label: 'Qualification',
                  id: 'qualificationName',
                  placeholder: 'Enter Qualification',
                  required: true,
                  options: [
                    {
                      label: 'High School Certificate',
                      id: 'highSchoolCertificate',
                    },
                    {
                      label: 'Bachelors',
                      id: 'bachelors',
                    },
                    {
                      label: 'Masters',
                      id: 'masters',
                    },
                    {
                      label: 'Doctrate',
                      id: 'doctrate',
                    },
                    {
                      label: 'Professional',
                      id: 'professional',
                    },
                    {
                      label: 'Other',
                      id: 'otherDegree',
                    },
                  ],
                },
              },
              {
                type: 'text',
                compProps: {
                  id: 'degreeIfOther',
                  placeholder: 'Please specify',
                  required: true,
                },
              },
              {
                type: 'datepicker',
                compProps: {
                  id: 'dateOfQualification',
                  label: 'Received Date',
                  required: true,
                  isPast: true,
                },
              },
            ],
            layout: [
              ['qualificationName'],
              ['dateOfQualification'],
              //['workExperienceProof'],
            ],
            dynamicLayout: {
              qualificationName: {
                otherDegree: [['degreeIfOther']],
              },
            },

            validatorConfig: {
              ruleSets: [
                {
                  fields: {
                    qualificationName: [
                      {
                        type: 'matches',
                        pattern: '^[A-Za-z ]+$',
                        errorMessage: 'Name is invalid.',
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          id: 'qualificationProof',
          label: 'Proof of Qualification in support of Work Permit Application',
          maxFiles: 5,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Requested Work Permit Start Date',
          id: 'permitPeriodStart',
          required: true,
          isFuture: true,
          before: 'permitPeriodEnd',
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Requested Work Permit End Date',
          id: 'permitPeriodEnd',
          required: true,
          isFuture: true,
          after: 'permitPeriodStart',
        },
      },
      {
        type: 'sectionTitle',
        compProps: {
          id: 'passportInfo',
          label: 'PASSPORT INFORMATION:',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Passport Number',
          id: 'passport',
          placeholder: 'Enter Passport Number(no special characters,no spaces)',
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Passport Expiry',
          id: 'passportExpiry',
          required: true,
          isPassport: true,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Place of Issue',
          id: 'typeOfPassport',
          placeholder: 'Enter Type of Passport (Country Issued)',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Biodata Page of Passport',
          id: 'passportProof',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Photograph',
          id: 'photograph',

          maxFiles: 1,
          supportedFormat: ['image/png', 'image/jpeg', 'image/jpg'],
          required: true,
        },
      },
      {
        type: 'dynamicFields',
        compProps: {
          id: 'workExperience',
          cardType: 'Work Experience',
          label: 'Work Experience',
          required: true,
          attributeForm: {
            modalTitle: 'Add Work Experience',
            editTitle: 'Edit Work Experience',
            fields: [
              {
                type: 'text',
                compProps: {
                  label: 'Name of Organization',
                  id: 'organizationName',
                  placeholder: 'Enter Name of Organization',
                  required: true,
                },
              },
              {
                type: 'datepicker',
                compProps: {
                  label: 'Start Date',
                  id: 'wePeriodStart',
                  required: true,

                  before: 'wePeriodEnd',

                  isPast: true,
                },
              },
              {
                type: 'datepicker',
                compProps: {
                  label: 'End Date',
                  id: 'wePeriodEnd',
                  after: 'wePeriodStart',
                  required: true,
                  isPast: true,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Job Duty/Type of Work',
                  id: 'jobDutyType',
                  placeholder: 'Enter Job Duty/Type of Work',
                  required: true,
                },
              },
            ],
            layout: [
              ['organizationName'],
              ['wePeriodStart', 'wePeriodEnd'],
              ['jobDutyType'],
              //['workExperienceProof'],
            ],
            validatorConfig: {
              ruleSets: [
                {
                  fields: {
                    // organizationName: [
                    //   {
                    //     type: 'matches',
                    //     pattern: '^[A-Za-z ]+$',
                    //     errorMessage: 'Name is invalid.',
                    //   },
                    // ],
                  },
                },
              ],
            },
          },
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Were you previously employed',
          id: 'previousEmployment',
          direction: 'horizontal',
          required: true,
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          id: 'workExperienceProof',
          label: 'Reference letter from previous Employer',
          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',
            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Justification for foreign employee',
          rows: 1,
          id: 'justifcationForForeignEmployee',
          placeholder: 'Enter Justification for foreign employee',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Skills of Applicant',
          rows: 1,
          id: 'skills',
          placeholder: 'Enter Skills of Applicant',
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label:
            'Have you ever been convicted of any criminal offence in any country?',
          id: 'criminalOffence',
          direction: 'horizontal',
          required: true,
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Country',
          id: 'countryConvicted',
          placeholder: 'Select Country in which convicted',
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Date of Conviction',
          id: 'dateOfConviction',
          required: true,
          isPast: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Nature of Offence',
          id: 'natureOfOffence',
          placeholder: 'Enter Nature of Offence',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Police Certificate',
          id: 'policeClearanceCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },

      {
        type: 'radio',
        compProps: {
          label: 'Have you ever worked in Jamaica (Yes/No)?',
          id: 'workedInJamaica',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: false,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Have your Husband/Wife ever worked in Jamaica (Yes/No)?',
          id: 'isHusbandWifeWorkedInJamaica',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: false,
        },
      },
      {
        type: 'sectionTitle',
        compProps: {
          id: 'detailsOnPrevious',
          label: 'Details on previous (Last) Employer in Jamaica',
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name of Previous Employer',
          id: 'nameOfEmployer',
          placeholder: 'Enter Name of Previous Employer',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Previous Employer Address Line 1',
          id: 'employerAddress1',
          placeholder: 'Enter Previous Employer Address Line 1',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Previous Employer Address Line 2',
          id: 'employerAddress2',
          placeholder: 'Enter Previous Employer Address Line 2',
          required: false,
        },
      },

      {
        type: 'select',
        compProps: {
          label: 'Parish',
          id: 'employerParish',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'City/Town',
          id: 'employerCity',
          placeholder: 'Enter City/Town',
          required: true,
          options: [],
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone Number',
          id: 'telephoneOfEmployer',
          placeholder: 'Enter Telephone Number',
          required: false,
        },
      },
      {
        type: 'text',
        compProps: {
          label: "Employee's Work Permit Number",
          id: 'workPermitNumber',
          placeholder: "Enter Employee's Work Permit Number",
          required: false,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: "Employee's Work Permit Expiry Date",
          id: 'workPermitExpiry',
          required: false,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Previous Employer Reference Letter Attachment',
          id: 'previousEmployerProof',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },

      {
        type: 'sectionTitle',
        compProps: {
          id: 'detailsOfHusbandWife',
          label: "Details of Husband's/Wife's previous Employment in Jamaica",
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name of Employer',
          id: 'nameOfEmployerDep',
          placeholder: 'Enter Name of Employer',
          required: true,
        },
      },
      // {
      //   type: 'phoneNumber',
      //   compProps: {
      //     label: 'Telephone Number',
      //     id: 'telephoneOfHusbandWife',
      //     placeholder: 'Enter Telephone Number',
      //     required: true,
      //   },
      // },
      {
        type: 'textarea',

        compProps: {
          label: 'Employer Address Line 1',
          id: 'employerAddress1Dep',
          placeholder: 'Enter Address Line 1',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Employer Address Line 2',
          id: 'employerAddress2Dep',
          placeholder: 'Enter Address Line 2',
          required: false,
        },
      },

      {
        type: 'select',
        compProps: {
          label: 'Parish',
          id: 'employerParishDep',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'City/Town',
          id: 'employerCityDep',
          placeholder: 'Enter City/Town',
          required: true,
          options: [],
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Work Permit Number',
          id: 'workPermitNumberDep',
          placeholder: 'Enter Work Permit Number',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Previous Employer Reference Letter Attachment',
          id: 'previousEmployerProofDep',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Work Permit Expiry Date',
          id: 'workPermitExpiryDep',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Signature',
          id: 'signatureDep',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'checkBox',
        compProps: {
          id: 'firstAcknowledgementCheck',
          options: [
            {
              id: 'yes',
              label:
                "I certify that I have carefully read the General Information and Application Guidelines sections on the Ministry of Labour and Social Security's Work Permit Website and I understand all requirements and restrictions for making this application.",
            },
          ],
          required: true,
        },
      },
    ],
    [
      {
        type: 'text',
        compProps: {
          label: 'Business Name',
          id: 'businessName',
          placeholder: 'Enter Business Name',
          required: true,
          readOnly: true,
          disabled: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label: 'Business Address Line 1',
          id: 'businessAddress1',
          placeholder: 'Enter Address Line 1',
          required: true,
          disabled: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          label: 'Business Address Line 2',
          rows: 1,
          id: 'businessAddress2',
          placeholder: 'Enter Address Line 2',
          required: false,
          disabled: true,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Business Parish',
          id: 'businessParish',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Business City/Town',
          id: 'businessCity',
          placeholder: 'Enter City/Town',
          required: true,
          options: [],
        },
      },

      {
        type: 'numbertext',
        compProps: {
          label: 'Postal Code',
          id: 'businessPostalCode',
          placeholder: 'Enter Postal Code',
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          id: 'isMailingAddressSame',
          direction: 'horizontal',
          label: 'Mailing Address same as above',
          value: 'yes',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          rows: 1,
          label: ' Mailing Address Line 1',
          id: 'mailingAddress1',
          placeholder: 'Enter Address Line 1',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: ' Mailing Address Line 2',
          rows: 1,
          id: 'mailingAddress2',
          placeholder: 'Enter Address Line 2',
          required: false,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Mailing Parish',
          id: 'mailingParish',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Mailing City/Town',
          id: 'mailingCity',
          placeholder: 'Enter City/Town',
          required: true,
          options: [],
        },
      },

      {
        type: 'numbertext',
        compProps: {
          label: 'Postal Code',
          id: 'mailingPostalCode',
          placeholder: 'Enter Postal Code',
          required: true,
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone Number',
          id: 'telephoneNumber',
          placeholder: 'Enter Telephone Number',
          required: true,
        },
      },

      {
        type: 'select',
        compProps: {
          label: 'Qualifications Necessary for Job',
          id: 'qualifications',
          placeholder: 'Enter Job Qualifications Necessary for Job',
          required: true,
          options: [
            {
              label: 'High School Certificate',
              id: 'highSchoolCertificate',
            },
            {
              label: 'Bachelors',
              id: 'bachelors',
            },
            {
              label: 'Masters',
              id: 'masters',
            },
            {
              label: 'Doctrate',
              id: 'doctrate',
            },
            {
              label: 'Professional',
              id: 'professional',
            },
            {
              label: 'Other',
              id: 'otherDegree',
            },
          ],
        },
      },
      {
        type: 'textarea',
        compProps: {
          rows: 1,
          label: 'Job Title',
          id: 'jobTitle',
          placeholder: 'Enter Job Title',
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          rows: 1,
          label: 'Job Duties to be Performed',
          id: 'jobDuties',
          placeholder: 'Enter Job Duties to be Performed',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Job Description',
          id: 'jobTitleProof',
          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Cover Letter',
          id: 'coveringLetterProof',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Reference Letter',
          id: 'referenceLetter',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'email',
          placeholder: 'Enter Email address',
          required: true,
          readOnly: true,
          disabled: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Nature of Business',
          id: 'natureOfBusiness',
          placeholder: 'Enter Nature of Business',
          required: true,
          disabled: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'TRN',
          id: 'businessTrn',
          placeholder:
            'Enter your TRN , should not contain any special characters ex: 123456789',
          required: true,
          readOnly: true,
          disabled: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Tax Compliance Certificate (TCC)  Number',
          id: 'tccNumber',
          placeholder:
            'Enter your TCC Number (no special characters, no spaces)',
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'TCC Expiry Date',
          id: 'tccExpiryDate',
          required: true,
          isFuture: true,
          isExpiredCheck: true,
          expiryMessage: 'TCC Expired',
          disabled: true,
          readOnly: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Date of Registration',
          id: 'dateOfReg',
          required: true,
          isPast: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'The request for Work Permit/Exemption is in relation to:',
          id: 'workPermit',
          direction: 'horizontal',
          options: [
            {
              label: 'By Multilateral Agreement',
              id: 'multilateral',
            },
            {
              label: 'Investment by Overseas Organization',
              id: 'overseas',
            },
            {
              label: 'Others',
              id: 'others',
            },
          ],
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'If Others, Work Permit/Exemption is in relation to',
          id: 'workPermitOthers',
          placeholder: 'Please specify',
          required: true,
        },
      },

      {
        type: 'sectionTitle',
        compProps: {
          id: 'stepsTaken',
          label: 'Steps taken to employ Jamaican National',
        },
      },
      {
        type: 'radio',
        compProps: {
          label:
            'Has this job been registered on the LMIS portal? If yes, please provide the Job ID',
          id: 'registeredLMIS',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Job ID',
          id: 'jobId',
          placeholder:
            'Enter the Job ID (no special characters,no spaces ex:JD1001)',
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Internal Recruitment',
          id: 'internalRecruitment',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label:
            'Has the job been advertised locally in a written or online  newspaper or other media?',
          id: 'byAdvertisement',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Proof of Advertisement',
          id: 'byAdvertisementFile',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'If the job was advertised locally, did a Jamaican apply?',
          id: 'didJamaicanApply',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: false,
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'How many applied?',
          id: 'appliedCandidates',
          placeholder: 'Enter number of candidates applied',
          required: true,
        },
      },
      {
        type: 'textarea',
        compProps: {
          rows: 1,
          label: 'Why were none hired?',
          id: 'reasonNoneHired',
          placeholder: 'Enter reason why none were hired',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Proof of candidate resumes',
          id: 'candidateResumes',
          maxFiles: 100,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: false,
        },
      },
      {
        type: 'textarea',
        compProps: {
          rows: 1,
          label: 'If no step was taken please state reason',
          id: 'noStepTaken',
          placeholder: 'Please state reason for not taking any step',
          required: true,
        },
      },
      {
        type: 'multiSelect',
        compProps: {
          label: 'Occupation',
          id: 'occupation',
          lookUpField: 'occupationList',
          placeholder: 'Select Occupation (Listed in JSOC)',
          errorText1: 'Please select appropriate Occupation',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          label:
            'How much the worker will receive in salary or wage? (Gross per Annum in Jamaican Currency)',
          id: 'salaryOffered',
          placeholder: 'Salary Offered',
          currency: 'J$',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          label:
            'How many hours will the worker be required to work each week?',
          id: 'workingHours',

          placeholder: 'Hours Required',
          min: 1,
          max: 168,
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Will the worker receive other benifits (Yes/No)?',
          id: 'benefitsReceived',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: false,
        },
      },
      {
        type: 'sectionTitle',
        compProps: {
          id: 'perquisites',
          label: 'Perquisites (Allowances) per annum',
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'House',
          id: 'house',
          placeholder: 'House',
          currency: 'J$',

          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'Car',
          id: 'car',
          placeholder: 'Car',
          currency: 'J$',

          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'Entertainment',
          id: 'entertainment',
          placeholder: 'Entertainment',
          currency: 'J$',

          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'Other',
          id: 'allowanceOther',
          placeholder: 'Other',
          currency: 'J$',

          required: true,
        },
      },
      {
        type: 'sectionTitle',
        compProps: {
          id: 'jamaican',
          label: 'NUMBER OF JAMAICAN NATIONALS:',
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'jamaicanProfessional',
          placeholder: 'PROFESSIONAL',
          label: 'PROFESSIONAL',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'jamaicanClerks',
          placeholder: 'CLERKS/ SERVICE WORKER',
          label: 'CLERKS/ SERVICE WORKER',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'jamaicanSkilledWorkers',
          placeholder: 'SKILLED WORKERS',
          label: 'SKILLED WORKERS',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'jamaicanPlant',
          placeholder: 'PLANT & MACHINE OPERATORS',
          label: 'PLANT & MACHINE OPERATORS',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'jamaicanElementary',
          placeholder: 'ELEMENTARY OCCUPATIONS',
          label: 'ELEMENTARY OCCUPATIONS',
          required: true,
        },
      },

      {
        type: 'sectionTitle',
        compProps: {
          id: 'carricom',
          label: 'NUMBER OF CARICOM NATIONALS:',
        },
      },

      {
        type: 'number',
        compProps: {
          id: 'carricomProfessional',
          placeholder: 'PROFESSIONAL',
          label: 'PROFESSIONAL',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'carricomClerks',
          placeholder: 'CLERKS/ SERVICE WORKER',
          label: 'CLERKS/ SERVICE WORKER',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'carricomSkilledWorkers',
          placeholder: 'SKILLED WORKERS',
          label: 'SKILLED WORKERS',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'carricomPlant',
          placeholder: 'PLANT & MACHINE OPERATORS',
          label: 'PLANT & MACHINE OPERATORS',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'carricomElementary',
          placeholder: 'ELEMENTARY OCCUPATIONS',
          label: 'ELEMENTARY OCCUPATIONS',
          required: true,
        },
      },

      {
        type: 'sectionTitle',
        compProps: {
          id: 'commonWealth',
          label: 'NUMBER OF COMMON-WEALTH NATIONALS:',
        },
      },

      {
        type: 'number',
        compProps: {
          id: 'commonWealthProfessional',
          placeholder: 'PROFESSIONAL',
          label: 'PROFESSIONAL',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'commonWealthClerks',
          placeholder: 'CLERKS/ SERVICE WORKER',
          label: 'CLERKS/ SERVICE WORKER',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'commonWealthSkilledWorkers',
          placeholder: 'SKILLED WORKERS',
          label: 'SKILLED WORKERS',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'commonWealthPlant',
          placeholder: 'PLANT & MACHINE OPERATORS',
          label: 'PLANT & MACHINE OPERATORS',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'commonWealthElementary',
          placeholder: 'ELEMENTARY OCCUPATIONS',
          label: 'ELEMENTARY OCCUPATIONS',
          required: true,
        },
      },

      {
        type: 'sectionTitle',
        compProps: {
          id: 'foreign',
          label: 'NUMBER OF FOREIGN NATIONALS:',
        },
      },

      {
        type: 'number',
        compProps: {
          id: 'foreignProfessional',
          placeholder: 'PROFESSIONAL',
          label: 'PROFESSIONAL',
          required: true,
          defaultValue: 0,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'foreignClerks',
          placeholder: 'CLERKS/ SERVICE WORKER',
          label: 'CLERKS/ SERVICE WORKER',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'foreignSkilledWorkers',
          placeholder: 'SKILLED WORKERS',
          label: 'SKILLED WORKERS',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'foreignPlant',
          placeholder: 'PLANT & MACHINE OPERATORS',
          label: 'PLANT & MACHINE OPERATORS',
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          id: 'foreignElementary',
          placeholder: 'ELEMENTARY OCCUPATIONS',
          label: 'ELEMENTARY OCCUPATIONS',
          required: true,
        },
      },
      {
        type: 'checkBox',
        compProps: {
          id: 'finalAcknowledgementCheck',
          options: [
            {
              id: 'yes',
              label:
                "I certify that I have carefully read the General Information and Application Guidelines sections on the Ministry of Labour and Social Security's Work Permit Website and I understand all requirements and restrictions for making this application.",
            },
          ],
          required: true,
        },
      },
    ],
  ],
  layout: [
    {
      first: {
        label: 'Personal Details',
        data: [
          ['isLastNameExist'],
          ['firstName'],
          ['middleInitial', 'alias'],
          ['gender', 'dateOfBirth'],
          ['address1', 'address2'],
          ['parish', 'city'],
          ['postalCode', 'country'],
          ['userEmail', 'placeOfBirth'],
          ['nationality', 'countryOfBirth'],
          ['maritalStatus'],
          ['otherNationality'],
          ['noOfChildren', 'trn'],
          ['dependentInfo'],
          ['passportInfo'],
          ['passport', 'passportExpiry'],
          ['typeOfPassport', 'passportIssuanceDate'],
          ['passportProof'],
          ['photograph'],
          ['criminalOffence'],
          ['policeClearanceCertificate'],
        ],
      },

      second: {
        label: 'Work Details',
        data: [
          ['qualification'],
          ['qualificationProof'],
          ['permitPeriodStart', 'permitPeriodEnd'],
          ['workExperience'],
          ['previousEmployment'],
          // ['workedInJamaica'],
          // ['isHusbandWifeWorkedInJamaica'],
          ['skills'],
          ['resumeProof'],
        ],
      },
      third: {
        label: 'Acknowledgement',
        data: [['firstAcknowledgementCheck']],
      },
    },

    {
      first: {
        label: 'Business Details',
        data: [
          ['businessName'],
          ['businessAddress1', 'businessAddress2'],
          ['businessParish', 'businessCity'],
          //['businessParish', 'businessPostalCode'],
          ['isMailingAddressSame'],
          ['telephoneNumber'],

          ['natureOfBusiness'],
          ['email', 'businessTrn'],
          ['tccNumber', 'tccExpiryDate'],
          ['dateOfReg'],
          ['workPermit'],
        ],
      },
      second: {
        label: 'Job Details',
        data: [
          ['qualifications'],
          ['jobTitle', 'jobDuties'],
          ['jobTitleProof'],
          ['coveringLetterProof'],
          ['referenceLetter'],
        ],
      },

      third: {
        label: 'Steps Taken',
        data: [
          ['stepsTaken'],
          ['registeredLMIS'],
          ['internalRecruitment'],
          ['byAdvertisement'],
          ['noStepTaken'],
          ['occupation'],
          ['salaryOffered', 'workingHours'],
          ['benefitsReceived'],
        ],
      },
      fourth: {
        label: 'Staff Composition',
        data: [
          ['jamaican'],
          ['jamaicanProfessional', 'jamaicanClerks', 'jamaicanSkilledWorkers'],
          ['jamaicanPlant', 'jamaicanElementary', ''],
          ['carricom'],
          ['carricomProfessional', 'carricomClerks', 'carricomSkilledWorkers'],
          ['carricomPlant', 'carricomElementary', ''],
          ['commonWealth'],
          [
            'commonWealthProfessional',
            'commonWealthClerks',
            'commonWealthSkilledWorkers',
          ],
          ['commonWealthPlant', 'commonWealthElementary', ''],
          ['foreign'],
          ['foreignProfessional', 'foreignClerks', 'foreignSkilledWorkers'],
          ['foreignPlant', 'foreignElementary', ''],
          // ['finalAcknowledgementCheck'],
        ],
      },
    },
  ],
  dynamicLayout: {
    previousEmployment: {
      yes: [['workExperienceProof'], ['workedInJamaica']],
      no: [['justifcationForForeignEmployee']],
    },
    isLastNameExist: {
      yes: [['lastName']],
    },
    criminalOffence: {
      yes: [['countryConvicted', 'dateOfConviction'], ['natureOfOffence']],
    },
    gender: {
      female: [['maidenName']],
    },
    workedInJamaica: {
      yes: [
        ['detailsOnPrevious'],
        ['nameOfEmployer'],
        ['employerAddress1', 'employerAddress2'],
        ['employerParish', 'employerCity'],
        ['telephoneOfEmployer'],
        ['workPermitNumber', 'workPermitExpiry'],
        ['previousEmployerProof'],
      ],
    },
    maritalStatus: {
      married: [['isHusbandWifeWorkedInJamaica']],
    },
    isHusbandWifeWorkedInJamaica: {
      yes: [
        ['detailsOfHusbandWife'],
        ['nameOfEmployerDep'],

        ['employerAddress1Dep', 'employerAddress2Dep'],
        ['employerParishDep', 'employerCityDep'],
        ['workPermitNumberDep', 'workPermitExpiryDep'],
        ['previousEmployerProofDep'],
      ],
    },
    isMailingAddressSame: {
      no: [
        ['mailingAddress1', 'mailingAddress2'],
        ['mailingParish', 'mailingCity'],
        //['mailingParish', 'mailingPostalCode'],
      ],
    },

    workPermit: {
      others: [['workPermitOthers']],
    },
    byAdvertisement: {
      yes: [['byAdvertisementFile'], ['didJamaicanApply']],
    },
    didJamaicanApply: {
      yes: [['appliedCandidates', 'reasonNoneHired'], ['candidateResumes']],
    },
    registeredLMIS: {
      yes: [['jobId']],
    },
    benefitsReceived: {
      yes: [
        ['perquisites'],
        ['house', 'car'],
        ['entertainment', 'allowanceOther'],
      ],
    },
    // maritalStatus: {
    //   divorced: [['husbandOrWifeName', 'husbandOrWifeNationality']],
    //   widowed: [['husbandOrWifeName', 'husbandOrWifeNationality']],
    //   married: [['husbandOrWifeName', 'husbandOrWifeNationality']],
    //   separated: [['husbandOrWifeName', 'husbandOrWifeNationality']],
    // },
  },
  //valid2
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          lastName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]{2,}([ ]+[a-zA-Z]+)*$',
              errorMessage: 'Last Name is Invalid',
            },
          ],
          placeOfBirth: [
            {
              type: 'matches',
              pattern: '^[a-zA-Zs]+([ ]+[a-zA-Zs]*)*$',
              errorMessage: 'Place of Birth is Invalid',
            },
          ],
          tccNumber: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z0-9]+$',
              errorMessage: 'Tcc number is invalid',
            },
            {
              type: 'MAXLENGTH',
              maxlength: 20,
              errorMessage: 'maximum length should not exceed 20 characters',
            },
          ],
          jobTitle: [
            {
              type: 'MAXLENGTH',
              maxlength: 255,
              errorMessage: 'maximum length should not exceed 255 characters',
            },
          ],
          jobDuties: [
            {
              type: 'MAXLENGTH',
              maxlength: 255,
              errorMessage: 'maximum length should not exceed 255 characters',
            },
          ],
          workPermitOthers: [
            {
              type: 'MAXLENGTH',
              maxlength: 255,
              errorMessage: 'maximum length should not exceed 255 characters',
            },
          ],
          reasonNoneHired: [
            {
              type: 'MAXLENGTH',
              maxlength: 255,
              errorMessage: 'maximum length should not exceed 255 characters',
            },
          ],
          firstName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]{2,}([ ]+[a-zA-Z]+)*$',
              errorMessage: 'First Name is Invalid',
            },
          ],
          middleInitial: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]{2,}([ ]+[a-zA-Z]+)*$',
              errorMessage: 'Middle Name is Invalid',
            },
          ],
          alias: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]{2,}([ ]+[a-zA-Z]+)*$',
              errorMessage: 'Alias is Invalid',
            },
          ],

          jobId: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z0-9-]+$',
              errorMessage: 'Invalid Job Id',
            },
            {
              type: 'MAXLENGTH',
              maxlength: 20,
              errorMessage: 'maximum length should not exceed 20 characters',
            },
          ],
          dependentPassport: [
            {
              type: 'matches',
              pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
              errorMessage: 'Passport is not valid',
            },
          ],
          passport: [
            {
              type: 'matches',
              pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
              errorMessage: 'Passport is not valid',
            },
          ],
          trn: [
            {
              type: 'matches',
              pattern: '[0-9]{9}',
              errorMessage: 'TRN should contain 9 digit.',
            },
          ],
          businessAddress1: [
            {
              type: 'MAXLENGTH',
              maxlength: 255,
              errorMessage:
                'maximum length of Address should not exceed 255 characters',
            },
          ],
          businessAddress2: [
            {
              type: 'MAXLENGTH',
              maxlength: 255,
              errorMessage:
                'maximum length of Address should not exceed 255 characters',
            },
          ],
          natureOfOffence: [
            {
              type: 'MAXLENGTH',
              maxlength: 255,
              errorMessage: 'maximum length should not exceed 255 characters',
            },
          ],

          // businessTrn: [
          //   {
          //     type: 'matches',
          //     pattern: '^[a-zA-Z0-9]+$',
          //     errorMessage: 'TRN should contain alphabets and numbers.',
          //   },
          // ],
        },
      },
    ],
  },
  employerDocuments: {
    jobTitleProof: 'JOB_TITLE_PROOF',
    taxCompilance: 'TAX_COMPILANCE',
    byAdvertisementFile: 'BY_ADVERTISEMENT_FILE',
    stepTakenProof: 'STEP_TAKEN_PROOF',
    signatureProof: 'EMPLOYER_SIGNATURE',
    candidateResumes: 'CANDIDATE_RESUME',
    coveringLetterProof: 'COVERING_LETTER_PROOF',
    referenceLetter: 'REFERENCE_LETTER',
  },
  userDocuments: {
    birthCertificateProof: 'BIRTH_CERTIFICATE_PROOF',
    nationalityProof: 'NAIONALITY_PROOF',
    occupationProof: 'OCCUPATION_PROOF',
    passportProof: 'PASSPORT_PROOF',
    qualificationProof: 'QUALIFICATION_PROOF',
    workExperienceProof: 'WORK_EXPERIENCE_PROOF',
    photograph: 'PHOTOGRAPH',
    policeClearanceCertificate: 'POLICE_CLEARANCE_CERTIFICATE',
    resumeProof: 'RESUME_PROOF',
    previousEmployerProof: 'PREVIOUS_EMPLOYER_PROOF',
    signatureDep: 'APPLICANT_SIGNATURE',
    previousEmployerProofDep: 'PREVIOUS_EMPLOYER_PROOF_DEP',
    adoption: 'ADOPTION_CERTIFICATE_PROOF',
    divorce: 'DIVORCE_CERTIFICATE_PROOF',
    marriage: 'MARRIAGE_CERTIFICATE_PROOF',
    academic: 'ACADEMIC_CERTIFICATE_PROOF',
    nameChange: 'NAME_CHANGE_PROOF',
    certifyBirth: 'CERTIFIED_COPY_OF_BIRTH_CERTIFICATE',
    biodata: 'BIODATA_PAGE_COPY',
    deedPoll: 'DEED_POLL_COPY',
    csmeCertificate: 'CSME_CERTIFICATE',
    nolGender: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    nolSports: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    nolMusic: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    nolMedia: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    graduateQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
    nurseQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
    associateDegreeQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
    teacherQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
  },
  availableDocuments: {
    referenceLetter: 'REFERENCE_LETTER',
    csmeProof: 'CSME_COPY',
    jobTitleProof: 'JOB_TITLE_PROOF',
    taxCompilance: 'TAX_COMPILANCE',
    byAdvertisementFile: 'BY_ADVERTISEMENT_FILE',
    stepTakenProof: 'STEP_TAKEN_PROOF',
    signatureProof: 'EMPLOYER_SIGNATURE',
    birthCertificateProof: 'BIRTH_CERTIFICATE_PROOF',
    nationalityProof: 'NAIONALITY_PROOF',
    occupationProof: 'OCCUPATION_PROOF',
    passportProof: 'PASSPORT_PROOF',
    qualificationProof: 'QUALIFICATION_PROOF',
    workExperienceProof: 'WORK_EXPERIENCE_PROOF',
    photograph: 'PHOTOGRAPH',
    policeClearanceCertificate: 'POLICE_CLEARANCE_CERTIFICATE',
    resumeProof: 'RESUME_PROOF',
    coveringLetterProof: 'COVERING_LETTER_PROOF',
    previousEmployerProof: 'PREVIOUS_EMPLOYER_PROOF',
    signatureDep: 'APPLICANT_SIGNATURE',
    previousEmployerProofDep: 'PREVIOUS_EMPLOYER_PROOF_DEP',
    candidateResumes: 'CANDIDATE_RESUME',
    adoption: 'ADOPTION_CERTIFICATE_PROOF',
    divorce: 'DIVORCE_CERTIFICATE_PROOF',
    marriage: 'MARRIAGE_CERTIFICATE_PROOF',
    academic: 'ACADEMIC_CERTIFICATE_PROOF',
    nameChange: 'NAME_CHANGE_PROOF',
    certifyBirth: 'CERTIFIED_COPY_OF_BIRTH_CERTIFICATE',
    biodata: 'BIODATA_PAGE_COPY',
    deedPoll: 'DEED_POLL_COPY',
    csmeCertificate: 'CSME_CERTIFICATE',
    passportSizePhoto: 'PASSPORT_SIZE_PHOTO',
    nolGender: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    nolSports: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    nolMusic: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    nolMedia: 'EMPLOYMENT_NO_OBJECTION_CERTIFICATE',
    graduateQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
    nurseQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
    associateDegreeQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
    teacherQual: 'EMPLOYMENT_QUALIFICATION_CERTIFICATE',
    countryNaturalizationCertificate: 'NATUARALIZATION_CERTIFICATE',
  },
  userFormFields: [
    {
      type: 'text',
      compProps: {
        id: 'lastName',
        label: 'Last Name',
        placeholder: 'Enter last name(no special characters, no spaces)',

        errorText: 'Last name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'firstName',
        label: 'First Name',
        placeholder: 'Enter first name(no special characters, no spaces)',
        required: true,
        errorText: 'First name is required',
      },
    },
    {
      type: 'email',
      compProps: {
        id: 'userEmail',
        label: 'Email id',
        required: true,
        // isPersonalEmailValidation: true,
        placeholder: 'Enter email address',
        errorText: 'Email is required',
      },
    },
  ],
  userFormLayout: [['lastName', 'firstName'], ['userEmail']],
  userValidatorConfig: {
    ruleSets: [
      {
        fields: {
          lastName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]{2,}([ ]+[a-zA-Z]+)*$',
              errorMessage: 'Last Name is Invalid',
            },
          ],
          firstName: [
            {
              type: 'matches',
              pattern: '^[a-zA-Z]{2,}([ ]+[a-zA-Z]+)*$',
              errorMessage: 'First Name is Invalid',
            },
          ],
          userEmail: [
            {
              type: 'isEmail',
              errorMessage: 'Please enter a valid email address',
            },
          ],
          jobDutyType: [
            {
              type: 'MAXLENGTH',
              maxlength: 255,
              errorMessage:
                'maximum length of Job Duty/Type of Work should not exceed 255 characters',
            },
          ],
        },
      },
    ],
  },
};
