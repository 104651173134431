export const renewCsmeConfig = {
  nextStepLabel: 'Next',
  submitButtonText: 'Submit',
  previewLabel: '',
  userFormTitle: 'Enter Applicant Information',
  csmeLabel: 'CSME Application - New',
  csmeRenewalLabel: 'CSME Application - Renewal',
  csmeAppealLabel: 'CSME Application - Appeal',
  successIcon: 'https://i.ibb.co/RNvQN2X/success.png',
  errorIcon: 'https://i.ibb.co/GM7nsgp/icons8-error-48.png',
  // csmeSubLabel1: 'Enter Prospective Employee Details',
  // csmeSubLabel2: 'Enter Employer Details',
  acknowledgementText:
    'I certify to the best of my knowledge and belief, that the above information is correct and accept the responsibility for the support and repatriation expenses of the applicant and his family should the need arise.',
  progressBarSteps: [
    'CSME Application - Renew',
    'CSME Application - Renew',
    'CSME Application - Renew',
    'CSME Application - Renew',
    'CSME Application - Renew',
    'Acknowledgement',
  ],
  fields: [
    [
      {
        type: 'text',
        compProps: {
          label: 'Last Name',
          id: 'lastName',
          placeholder: 'Enter Last Name',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'First Name',
          id: 'firstName',
          placeholder: 'Enter First Name',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Middle Name',
          id: 'middleInitial',
          placeholder: 'Enter Middle Name',
        },
      },

      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label: 'Street Address 1',
          id: 'address1',
          placeholder: 'Enter Address 1',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Street Address 2',
          rows: 1,
          id: 'address2',
          placeholder: 'Enter Address 2',
          required: false,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Parish',
          id: 'parish',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'City/Town',
          id: 'city',
          placeholder: 'Enter City/Town',
          required: true,
          options: [],
        },
      },

      {
        type: 'text',
        compProps: {
          label: 'Postal Code',
          id: 'postalCode',
          placeholder: 'Enter Postal Code',
        },
      },

      // {
      //   type: 'text',
      //   compProps: {
      //     label: 'Postal Code',
      //     id: 'mailingPostalCode',
      //     placeholder: 'Enter Postal Code',
      //     required: true,
      //   },
      // },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone Contact',
          id: 'userTelephoneNumber',
          placeholder: 'Enter Telephone Number',
          required: true,
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'userEmail',
          placeholder: 'Enter Email address',
          required: true,
          // readOnly:true
        },
      },
      {
        type: 'radio',
        compProps: {
          id: 'gender',
          direction: 'horizontal',
          label: 'Sex',
          value: 'Male',
          options: [
            {
              id: 'Male',
              label: 'Male',
            },
            {
              id: 'Female',
              label: 'Female',
            },
          ],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          id: 'isNameChange',
          direction: 'horizontal',
          label: 'If there is a name change',
          value: 'yes',
          options: [
            {
              id: 'yes',
              label: 'Yes',
            },
            {
              id: 'no',
              label: 'No',
            },
          ],
          required: true,
        },
      },
      {
        type: 'datepicker',
        compProps: {
          label: 'Date of Birth',
          id: 'dateOfBirth',
          isAdult: true,
          required: true,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Nationality',
          id: 'nationality',
          placeholder: 'Enter Nationality',
          required: true,
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Country of Birth',
          id: 'placeOfBirth',
          placeholder: 'Enter Country of Birth',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Destination Caribbean State',
          id: 'destinationCarribbeanState',
          placeholder: 'Enter Destination Caribbean State',
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Marital Status',
          id: 'maritalStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Single',
              id: 'single',
            },
            {
              label: 'Divorced',
              id: 'divorced',
            },
            {
              label: 'Widowed',
              id: 'widowed',
            },
            {
              label: 'Married',
              id: 'married',
            },
            {
              label: 'Separated',
              id: 'separated',
            },
          ],
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Purpose of Relocation',
          id: 'purposeOfRelocation',
          direction: 'horizontal',
          options: [
            {
              label: 'Self-Employment',
              id: 'SELF_EMPLOYMENT',
            },
            {
              label: 'Employment',
              id: 'EMPLOYMENT',
            },
            {
              label: 'Study',
              id: 'STUDY',
            },
            {
              label: 'Family/Personal Relationship',
              id: 'PERSONAL_RELATIONSHIP',
            },
          ],
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Occupation',
          id: 'employmentOccupation',
          placeholder: 'Enter Occupation',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Nature of Business',
          id: 'userNatureOfBusiness',
          placeholder: 'Enter Nature of Business',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Nature of Professional Activity',
          id: 'natureOfProfessionalActivity',
          placeholder: 'Enter Nature of Professional Activity',
          required: true,
        },
      },
      {
        type: 'radio',
        compProps: {
          label: 'Do you have any dependents',
          id: 'isDependentExist',
          direction: 'horizontal',
          options: [
            {
              label: 'Yes',
              id: 'yes',
            },
            {
              label: 'No',
              id: 'no',
            },
          ],
          required: true,
        },
      },
      {
        type: 'number',
        compProps: {
          label: 'In case of Dependents, How many dependents?',
          id: 'noOfChildren',
          placeholder: 'Enter number of Dependents',
          required: true,
        },
      },
      {
        type: 'dynamicFields',
        compProps: {
          id: 'dependentInfo',
          cardType: 'Dependent',
          label: 'Dependents Info',
          lookUpField: 'noOfChildren',
          required: false,
          errorText: 'Please enter all Children / Dependent info',
          attributeForm: {
            modalTitle: 'Add Dependent Info',
            editTitle: 'Edit Dependent Info',
            fields: [
              {
                type: 'text',
                compProps: {
                  label: 'Full Name',
                  id: 'dependentName',
                  placeholder: 'Enter Full Name',
                  required: true,
                },
              },
              {
                type: 'number',
                compProps: {
                  id: 'dependentAge',
                  placeholder: 'Age',
                  label: 'Age',
                  required: true,
                },
              },
              {
                type: 'radio',
                compProps: {
                  id: 'dependentSex',
                  direction: 'horizontal',
                  label: 'Sex',
                  value: 'Male',
                  options: [
                    {
                      id: 'Male',
                      label: 'Male',
                    },
                    {
                      id: 'Female',
                      label: 'Female',
                    },
                  ],
                  required: true,
                },
              },
              {
                type: 'countrySelect',
                compProps: {
                  label: 'Nationality',
                  id: 'dependentNationality',
                  placeholder: 'Enter Nationality',
                  required: true,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Passport Number',
                  id: 'dependentPassport',
                  placeholder: 'Enter Passport Number',
                  required: true,
                },
              },
              {
                type: 'select',
                compProps: {
                  label: 'Relationship to Applicant',
                  id: 'dependentRelationship',
                  options: [
                    {
                      label: 'Minor Child',
                      id: 'MINOR_CHILD',
                    },
                    {
                      label: 'Spouse ',
                      id: 'SPOUSE',
                    },
                    {
                      label: 'Other',
                      id: 'OTHER',
                    },
                  ],
                  required: true,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Certified Copy of Birth Certificate',
                  id: 'certifyBirth',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  required: true,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Bio-Data Page of Valid Passport',
                  id: 'passportProof',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  required: true,
                },
              },
              {
                type: 'fileDropZone',
                compProps: {
                  label: 'Proof of Adoption (Where necessary)',
                  id: 'adoption',

                  maxFiles: 1,
                  supportedFormat: [
                    'image/png',
                    'application/pdf',

                    'image/jpeg',
                    'image/jpg',
                  ],
                  // required: true,
                },
              },
            ],
            layout: [
              ['dependentName'],
              ['dependentAge'],
              ['dependentSex'],
              ['dependentNationality'],
              ['dependentPassport'],
              ['dependentRelationship'],
            ],
            dynamicLayout: {
              dependentRelationship: {
                MINOR_CHILD: [
                  ['certifyBirth'],
                  ['passportProof'],
                  ['adoption'],
                ],
              },
            },
            validatorConfig: {
              ruleSets: [
                {
                  fields: {
                    dependentName: [
                      {
                        type: 'matches',
                        pattern: '^[A-Za-z ]+$',
                        errorMessage: 'Name is invalid.',
                      },
                    ],
                    dependentPassport: [
                      {
                        type: 'matches',
                        pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
                        errorMessage: 'Passport is not valid',
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Highest Education Qualification',
          id: 'highEducationQualification',
          placeholder: 'Enter Highest Education Qualification',
          required: true,
        },
      },
      {
        type: 'dynamicFields',
        compProps: {
          id: 'qualification',
          cardType: 'Qualification',
          label: 'Qualification',
          required: true,
          attributeForm: {
            modalTitle: 'Add Qualification',
            editTitle: 'Edit Qualification',
            fields: [
              {
                type: 'text',
                compProps: {
                  label: 'Qualification Name',
                  id: 'qualificationName',
                  placeholder: 'Enter Qualification Name',
                  required: true,
                },
              },
              {
                type: 'datepicker',
                compProps: {
                  label: 'Date Awarded',
                  id: 'dateOfQualification',
                  required: true,
                  isPast: true,
                },
              },
              {
                type: 'text',
                compProps: {
                  label: 'Name of Institution',
                  id: 'name',
                  placeholder: 'Enter Institution Name',
                  required: true,
                },
              },
              {
                type: 'textarea',

                compProps: {
                  rows: 1,
                  label: 'Institution Address Line 1',
                  id: 'address1',
                  placeholder: 'Enter Address Line 1',
                  required: true,
                },
              },
              {
                type: 'textarea',

                compProps: {
                  label: 'Institution Address Line 2',
                  rows: 1,
                  id: 'address2',
                  placeholder: 'Enter Address Line 2',
                  required: false,
                },
              },

              {
                type: 'select',
                compProps: {
                  label: 'Parish',
                  id: 'parish',
                  placeholder: 'Enter Parish',
                  required: true,
                  options: [
                    {
                      label: 'Clarendon',
                      id: 'Clarendon',
                    },
                    {
                      label: 'Hanover',
                      id: 'Hanover',
                    },
                    {
                      label: 'Kingston',
                      id: 'Kingston',
                    },
                    {
                      label: 'Manchester',
                      id: 'Manchester',
                    },
                    {
                      label: 'Portland',
                      id: 'Portland',
                    },
                    {
                      label: 'Saint Andrew',
                      id: 'Saint Andrew',
                    },
                    {
                      label: 'Saint Ann',
                      id: 'Saint Ann',
                    },
                    {
                      label: 'Saint Catherine',
                      id: 'Saint Catherine',
                    },
                    {
                      label: 'Saint Elizabeth',
                      id: 'Saint Elizabeth',
                    },
                    {
                      label: 'Saint James',
                      id: 'Saint James',
                    },
                    {
                      label: 'Saint Mary',
                      id: 'Saint Mary',
                    },
                    {
                      label: 'Saint Thomas',
                      id: 'Saint Thomas',
                    },
                    {
                      label: 'Trelawny',
                      id: 'Trelawny',
                    },
                    {
                      label: 'Westmoreland',
                      id: 'Westmoreland',
                    },
                  ],
                },
              },
              {
                type: 'select',
                compProps: {
                  label: 'City/Town',
                  id: 'city',
                  placeholder: 'Enter City/Town',
                  required: true,
                  options: [],
                },
              },
              {
                type: 'countrySelect',
                compProps: {
                  label: 'Country',
                  id: 'country',
                  placeholder: 'Enter Country',
                  required: true,
                },
              },
            ],
            layout: [
              ['qualificationName'],
              ['dateOfQualification'],
              ['name'],
              ['address1', 'address2'],
              ['parish', 'city'],
              ['country'],
            ],
            validatorConfig: {
              ruleSets: [
                {
                  fields: {
                    qualificationName: [
                      {
                        type: 'matches',
                        pattern: '^[A-Za-z ]+$',
                        errorMessage: 'Name is invalid.',
                      },
                    ],
                    dependentPassport: [
                      {
                        type: 'matches',
                        pattern: '^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{3,}$',
                        errorMessage: 'Passport is not valid',
                      },
                    ],
                  },
                },
              ],
            },
          },
        },
      },

      {
        type: 'radio',
        compProps: {
          label: 'Employment Status',
          id: 'employmentStatus',
          direction: 'horizontal',
          options: [
            {
              label: 'Self-Employed',
              id: 'SELF_EMPLOYMENT',
            },
            {
              label: 'Employed',
              id: 'EMPLOYMENT',
            },
            {
              label: 'Student',
              id: 'STUDENT',
            },
          ],
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Current Occupation',
          id: 'selfOccupation',
          placeholder: 'Enter Occupation',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label: 'Business Address 1',
          id: 'selfAddress1',
          placeholder: 'Enter Address 1',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Business Address 2',
          rows: 1,
          id: 'selfAddress2',
          placeholder: 'Enter Address 2',
          required: false,
        },
      },

      {
        type: 'select',
        compProps: {
          label: 'City/Town',
          id: 'selfCity',
          placeholder: 'Enter City/Town',
          required: true,
          options: [],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Parish',
          id: 'selfParish',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Country',
          id: 'selfCountry',
          placeholder: 'Enter Country',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Employer Name',
          id: 'employerName',
          placeholder: 'Enter Employer Name',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          rows: 1,
          label: 'Employer Address Line 1',
          id: 'employedAddress1',
          placeholder: 'Enter Address Line 1',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Employer Address Line 2',
          rows: 1,
          id: 'employedAddress2',
          placeholder: 'Enter Address Line 2',
          required: false,
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'City/Town',
          id: 'employedCity',
          placeholder: 'Enter City/Town',
          required: true,
          options: [],
        },
      },
      {
        type: 'select',
        compProps: {
          label: 'Parish',
          id: 'employedParish',
          placeholder: 'Enter Parish',
          required: true,
          options: [
            {
              label: 'Clarendon',
              id: 'Clarendon',
            },
            {
              label: 'Hanover',
              id: 'Hanover',
            },
            {
              label: 'Kingston',
              id: 'Kingston',
            },
            {
              label: 'Manchester',
              id: 'Manchester',
            },
            {
              label: 'Portland',
              id: 'Portland',
            },
            {
              label: 'Saint Andrew',
              id: 'Saint Andrew',
            },
            {
              label: 'Saint Ann',
              id: 'Saint Ann',
            },
            {
              label: 'Saint Catherine',
              id: 'Saint Catherine',
            },
            {
              label: 'Saint Elizabeth',
              id: 'Saint Elizabeth',
            },
            {
              label: 'Saint James',
              id: 'Saint James',
            },
            {
              label: 'Saint Mary',
              id: 'Saint Mary',
            },
            {
              label: 'Saint Thomas',
              id: 'Saint Thomas',
            },
            {
              label: 'Trelawny',
              id: 'Trelawny',
            },
            {
              label: 'Westmoreland',
              id: 'Westmoreland',
            },
          ],
        },
      },
      {
        type: 'countrySelect',
        compProps: {
          label: 'Country',
          id: 'employedCountry',
          placeholder: 'Enter Country',
          required: true,
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone',
          id: 'employedTelephone',
          placeholder: 'Enter Telephone Number',
          required: true,
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email Address',
          id: 'employedEmailAddress',
          placeholder: 'Enter Email address',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Describe Nature of Business',
          id: 'employedNatureOfBusiness',
          placeholder: 'Describe Nature of Business',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Type of Education Program',
          id: 'educationType',
          placeholder: 'Enter Type of Education Program',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Specify Degree or Certification to be awarded on completion',
          id: 'degree',
          placeholder: 'Specify degree of certification',
          required: true,
        },
      },
      {
        type: 'text',
        compProps: {
          label: 'Name of Institution',
          id: 'institutionName',
          placeholder: 'Enter Institution Name',
          required: true,
        },
      },
      {
        type: 'textarea',

        compProps: {
          label: 'Address of Institution',
          rows: 1,
          id: 'institutionAddress',
          placeholder: 'Enter Address',
          required: true,
        },
      },
      {
        type: 'phoneNumber',
        compProps: {
          label: 'Telephone',
          id: 'studentTelephone',
          placeholder: 'Enter Telephone Number',
          required: true,
        },
      },
      {
        type: 'email',
        compProps: {
          label: 'Email',
          id: 'studentEmailAddress',
          placeholder: 'Enter Email address',
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Police Report from Country',
          id: 'policeClearanceCertificate',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Birth Certificate Copy',
          id: 'birthCertificateProof',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Bio-Data Page Copy',
          id: 'biodata',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },

      {
        type: 'select',
        compProps: {
          label: 'Select document type to support name change',
          id: 'isFileUpload',
          required: true,
          options: [
            {
              label: 'A deed poll',
              id: '1',
            },
            {
              label: 'Other documents',
              id: '2',
            },
          ],
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'A deed poll',
          id: 'deadPoll',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Marriage Certificate',
          id: 'marriageForNameChange',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Decree Absolute (Divorce)',
          id: 'divorseForNameChange',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Other document to support name change',
          id: 'nameChange',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Certified Copy of Academic Certificate',
          id: 'academic',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Marriage Certificate',
          id: 'marriage',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
      {
        type: 'fileDropZone',
        compProps: {
          label: 'Decree Absolute (Divorce)',
          id: 'divorce',

          maxFiles: 1,
          supportedFormat: [
            'image/png',
            'application/pdf',

            'image/jpeg',
            'image/jpg',
          ],
          required: true,
        },
      },
    ],
  ],
  layout: [
    {
      first: {
        label: 'Applicant Information',
        data: [
          ['lastName', 'firstName', 'middleInitial'],
          ['address1', 'address2'],
          ['parish', 'city'],
          ['postalCode'],
          ['userTelephoneNumber'],
          ['userEmail'],
          ['gender', 'dateOfBirth'],
          ['nationality', 'placeOfBirth'],
          ['maritalStatus'],
          ['destinationCarribbeanState'],
          ['purposeOfRelocation'],
          ['isDependentExist'],
        ],
      },
      second: {
        label: 'Education & Employment',
        data: [
          ['highEducationQualification'],
          ['qualification'],
          ['employmentStatus'],
        ],
      },
      third: {
        label: 'Documents',
        data: [
          ['policeClearanceCertificate'],
          ['birthCertificateProof'],
          ['biodata'],
          ['isNameChange'],
          ['academic'],
        ],
      },
    },
  ],
  dynamicLayout: {
    purposeOfRelocation: {
      SELF_EMPLOYMENT: [['natureOfProfessionalActivity']],
      EMPLOYMENT: [['employmentOccupation'], ['userNatureOfBusiness']],
    },
    isDependentExist: {
      yes: [['noOfChildren'], ['dependentInfo']],
    },
    employmentStatus: {
      STUDENT: [
        ['educationType'],
        ['degree'],
        ['institutionName'],
        ['institutionAddress'],
        ['studentTelephone'],
        ['studentEmailAddress'],
      ],
      EMPLOYMENT: [
        ['employerName'],
        ['employedAddress1'],
        ['employedAddress2'],
        ['employedParish'],
        ['employedCity'],
        ['employedCountry'],
        ['employedTelephone'],
        ['employedEmailAddress'],
        ['employedNatureOfBusiness'],
      ],
      SELF_EMPLOYMENT: [
        ['selfOccupation'],
        ['selfAddress1'],
        ['selfAddress2'],
        ['selfParish'],
        ['selfCity'],
        ['selfCountry'],
      ],
    },
    isNameChange: {
      yes: [['isFileUpload']],
    },
    isFileUpload: {
      1: [['deadPoll']],
      2: [['nameChange']],
    },
  },
  // validatorConfig: {
  //   // ruleSets: [
  //   //   {
  //   //     fields: {
  //   //       dependentName: [
  //   //         {
  //   //           type: 'matches',
  //   //           pattern: '^[A-Za-z ]+$',
  //   //           errorMessage: 'Name is invalid.',
  //   //         },
  //   //       ],
  //   //     },
  //   //   },
  //   // ],
  // },
  //   employerDocuments: {
  //     jobTitleProof: 'JOB_TITLE_PROOF',
  //     taxCompilance: 'TAX_COMPILANCE',
  //     byAdvertisementFile: 'BY_ADVERTISEMENT_FILE',
  //     stepTakenProof: 'STEP_TAKEN_PROOF',
  //     signatureProof: 'EMPLOYER_SIGNATURE',
  //   },
  //   userDocuments: {
  //     birthCertificateProof: 'BIRTH_CERTIFICATE_PROOF',
  //     nationalityProof: 'NAIONALITY_PROOF',
  //     occupationProof: 'OCCUPATION_PROOF',
  //     passportProof: 'PASSPORT_PROOF',
  //     qualificationProof: 'QUALIFICATION_PROOF',
  //     workExperienceProof: 'WORK_EXPERIENCE_PROOF',
  //     photograph: 'PHOTOGRAPH',
  //     policeClearanceCertificate: 'POLICE_CLEARANCE_CERTIFICATE',
  //     resumeProof: 'RESUME_PROOF',
  //     coveringLetterProof: 'COVERING_LETTER_PROOF',
  //     previousEmployerProof: 'PREVIOUS_EMPLOYER_PROOF',
  //     signatureDep: 'APPLICANT_SIGNATURE',
  //     previousEmployerProofDep: 'PREVIOUS_EMPLOYER_PROOF_DEP',
  //   },
  //   availableDocuments: {
  //     jobTitleProof: 'JOB_TITLE_PROOF',
  //     taxCompilance: 'TAX_COMPILANCE',
  //     byAdvertisementFile: 'BY_ADVERTISEMENT_FILE',
  //     stepTakenProof: 'STEP_TAKEN_PROOF',
  //     signatureProof: 'EMPLOYER_SIGNATURE',
  //     birthCertificateProof: 'BIRTH_CERTIFICATE_PROOF',
  //     nationalityProof: 'NAIONALITY_PROOF',
  //     occupationProof: 'OCCUPATION_PROOF',
  //     passportProof: 'PASSPORT_PROOF',
  //     qualificationProof: 'QUALIFICATION_PROOF',
  //     workExperienceProof: 'WORK_EXPERIENCE_PROOF',
  //     photograph: 'PHOTOGRAPH',
  //     policeClearanceCertificate: 'POLICE_CLEARANCE_CERTIFICATE',
  //     resumeProof: 'RESUME_PROOF',
  //     coveringLetterProof: 'COVERING_LETTER_PROOF',
  //     previousEmployerProof: 'PREVIOUS_EMPLOYER_PROOF',
  //     signatureDep: 'APPLICANT_SIGNATURE',
  //     previousEmployerProofDep: 'PREVIOUS_EMPLOYER_PROOF_DEP',
  //     candidateResumes: 'CANDIDATE_RESUME',
  //   },
  //   userFormFields: [
  //     {
  //       type: 'text',
  //       compProps: {
  //         id: 'userFirstName',
  //         label: 'First Name',
  //         placeholder: 'Enter first name',
  //         required: true,
  //         errorText: 'First name is required',
  //       },
  //     },
  //     {
  //       type: 'text',
  //       compProps: {
  //         id: 'userLastName',
  //         label: 'LastName',
  //         placeholder: 'Enter last name',
  //         required: true,
  //         errorText: 'Last name is required',
  //       },
  //     },
  //     {
  //       type: 'email',
  //       compProps: {
  //         id: 'userEmail',
  //         label: 'Email',
  //         required: true,

  //         placeholder: 'Enter email address',
  //         errorText: 'Email is required',
  //       },
  //     },
  //   ],
  //   userFormLayout: [['userFirstName', 'userLastName'], ['userEmail']],
};
