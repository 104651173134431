export const loadingConfig = {
  loadingUsers: {
    image: '',
    titleNormalText: 'Retrieving ',
    titleBoldText: 'Organization list...',
    description: 'This might take few seconds, Please wait !',
  },
  inviteUser: {
    image: '',
    titleNormalText: 'Inviting Organization',
    description: 'This might take few seconds, Please wait !',
  },
  usersEmpty: {
    image:
      'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/resting_black.png',
    titleNormalText: 'Admin Users',
    titleBoldText: 'Not found !',
    description: 'Inviting new users feature to manage this portal',
  },
  suspendUser: {
    image: '',
    titleNormalText: 'Updating admin user status',
    description: 'This might take few seconds, Please wait !',
  },
  deleteUser: {
    image: '',
    titleNormalText: 'Deleting admin user',
    description: 'This might take few seconds, Please wait !',
  },
};
