import { logger as log } from '@common/logger';
import { PaymentPageService } from '../../../services';
import { vappContainer } from '@/application';

const MODULE_NAME = 'payment.machines.states.getFee';

const getSrv = () => {
  return vappContainer.get<PaymentPageService>(PaymentPageService.displayName);
};

export const getFee = async (context: any, event: any) => {
  const currentState = 'GET_FEE';
  const method = 'getFee';

  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      const srv = getSrv();
      const content = await srv.agent.getFee(event?.payload);
      if (content) {
        return resolve({
          key: 'GET_FEE_SUCCESS',
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = 'Page content not found';
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'GET_FEE_FAILED',
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      let _msg = err?.message?.replace('GraphQL error:', '');
      return reject({
        key: 'GET_FEE_FAILED',
        value: _msg,
      });
    }
  });
};
