import React from 'react';
import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  FormCheck,
  OverlayTrigger,
  Tooltip,
  Image,
} from 'react-bootstrap';
import {
  CheckCircleIcon,
  DotsHorizontalIcon,
  EyeIcon,
  InformationCircleIcon,
  ShieldExclamationIcon,
  UserRemoveIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import { StorageModule } from '@vlinder-web/storage-module-react';
import { USER_LEVEL } from '@/config/userManagement.config';
import * as S from './styles';

export interface ToggleActionProps {
  status: string;
  onTogglePress?(key: string): void;
  whoAmI?: any;
}

export const ToggleAction = (props: ToggleActionProps) => {
  const { status, onTogglePress, whoAmI } = props;
  const storageSrv = StorageModule?.getInstance();
  const userProfile = storageSrv.get('myProfile', 'session');
  const userRole = userProfile?.role?.find((item: any) =>
    USER_LEVEL.owner.includes(item?.name)
  );

  console.log('last1111', whoAmI);
  console.log(`onToggleClick ::`);
  const onToggleClick = (key: string) => {
    console.log(`onToggleClick`, key);
    onTogglePress && onTogglePress(key);
  };

  console.log('status22', status);
  const _getToggleItems = () => {
    if (
      status !== 'Applicant Application Incomplete' ||
      whoAmI === 'prospective_employee'
    ) {
      return (
        <>
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => onToggleClick('view')}
          >
            <EyeIcon className="dropdown-icon text-gray-400 me-2" />
            View
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex align-items-center"
            onClick={() => onToggleClick('delete')}
          >
            <ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
            Delete
          </Dropdown.Item>
        </>
      );
    } else {
      return (
        <Dropdown.Item
          className="d-flex align-items-center"
          onClick={() => onToggleClick('delete')}
        >
          <ShieldExclamationIcon className="dropdown-icon text-gray-400 me-2" />
          Delete
        </Dropdown.Item>
      );
    }
  };

  return (
    <Dropdown as={ButtonGroup}>
      <Dropdown.Toggle
        as={Button}
        split
        variant="link"
        className="text-dark m-0 p-0"
      >
        <DotsHorizontalIcon className="icon icon-xs" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="dashboard-dropdown dropdown-menu-start mt-2 py-1">
        {_getToggleItems()}
      </Dropdown.Menu>
    </Dropdown>
  );

  // return (
  //   <>
  //     {status !== 'Applicant Application Incomplete' ||
  //     whoAmI === 'prospective_employee' ? (
  //       <S.ButtonComp
  //         className={` btn rounded-pill btn-sm`}
  //         color={'#0000FF'}
  //         onClick={() => onToggleClick('view')}
  //       >
  //         View
  //       </S.ButtonComp>
  //     ) : (
  //       '-'
  //     )}
  //   </>
  // );
};
