export const loading = {
  loadingProfile: {
    // image:
    //   'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/fhp_loading_illustration_2.png',
    titleNormalText: 'Retrieving user',
    titleBoldText: 'profile...',
    description: 'This might take few seconds, Please wait !',
  },
  inviteUser: {
    titleNormalText: 'Inviting user',
    description: 'This might take few seconds, Please wait !',
  },
  updateProfile: {
    // image:
    //   'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/fhp_loading_illustration_2.png',
    titleNormalText: 'Updating user',
    titleBoldText: ' profile...',
    description: 'This might take few seconds, Please wait !',
  },
  suspendUser: {
    image: '',
    titleNormalText: 'Updating user status',
    description: 'This might take few seconds, Please wait !',
  },
  revokeAdmin: {
    image: '',
    titleNormalText: 'Revoking admin status',
    description: 'This might take few seconds, Please wait !',
  },
  removeAccess: {
    image: '',
    titleNormalText: 'Removing User',
    description: 'This might take few seconds, Please wait !',
  },
  usersEmpty: {
    // image:
    //   'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/resting_black.png',
    titleNormalText: 'Profile data',
    titleBoldText: 'Not found !',
    description: 'Kindly create new profile by clicking Create Profile button.',
  },
};
