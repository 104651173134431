import React, { useEffect } from 'react';
import { LoggerModule } from '@vlinder-web/logger-module-react';

import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';

import { actions } from './actions';
import { MACHINE_CONFIG } from './config';
import { services } from './services';

export let renewWorkPermitPageMachine: any;

const MODULE_NAME = 'machines.pageContent';
const log: any = LoggerModule?.getInstance();

export const useSMRenewWorkPermitPage = (_context: any) => {
  let _machineConfig: any = MACHINE_CONFIG;
  if (_machineConfig && _machineConfig?.context) {
    _machineConfig.context = {
      ..._machineConfig.context,
      ...(_context || {}),
    };
  }
  renewWorkPermitPageMachine = createMachine(_machineConfig, {
    services,
    actions,
  });
  const [state, send] = useMachine(renewWorkPermitPageMachine);

  return {
    renewWorkPermitPageMachine,
    renewWorkPermitPageState: state,
    renewWorkPermitPageSend: send,
  };
};
