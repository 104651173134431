export const loadingConfig = {
  loadingUsers: {
    titleNormalText: 'Retrieving ',
    titleBoldText: 'Users list...',
    description: 'This might take few seconds, Please wait !',
  },
  inviteUser: {
    titleNormalText: 'ReInviting User',
    description: 'This might take few seconds, Please wait !',
  },
  usersEmpty: {
    image: '',
    titleNormalText: 'Users',
    titleBoldText: 'Not found !',
    description: '',
  },
  suspendUser: {
    image: '',
    titleNormalText: 'Updating user status',
    description: 'This might take few seconds, Please wait !',
  },
  deleteUser: {
    image: '',
    titleNormalText: 'Deleting user',
    description: 'This might take few seconds, Please wait !',
  },
  notAuthorized: {
    image: '',
    titleNormalText:
      'Please update the Organization/Employer Details under Organization Page',
    titleBoldText: '',
    description: 'Your Profile is not yet approved',
  },
};
