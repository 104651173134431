export const formData = {
  fields: [
    {
      type: 'text',
      compProps: {
        label: 'Business Name',
        id: 'businessName',
        placeHolder: 'Enter Business Name',
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Company Registration No',
        id: 'companyRegNo',
        placeHolder: 'Enter Company Registration No',
        required: true,
      },
    },
    {
      type: 'datepicker',
      compProps: {
        label: 'Incorporation Date',
        id: 'incorporationDate',
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Taxpayer Registration No',
        id: 'taxpayerRegNo',
        placeHolder: 'Enter Taxpayer Registration No',
        required: true,
      },
    },
    {
      type: 'select',
      compProps: {
        label: 'CompanyType',
        id: 'companyType',
        required: true,
        options: [
          {
            label: 'Public',
            id: 'public',
          },
          {
            label: 'Private',
            id: 'private',
          },
          {
            label: 'Government Entity',
            id: 'government Entity',
          },
        ],
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Nature of Business',
        id: 'natureOfBusiness',
        placeHolder: 'Enter Nature of Business',
        required: true,
      },
    },
    {
      type: 'textarea',

      compProps: {
        label: 'Registered Address (Street 1)',
        rows: 1,
        id: 'address1',
        placeHolder: 'Enter Registered Address (Street 1)',
        required: true,
      },
    },
    {
      type: 'textarea',

      compProps: {
        label: 'Registered Address (Street 2)',
        rows: 1,
        id: 'address2',
        placeHolder: 'Enter Registered Address (Street 2)',
        required: true,
      },
    },

    {
      type: 'text',
      compProps: {
        label: 'City/Town',
        id: 'city',
        placeHolder: 'Enter City/Town',
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Parish',
        id: 'parish',
        placeHolder: 'Enter Parish',
        required: true,
      },
    },
    {
      type: 'numbertext',
      compProps: {
        label: 'Postal Code',
        id: 'postalCode',
        placeHolder: 'Enter Postal Code',
        required: true,
      },
    },
    {
      type: 'radio',
      compProps: {
        label: 'Company Status',
        id: 'companyStatus',
        direction: 'horizontal',
        required: true,
        options: [
          {
            label: 'Active',
            id: 'active',
          },
          {
            label: 'Closed',
            id: 'closed',
          },
        ],
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Website',
        id: 'website',
        placeHolder: 'Enter Website',
        required: true,
      },
    },
    {
      type: 'sectionTitle',
      compProps: {
        id: 'userDetails',
        label: 'User Details',
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'First Name',
        id: 'firstName',
        placeHolder: 'Enter First Name',
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Middle Name',
        id: 'middleName',
        placeHolder: 'Enter Middle Name',
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Last Name',
        id: 'lastName',
        placeHolder: 'Enter Last Name',
        required: true,
      },
    },

    {
      type: 'email',
      compProps: {
        label: 'Email Address',
        id: 'email',
        placeHolder: 'Enter Email address',
        required: true,
      },
    },
    {
      type: 'phoneNumber',
      compProps: {
        label: 'Telephone Number',
        id: 'telephoneNumber',
        placeHolder: 'Enter Telephone Number',
        required: true,
      },
    },
    {
      type: 'text',
      compProps: {
        label: 'Position',
        id: 'position',
        placeHolder: 'Enter Position',
        required: true,
      },
    },
    // {
    //   type: 'radio',
    //   compProps: {
    //     label: 'Status',
    //     id: 'status',    required:true,
    //     direction: 'horizontal',
    //     options: [
    //       {
    //         label: 'Active',
    //         id: 'active',
    //       },
    //       {
    //         label: 'InActive',
    //         id: 'inActive',
    //       },
    //     ],
    //   },
    // },
    {
      type: 'sectionTitle',
      compProps: {
        id: 'companyDirectors',
        label: 'Company Directors',
      },
    },

    {
      type: 'sectionTitle',
      compProps: {
        id: 'requiredDocuments',
        label: 'Required Documents',
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Tax Clearance Certificate (TCC)',
        id: 'taxClearance',

        maxFiles: 1,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Articles of Incorporation',
        id: 'acticlesOfIncorporation',

        maxFiles: 1,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Annual Return',
        id: 'annualReturn',

        maxFiles: 1,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Certificate of Incorporation',
        id: 'certificateOfIncorporation',

        maxFiles: 1,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Certificate of Good Standing',
        id: 'certificateOfGoodStanding',

        maxFiles: 1,
      },
    },
    {
      type: 'dynamicFields',
      compProps: {
        id: 'directors',
        label: 'Directors',
        required: true,
        attributeForm: {
          modalTitle: 'Add a Director',
          editTitle: 'Edit a Director',

          fields: [
            {
              type: 'text',
              compProps: {
                label: 'First Name',
                id: 'directorFirstName',
                placeHolder: 'Enter First Name',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Middle Name',
                id: 'directorMiddleName',
                placeHolder: 'Enter Middle Name',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Last Name',
                id: 'directorLastName',
                placeHolder: 'Enter Last Name',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Nationality',
                id: 'directorNationality',
                placeHolder: 'Enter Nationality',
                required: true,
              },
            },
            {
              type: 'text',
              compProps: {
                label: 'Occupation',
                id: 'directorOccupation',
                placeHolder: 'Enter Occupation',
                required: true,
              },
            },
          ],
          layout: [
            ['directorFirstName'],
            ['directorMiddleName'],
            ['directorLastName'],
            ['directorNationality'],

            ['directorOccupation'],
          ],
        },
      },
    },
  ],
  layout: [
    ['businessName'],
    ['companyRegNo', 'incorporationDate'],
    ['taxpayerRegNo', 'companyType'],
    ['natureOfBusiness'],
    ['address1'],
    ['address2'],
    ['city', 'parish'],
    ['postalCode'],
    ['website'],
    // ['userDetails'],
    // ['firstName', 'middleName'],
    // ['lastName', 'email'],
    // ['telephoneNumber', 'position'],
    // ['status'],
    ['companyDirectors'],
    ['directors'],
    // ['requiredDocuments'],
    // ['taxClearance'],
    // ['acticlesOfIncorporation'],
    // ['annualReturn'],
    // ['certificateOfIncorporation'],
    // ['certificateOfGoodStanding'],
  ],
  fileFields: [
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Tax Clearance Certificate (TCC)',
        id: 'taxClearance',
        required: true,

        maxFiles: 1,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Articles of Incorporation',
        id: 'acticlesOfIncorporation',
        required: true,

        maxFiles: 1,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Annual Return',
        id: 'annualReturn',
        required: true,

        maxFiles: 1,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Certificate of Incorporation',
        id: 'certificateOfIncorporation',
        required: true,

        maxFiles: 1,
      },
    },
    {
      type: 'fileDropZone',
      compProps: {
        label: 'Certificate of Good Standing',
        id: 'certificateOfGoodStanding',
        required: true,

        maxFiles: 1,
      },
    },
  ],
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          businessName: [
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage: 'Name is invalid.',
            },
          ],
          companyRegNo: [
            {
              type: 'matches',
              pattern: '^[A-Za-z0-9 ]+$',
              errorMessage:
                'Company Registration No should contain alphanumerical values.',
            },
          ],
          taxpayerRegNo: [
            {
              type: 'matches',
              pattern: '^[A-Za-z0-9 ]+$',
              errorMessage:
                'Taxpayer Registration No should contain alphanumerical values.',
            },
          ],
          natureOfBusiness: [
            {
              type: 'matches',
              pattern: '^[A-Za-z ]+$',
              errorMessage: 'Nature of Business should contain alphabets.',
            },
          ],
        },
      },
    ],
  },
};
