export const signUpUserFormConfig = {
  title: 'Tell us about you',
  otpScreenTitle: 'Enter the One Time Password sent to your email address',
  logo: 'https://i.ibb.co/CbVc1f2/Ministry-New-Logo-cropped-PNG-1.png',
  selectFields: [
    {
      type: 'select',
      compProps: {
        label: 'Account Type',
        id: 'type',
        required: true,
        options: [
          {
            label: 'Applicant',
            id: 'prospective_employee',
          },
          {
            label: 'Employer',
            id: 'owner',
          },
        ],
      },
    },
  ],
  fields: [
    {
      type: 'password',
      compProps: {
        id: 'password',
        label: 'Password',
        required: true,
        placeholder: 'Enter your password',
        errorText: 'Password is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'firstName',
        label: 'First Name',
        required: true,
        placeholder: 'Enter your name',
        errorText: 'Name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'lastName',
        label: 'Last Name',
        required: true,
        placeholder: 'Enter your last name',
        errorText: 'Last Name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'orgName',
        label: 'Business Name',
        required: true,
        placeholder: 'Enter your Business name',
        errorText: 'Business Name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'trn',
        label: 'TRN',
        required: true,
        placeholder: 'Enter your TRN , should not contain any special characters ex: 123456789',
        errorText: 'TRN is required',
      },
    },
    {
      type: 'phoneNumber',
      compProps: {
        id: 'phoneNumber',
        label: 'Mobile Number',
        required: true,
        placeholder:
          'Select your country code first and then Enter mobile Number',
        errorText: 'Mobile number is required',
      },
    },
    {
      type: 'email',
      compProps: {
        id: 'email',
        label: 'Email',
        required: true,
        placeholder: 'Enter your email address',
        errorText: 'Email is required',
      },
    },
  ],
  layout: [
    ['email'],
    ['trn'],
    ['password'],
    ['lastName', 'firstName'],
    ['phoneNumber'],
  ],
  validatorConfig: {
    ruleSets: [
      {
        fields: {
          trn: [
            {
              type: 'matches',
              pattern: '^(?=.*d)[0-9a-zA-Z]+$',
              errorMessage: 'TRN should contain alphabets and numbers.',
            },
          ],
        },
      },
    ],
  },
};
