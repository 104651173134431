export const loadingConfig = {
  getApplication: {
    titleNormalText: 'Retrieving your',
    titleBoldText: 'application...',
    description: 'This might take few seconds, Please wait !',
  },
  getWorkPermit: {
    titleNormalText: 'Retrieving your',
    titleBoldText: 'work permit...',
    description: 'This might take few seconds, Please wait !',
  },
  getCertificate: {
    titleNormalText: 'Retrieving your',
    titleBoldText: 'certificate...',
    description: 'This might take few seconds, Please wait !',
  },
  terminatingWorkPermit: {
    titleNormalText: 'Terminating ',
    titleBoldText: 'work permit...',
    description: 'This might take few seconds, Please wait !',
  },
  appealApplication: {
    titleNormalText: 'Processing your',
    titleBoldText: 'application...',
    description: 'This might take few seconds, Please wait !',
  },
  rejectedDocApplication: {
    titleNormalText: 'Submitting your',
    titleBoldText: 'application...',
    description: 'This might take few seconds, Please wait !',
  },
  renewApplication: {
    titleNormalText: 'Processing your',
    titleBoldText: 'application...',
    description: 'This might take few seconds, Please wait !',
  },
  loadingUsers: {
    titleNormalText: 'Retrieving your',
    titleBoldText: 'applications...',
    description: 'This might take few seconds, Please wait !',
  },
  inviteUser: {
    image:
      'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/fhp_loading_illustration_2.png',
    titleNormalText: 'Inviting user',
    description: 'This might take few seconds, Please wait !',
  },
  usersEmpty: {
    titleNormalText: 'Submitted Applications',
    titleBoldText: 'Not Found !',
    description: 'Submit new apllicaitons to manage this portal',
  },
  suspendUser: {
    image:
      'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/fhp_loading_illustration_2.png',
    titleNormalText: 'Updating admin user status',
    description: 'This might take few seconds, Please wait !',
  },
  deleteUser: {
    image:
      'https://s3.ap-south-1.amazonaws.com/io.vlinder.illustrations/fhp_loading_illustration_2.png',
    titleNormalText: 'Deleting admin user',
    description: 'This might take few seconds, Please wait !',
  },
};
