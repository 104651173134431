export const loadingConfig = {
  GetPageLoading: {
    image: '',
    titleNormalText: 'Loading',
    titleBoldText: '...',
    description: 'This might take few seconds, Please wait !',
  },
  forgotPassword: {
    image: '',
    titleNormalText: 'Sending an email',
    titleBoldText: 'to your account...',
    description: 'This might take few seconds, Please wait !',
  },
};
