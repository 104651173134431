export const loadingConfig = {
  userRoles: {
    titleNormalText: 'Fetching User Roles',
    description: 'This might take few seconds, Please wait !',
  },
  getAccessToken: {
    titleNormalText: 'Request in Progress!',
    description: 'This might take few seconds, Please wait !',
  },
};
