import { VApplication, vappContainer } from '../../../../../application';
import { StorageModule } from '@vlinder-web/storage-module-react';
import { logger as log } from '@common/logger';
import { SignInPageService } from '@/pages/SignIn/services';
const MODULE_NAME = 'switchRoles.machines.states.signInUser';
const getSrv = () => {
  return vappContainer.get<SignInPageService>(SignInPageService.displayName);
};
export const getAccessToken = async (context: any, event: any) => {
  const currentState = 'GET_ACCESS_TOKEN';
  const method = 'getAccessToken';
  const storageSrv = StorageModule.getInstance();
  console.log('onrole->');

  return new Promise(async (resolve, reject) => {
    try {
      log.log(MODULE_NAME, 'context: ', context, 'event: ', event);
      console.log('onrole->');

      const srv = getSrv();
      const content = await srv.agent.getAccessToken(event?.payload);
      log.log(MODULE_NAME, '"GET_ACCESS_TOKEN":', content);
      if (content && content?.accessToken) {
        const _localData = storageSrv.get('userProfile', 'local');
        const _sessionData = storageSrv.get('userProfile', 'session');
        const userData = _sessionData ?? _localData;
        console.log('content->', content);
        storageSrv.set(
          'userProfile',
          {
            ...userData,
            accessToken: content?.accessToken,
            currentRole: event?.payload?.name,
          },
          'session'
        );
        return resolve({
          key: 'SIGN_IN_USER_SUCCESS',
          value: {
            ...(content || {}),
            currentRole: event?.payload?.name,
          },
        });
      } else {
        const msg = content?.error;
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: 'SIGN_IN_USER_FAILED',
          value: content,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      return reject({
        key: 'SIGN_IN_USER_FAILED',
        value: 'Something went wrong please try again!',
      });
    }
  });
};
