export const loadingConfig = {
  loadingUsers: {
    titleNormalText: 'Retrieving your',
    titleBoldText: 'Draft applications list...',
    description: 'This might take few seconds, Please wait !',
  },
  usersEmpty: {
    titleNormalText: 'Draft Applications',
    titleBoldText: 'Not found !',
    description: 'Kindly create an application',
  },
  deleteDraft: {
    titleNormalText: 'Deleting Draft',
    description: 'This might take few seconds, Please wait !',
  },
  tccExpired : {
    image: '',
    titleNormalText: 'Tax Clearance Certificate Expired',
    titleBoldText: '',
    description: 'Please update your Tax Clearance Certificate in the organization profile or contact the administrator.',
  },
};
