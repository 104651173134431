export const settingsConfig = {
  searchStatusFilter: [
    {
      label: 'Select Status',
      value: '',
    },
    {
      label: 'Active',
      value: 'ACTIVE',
    },
    {
      label: 'Applied for Renewal',
      value: 'APPLIED_FOR_RENEWAL',
    },
    {
      label: 'Expiring Soon',
      value: 'EXPIRING_SOON',
    },
    {
      label: 'Expired',
      value: 'EXPIRED',
    },
    {
      label: 'Terminated',
      value: 'TERMINATED',
    },
  ],
  tableHeaders: [
    {
      key: 'Email',
      filterTypes: [
        {
          label: 'Contains',
          type: 'text',
          id: 'email',
        },
        {
          label: 'Equal',
          type: 'text',
          id: 'email',
        },
      ],
    },
    {
      key: 'First Name',
      filterTypes: [
        {
          label: 'ContainsCI',
          type: 'text',
          id: 'firstName',
        },
      ],
    },
    {
      key: 'Last Name',
      filterTypes: [
        {
          label: 'ContainsCI',
          type: 'text',
          id: 'lastName',
        },
      ],
    },
  ],
};
