import { Card, Dropdown, Nav } from 'react-bootstrap';
import { ISettingsCompProps, SettingsComp } from '../Settings';
import { InformationCircleIcon } from '@heroicons/react/solid';
import { SettingsAdminComp } from '@/pages/Admin/components/Settings';

interface IItem {
  key: string;
  value: string;
}
interface IDetailedInfoCardProps {
  items?: IItem[];
  isAdmin?: boolean;
}

export const DetailedInfoCard = (
  props: IDetailedInfoCardProps & ISettingsCompProps
) => {
  const { items, isAdmin } = props;
  return (
    <>
      <div className="d-flex i-btn">
        {isAdmin ? (
          <SettingsAdminComp {...props} />
        ) : (
          <SettingsComp {...props} />
        )}
        <Dropdown as={Nav.Item} className="ms-lg-3">
          <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
            <div className="media d-flex align-items-center">
              <InformationCircleIcon
                style={{ width: '30px', color: '#104382' }}
              />
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              key={`dditem-1`}
              className="d-flex align-items-center"
            >
              <div className="px-5 py-2 my-3 mx-auto">
                {items?.map((item) => {
                  return (
                    <div className="d-flex my-2">
                      <h6 style={{ color: '#104382' }}>
                        <span style={{ color: 'red' }}>*</span>
                        {item.key}:{' '}
                      </h6>
                      <h6 className="ms-2">{item.value}</h6>
                    </div>
                  );
                })}
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

DetailedInfoCard.defaultProps = {
  items: [
    {
      key: 'Invited',
      value:
        "A user who has been asked to join but hasn't completed the registration yet.",
    },
    {
      key: 'Active',
      value: 'Currently in use and working.',
    },
    {
      key: 'Inactive',
      value: 'Not in use or operational at the moment.',
    },
    {
      key: 'Suspended',
      value: 'Temporarily disabled or restricted for a certain period.',
    },
  ],
};
