//@ts-nocheck

import styled from 'styled-components';

export const RoundDot = styled.div`
  width: 5px;
  margin-left: 25px;
  height: 5px;
  cursor: pointer;
  border-radius: 50%;
  padding: 5px;
  background-color: gray;
  transition: 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
    background-color: black;
  }
`;

export const MinusContainer = styled.div`
  background-color: whitesmoke;
  cursor: pointer;
  margin-top: -3px;
  margin-right: -15px;
`;
export const PlusContainer = styled.div`
  background-color: whitesmoke;
  cursor: pointer;
  margin-top: -3px;
  margin-left: 10px;
`;

export const CurrentItem = styled.div`
  height: 15px;
  z-index: 5;
  position: absolute;
  transition: 1s ease-in-out;
  translate: ${(props) => (props?.fontScale + 4) * 35}px 0px;
  background-color: #104382;
  width: 10px;
  border-radius: 5px;
`;

export const FontSliderContainer = styled.div`
  width: fit-content;
  height: 1px;
  background-color: gray;
  transition: 0.3s ease-in-out;
  display: flex;
  margin-left: 0.5rem;
  align-items: center;
`;

export const Container = styled.div`
  margin: 2rem 2rem 2rem auto;
  width: fit-content;
`;

export const IconStyle = {
  color: 'blue',
  cursor: 'pointer',
};

export const InfoStyle = { backgroundColor: 'white', padding: '0.5rem' };
