export const APPLICATION_STATUS: any = {
  APPLICATION_INCOMPLETE: 'Employer Application Incomplete',
  APPLICANT_DRAFT: 'Applicant Application Incomplete',
  READY_TO_PAY: 'Ready to Pay',
  PROCESSING_FEES_RECEIVED: 'Processing Fees Received',
  ACTIVE: 'Active',
  SUBMITTED: 'Submitted',
  SUCCESS: 'Success',
  FAILED: 'Failed',
  PENDING: 'Pending',
  PROCESSED: 'Processed',
  RESUBMITTED: 'Resubmitted',
  PROCESSING_OFFICER_REVIEW: 'Processing Officer Review',
  DOCUMENTATION_REJECTED: 'Documentation Rejected',
  PROCESSING_OFFICER_SUPERVISOR_REVIEW: 'Processing officer supervisor review',
  MINISTRY_REVIEW: 'Ministry Review',
  MISSING_INFORMATION: 'Missing Information',
  DIRECTOR_REVIEW: 'Director Review',
  DIRECTOR_REVIEW_INVESTIGATION: 'Director Review Investigation',
  COMMITEE_REVIEW_INVESTIGATION: 'Committe Review Investigation',
  COMMITEE_REVIEW: 'Committe Review',
  MINISTER_REVIEW_INVESTIGATION: 'Minister Review Investigation',
  MINISTER_REVIEW: 'Minister Review',
  COMPLIANCE_CHECK_AND_SCHEDULE_DATE: 'Compliance Check and Schedule Date',
  COMPLIANCE_CHECK: 'Compliance Check',
  PICA_SCHEDULE_DATE: 'Pica Schedule Date',
  COMPLIANCE_ISSUE_DIRECTOR_REVIEW: 'Compliance Issue Director Review',
  COMPLIANCE_ISSUE_RETURN_TO_PICA: 'Compliance Issue Return to PICA',
  PASSPORT_AFFIXED: 'Passport Affixed',
  PASSPORT_DISTRIBUTED: 'Passport Distributed',
  APPLICATION_DENIED: 'Application Denied',
  APPLICATION_APPROVED: 'Application Approved',
  WAITING_FOR_WP_FEE: 'Ready to Pay - Work Permit Fee',
  WP_FEES_RECEIVED: 'WP fees received',
  WP_ISSUED: 'Work Permit Issued',
  MARRIAGE_CERTIFICATE_ISSUED: 'Marriage Certificate Issued',
  CERTIFICATE_ISSUED: 'Certificate Issued',
  APPLIED_FOR_RENEWAL: 'Applied for Renewal',
  APPEALED: 'Appealed',
  APPOINTMENT_SCHEDULED: 'Appointment Scheduled',
  PICA_APPOINTMENT_SCHEDULED: 'PICA Appointment Scheduled',
  EXPIRED: 'Expired',
  EXPIRING_SOON: 'Expiring Soon',
  TERMINATION_REQUESTED: 'Termination Requested',
  TERMINATED: 'Terminated',
  CARD_DISTRIBUTED: 'Card Distributed',
  PROCESSING_OFFICER_REVIEW_QUALIFICATIONS:
    'Processing Officer Review Qualifications',
  PROCESSING_OFFICER_REVIEW_ORIGINAL_DOCUMENTS:
    'Processing Officer Review Original Documents',
  PROCESSING_OFFICER_GENERATE_CERTIFICATE:
    'Processing Officer Generate Certificate',
  MINISTER_SIGN_CERTIFICATE: 'Minister Sign Certificate',
  PROCESSING_OFFICER_APPLY_SEAL: 'Processing Officer Apply Seal',
  READY_FOR_PICKUP: 'Ready for Pickup',
  DISTRIBUTED: 'Distributed',
  UNDER_INVESTIGATION: 'Under Investigation',
  NOT_QUALIFIED: 'Not Qualified',
  CREATE_WPCARD_AND_SCHEDULE_APPOINTMENT:
    'Create WP Card and Schedule Appointment',

  // CSME Verification
  PICA_VERIFY_LANDING_STATUS_AND_INTERPOL_CHECK:
    'Pica Verify Landing Status and Interpol Check',
  PROCESSING_OFFCIER_VERIFY_CERTIFICATE_AUTHENTICITY:
    'Processing Offcier Verify Certificate Authenticity',
  MINISTER_SIGN_APPROVAL_LETTER: 'Minister Sign Approval Letter',
  DISTRIBUTE_LETTER: 'Distribute Letter',
  LETTER_DISTRIBUTED: 'Letter Distributed',

  //marriage
  DRAFT_LETTER_AND_SCHEDULE_APPOINTMENT:
    'Draft Letter And Schedule Appointment',
  DIRECTOR_REVIEW_DENIAL: 'Director Review Denial',
  MEETING_WITH_INVESTIGATING_OFFICER: 'Meeting with Investigation Officer',
  PICA_STAMP_PASSPORT: 'PICA Stamp Passport',
  MLSS_APPOINTMENT_SCHEDULED: 'MLSS Appointment Scheduled',
  PROCESSING_OFFICER_REVIEW_INVESTIGATION:
    'Processing Officer Review Investigation',
  SUBMITTING: 'Submitting',
  APPEAL_APPROVED: 'Appeal Approved',
  APPEAL_DENIED: 'Appeal Denied',

  //display status
  IN_PROCESS: 'In Process',
  DENIED: 'Application Denied',
  CARD_OR_CERTIFICATE_ISSUED: 'Application Processed',
  WORK_PERMIT_FEE_RECEIVED: 'Work Permit Fee Received',
  APPROVED: 'Application Approved',

  CREDENTIAL_DOES_NOT_EXIST: 'E-Ceritificate not issued',
};
