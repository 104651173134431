import { VApplication } from "../../../../../application";
import { LoggerModule } from "@vlinder-web/logger-module-react";
import { PaymentTransactionPageService  } from "../../../services";

const MODULE_NAME = "profile.machines.states.getPaymentTxnReport";
const log: any = LoggerModule?.getInstance();

const getSrv = () => {
  const app = VApplication.getInstance();
  return app.getSync("services.PaymentTransactionPageService") as PaymentTransactionPageService;
};

export const getPaymentTxnReport = async (context: any, event: any) => {
  const currentState = "DOWNLOAD_PAYMENT_CSV";
  const method = "activateUser";

  return new Promise(async (resolve, reject) => {
    try {
      log.blue(MODULE_NAME, "context: ", context, "event: ", event);
      const srv = getSrv();
      const content = await srv.agent.getPaymentTxnReport(event?.payload);
      if (content) {
        return resolve({
          key: "DOWNLOAD_PAYMENT_CSV_SUCCESS",
          value: {
            ...(content || {}),
          },
        });
      } else {
        const msg = "CSV download failed";
        log.error(MODULE_NAME, `error in ${method}, msg: ${msg}`);
        return reject({
          key: "DOWNLOAD_PAYMENT_CSV_FAILED",
          value: msg,
        });
      }
    } catch (err: any) {
      log.error(MODULE_NAME, `error in ${method}, msg: ${err?.message}`);
      return reject({
        key: "DOWNLOAD_PAYMENT_CSV_FAILED",
        value: err?.message,
      });
    }
  });
};
