import React from 'react';
import moment from 'moment';
import { APPLICATION_STATUS } from '@/config/applicationStatus.config';
export const getWidgetData = (data: any) => {

  
  const getFormattedStatus = (status: string,displayStatus: string) => {
    if (displayStatus === 'INCOMPLETE') {
      if (APPLICATION_STATUS?.[status]) {
        return APPLICATION_STATUS?.[status];
      }
    }
    if (displayStatus === 'PAYMENT_REQUIRED') {
      if (APPLICATION_STATUS?.[status]) {
        return APPLICATION_STATUS?.[status];
      }
    }
    
    else {if (APPLICATION_STATUS?.[displayStatus]) {
        return APPLICATION_STATUS?.[displayStatus];
      }
    }
    return '-';
  
  };
  const formatCreatedDate = (date: Date) => {
    if (date) {
      return moment(date).format('LLL');
    }
  };
  const formatString = (inputString: any) => {
    const stringWithSpaces = inputString.replace('_', ' ');
    const words = stringWithSpaces.split(' ');
    const pascalCaseWords = words.map((word: any) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    const formattedString = pascalCaseWords.join(' ');
    return formattedString;
  };
  let lastModified = formatCreatedDate(data?.updatedAt || '') || '';
  const widgetData = {
    profileData: {
      name: `${data?.applicantInfo?.lastName ?? ''} ${
        data?.applicantInfo?.firstName ?? ''
      }`,
      listItems: [
        {
          key: 'id',
          title: 'Application ID',
          subTitle: data?.applicationNo,
          icon: 'https://i.ibb.co/QH6mxdw/id-card-3.png',
        },
        {
          key: 'email',
          title: 'Email',
          subTitle: data?.applicantInfo?.email,
          icon: 'https://i.ibb.co/Wg4zMsq/email-2.png',
        },
        {
          key: 'status',
          title: 'Status',
          subTitle: getFormattedStatus(data?.status,data?.displayStatus),
          icon: 'https://i.ibb.co/rx6mPgs/status-1.png',
        },
        {
          key: 'lastModified',
          title: 'Last Modified on',
          subTitle: lastModified,
          icon: 'https://i.ibb.co/48t2Jzj/calendar-2.png',
        },
        {
          key: 'applicationType',
          title: 'Application Type',
          subTitle: formatString(data?.type || ''),
          icon: 'https://i.ibb.co/9g5t0Cx/documents-2.png',
        },
      ],
    },
  };
  return widgetData;
};
