export const newUserConfig = {
  FIELDS: [
    {
      type: 'text',
      compProps: {
        id: 'firstName',
        label: 'First Name',
        placeholder: 'Enter first name',
        required: true,
        errorText: 'First name is required',
      },
    },
    {
      type: 'text',
      compProps: {
        id: 'lastName',
        label: 'LastName',
        placeholder: 'Enter last name',
        required: true,
        errorText: 'Last name is required',
      },
    },
    {
      type: 'email',
      compProps: {
        id: 'email',
        label: 'Email',
        required: true,

        placeholder: 'Enter email',
        errorText: 'Email is required',
      },
    },
  ],
  layout: [['lastName'], ['firstName'], ['email'], ['sendInvite']],
};
