//@ts-nocheck
import { APPLICATION_STATUS } from '@/config/applicationStatus.config';
import { USER_LEVEL } from '@/config/userManagement.config';
import moment from 'moment';

export const getMarriageWidgetData = (props: {
  fields: any[];
  initialData: any;
  marriageExemptionFormData: any;
  timelinedata: any;
  data: any;
  applicationNo: any;
  credentialNo: any;
  from: any;
  whoAmI: any;
}) => {
  const {
    marriageExemptionFormData,
    timelinedata,
    data,
    applicationNo,
    from,
    initialData,
    credentialNo,
    fields,
    whoAmI,
  } = props;
  // console.log('initialData?._initialData-->Asdfc', initialData,data);
  const formatCreatedDate = (date: Date) => {
    if (date) {
      return moment(date).format('LLL');
    }
  };
  const formatString = (inputString: any) => {
    const stringWithSpaces = inputString.replace('_', ' ');
    const words = stringWithSpaces.split(' ');
    const pascalCaseWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    const formattedString = pascalCaseWords.join(' ');
    return formattedString;
  };
  const renderIf = (data: any, ele: any) => {
    console.log(data, 'lo->');
    if (Array.isArray(data) && data.length > 0) {
      return [ele];
    }
    return [];
  };

  const getFormattedStatus = (status: string,displayStatus: string) => {
    
    if (displayStatus === 'INCOMPLETE') {
      if (APPLICATION_STATUS?.[status]) {
        return APPLICATION_STATUS?.[status];
      }
    }
    if (displayStatus === 'PAYMENT_REQUIRED') {
      if (APPLICATION_STATUS?.[status]) {
        return APPLICATION_STATUS?.[status];
      }
    }
    if(!displayStatus){
      if (APPLICATION_STATUS?.[status]) {
        return APPLICATION_STATUS?.[status];
      }
    }
    else {if (APPLICATION_STATUS?.[displayStatus]) {
        return APPLICATION_STATUS?.[displayStatus];
      }
    }
    return '-';
  
  };
  const formData: any[] = [];
  const expatirateInfo: any[] = [];
  const jamaicanInfo: any[] = [];
  const marriageInfo: any[] = [];
  const fileData: any[] = [];
  let certificateData: any[] = [];

  const _specialData: any[] = [];
  const _specialNavItems: any[] = [];
  // let initialData = _initialData;
  
  let evidenceData: any[] = [];
  let evidenceformatdata: any[] = [];
  console.log('getwidgetdata-->1=-->', fields, initialData);
  let hiddenItems = {};
  let special = {
    refusedvisadetail: 'Refused Visa Details',
  };
  let evidenceDatacmt: any[] = [];
  let evidenceDataTitle = {
    deathCertificate: 'PREVIOUS_SPOUSE_DEATH_CERTIFICATE',
    jamaicandeathCertificate: 'JAMAICAN_PREVIOUS_SPOUSE_DEATH_CERTIFICATE',
    jobTitleProof: 'JOB_TITLE_PROOF',
    taxCompilance: 'TAX_COMPILANCE',
    applicantphotograph: 'APPLICANT_PHOTOGRAPH',
    divorceCertificate: 'DIVORCE_CERTIFICATE_PROOF',
    jamaicandivorceCertificate: 'JAMAICAN_DIVORCE_CERTIFICATE',
    expbirthcertificate: 'BIRTH_CERTIFICATE_PROOF',
    byAdvertisementFile: 'BY_ADVERTISEMENT_FILE',
    stepTakenProof: 'STEP_TAKEN_PROOF',
    signatureProof: 'EMPLOYER_SIGNATURE',
    birthCertificateProof: 'BIRTH_CERTIFICATE_PROOF',
    nationalityProof: 'NAIONALITY_PROOF',
    occupationProof: 'OCCUPATION_PROOF',
    passportProof: 'PASSPORT_PROOF',
    qualificationProof: 'QUALIFICATION_PROOF',
    workExperienceProof: 'WORK_EXPERIENCE_PROOF',
    photograph: 'PHOTOGRAPH',
    policeClearanceCertificate: 'POLICE_CLEARANCE_CERTIFICATE',
    resumeProof: 'RESUME_PROOF',
    coveringLetterProof: 'COVERING_LETTER_PROOF',
    previousEmployerProof: 'PREVIOUS_EMPLOYER_PROOF',
    signatureDep: 'APPLICANT_SIGNATURE',
    previousEmployerProofDep: 'PREVIOUS_EMPLOYER_PROOF_DEP',
    candidateResumes: 'CANDIDATE_RESUME',
    financialStatement: 'FINANCIAL_STATEMENT_PROOF',
    jamIdentificationProof: 'IDENTITY_COPY_PROOF',
    marriageCertificate: 'MARRIAGE_CERTIFICATE_PROOF',
    natCertificate: 'NATUARALIZATION_CERTIFICATE',
    birthCertificate: 'JAMAICAN_BIRTH_CERTIFICATE',
    biodatapassport: 'PASSPORT_PROOF',
    jamaicanspousephotograph: 'JAMAICAN_SPOUSE_PHOTOGRAPH',
  };
  // *******************************************************************************

  // *******************************************************************************
  // console.log("asfkjasfdhaskdjffassdf",initialData,initialData?._initialData)
  if (data?._initialData?.evidencedocuments) {
    // Object.entries(initialData._initialData?.evidencedocuments)?.forEach((item) => {
    //   if(item.length > 0){
    //     if(item[1].length>0)
    //     evidenceData=item[1];
    evidenceData = data?._initialData?.evidencedocuments;
    if (
      data?.appealInfo &&
      Array.isArray(data?.appealInfo) &&
      data?.appealInfo?.length > 0 &&
      data?.appealInfo[0]?.comment
    ) {
      evidenceDatacmt.push({
        title:
          data?.appealInfo[data?.appealInfo?.length - 1]
            ?.comment,
        // subTitle:'hii',
        icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
      });
    }
    if (Array.isArray(evidenceData)) {
      if (evidenceData?.length > 0) {
        console.log('service args container', evidenceData);
        for (let i = 0; i < evidenceData?.length; i++) {
          if (evidenceData[i]?.type === 'image/png') {
            evidenceformatdata.push({
              type: 'img',
              src: evidenceData[i]?.src,
              meta: [evidenceData[i]?.name, evidenceData[i]?.size],
              title:
                evidenceDataTitle?.[evidenceData[i]?.docType] ||
                'Evidence Data',
            });
          } else if (evidenceData[i]?.type === 'application/pdf') {
            evidenceformatdata.push({
              type: 'pdf',
              src: evidenceData[i]?.src,
              meta: [evidenceData[i]?.name, evidenceData[i]?.size],
              title:
                evidenceDataTitle?.[evidenceData[i]?.docType] ||
                'Evidence Data',
            });
          } else if (evidenceData[i]?.type === 'image/jpeg') {
            evidenceformatdata.push({
              type: 'img',
              src: evidenceData[i]?.src,
              meta: [evidenceData[i]?.name, evidenceData[i]?.size],
              title:
                evidenceDataTitle?.[evidenceData[i]?.docType] ||
                'Evidence Data',
            });
          }
        }
      }
    }
  }
  for (let step = 0; step < 3; step++) {
    console.log(step, fields, 'lp->', initialData);
    fields?.[step]?.forEach((_item: any) => {
      let ans = initialData?.[_item?.compProps?.id];
      if (
        special[_item?.compProps?.id] &&
        Array.isArray(ans) &&
        ans.length > 0
      ) {
        console.log('suspect->', ans);
        _specialNavItems.push({
          key: _item?.compProps?.id,
          title: special[_item?.compProps?.id],
          link: `/${_item?.compProps?.id}`,
          type: 'navbar',
        });
        const reqEle = _item?.compProps?.attributeForm?.fields;
        const totalData: any[] = [];
        console.log('liu->', ans);
        ans?.forEach((val) => {
          const currData: any[] = [];
          const currDocData: any[] = [];

          Object.entries(val)?.forEach((_item) => {
            const ans1 = reqEle?.filter(
              (element) => _item[0] === element?.compProps?.id
            )?.[0];
            console.log('suspect->->', ans1);
            if (_item[0] !== 'id' && ans1?.compProps?.label && _item[1]) {
              console.log('liop->hahahahaha', typeof _item[1]);
              if (typeof _item[1] === 'string') {
                console.log('suspect---->--->', _item[1]);
                currData.push({
                  title: ans1?.compProps?.label,
                  subTitle: _item[1],
                  icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
                });
              } else if (
                _item?.[1]?.[0]?.type === 'image/png' ||
                _item?.[1]?.[0]?.type === 'image/jpg' ||
                _item?.[1]?.[0]?.type === 'image/jpeg'
              ) {
                currDocData.push({
                  type: 'img',
                  src: _item?.[1]?.[0]?.src,
                  meta: [_item?.[1]?.[0]?.name, _item?.[1]?.[0]?.size],
                  title: [ans1?.compProps?.label],
                });
              } else if (_item?.[1]?.[0]?.type === 'application/pdf') {
                console.log('pdfcoming...');
                currDocData.push({
                  type: 'pdf',
                  src: _item?.[1]?.[0]?.src,
                  meta: [_item?.[1]?.[0]?.name, _item?.[1]?.[0]?.size],
                  title: [ans1?.compProps?.label],
                });
              }
            }
          });
          console.log('liop->', currData, currDocData);
          if (currDocData?.length === 0) {
            totalData.push(currData);
          } else {
            totalData.push({ doc: currDocData, data: currData });
          }
        });

        _specialData.push({
          type: 'accordion',
          key: _item?.compProps?.id,
          data: totalData?.map((data, key: number) => {
            if (data?.doc) {
              return [
                {
                  type: 'multi-col-list',
                  title: `${special[_item?.compProps?.id]} ${key + 1}`,
                  numberOfColumns: '3',
                  data: data?.data,
                },
                {
                  type: 'media-carousel',
                  key: `org-documents ${key + 1}`,
                  data: data?.doc,
                },
              ];
            }
            return {
              type: 'multi-col-list',
              title: `${special[_item?.compProps?.id]} ${key + 1}`,
              numberOfColumns: '3',
              data: data,
            };
          }),
        });
        console.log('firstans', ans);
      }
      if (step === 0) console.log('jramfinallog', _item, ans);
      console.log('asns', ans);
      const dynamicLayoutItem =
        marriageExemptionFormData?.dynamicLayout?.[_item?.compProps?.id];
      console.log('dynamicLayoutItem', dynamicLayoutItem);
      if (dynamicLayoutItem) {
        Object.entries(dynamicLayoutItem)
          ?.filter((item) => item?.[0] !== ans)
          ?.map((subItem) => {
            subItem[1] &&
              subItem[1]?.map((_subItem) => {
                let cpy = initialData[_subItem[0]];
                console.log('doubtforjram->', cpy);
                if (!(cpy && Array.isArray(cpy) && cpy?.length > 0)) {
                  hiddenItems[_subItem] = true;
                  // delete initialData[_subItem];
                }
              });
          });
      }
      if (Array.isArray(ans)) {
        if (ans?.length > 0 && !hiddenItems[_item?.compProps?.id]) {
          if (
            ans[0]?.type === 'image/png' ||
            ans[0]?.type === 'image/jpg' ||
            ans[0]?.type === 'image/jpeg'
          ) {
            console.log('hi0->', _item?.compProps?.id);
            fileData.push({
              type: 'img',
              src: ans[0]?.base64 ?? ans[0]?.src,
              meta: [ans[0]?.name, ans[0]?.size],
              title: [_item?.compProps?.label],
            });
          } else if (ans[0]?.type === 'application/pdf') {
            fileData.push({
              type: 'pdf',
              src: ans[0]?.base64 ?? ans[0]?.src,
              meta: [ans[0]?.name, ans[0]?.size],
              title: [_item?.compProps?.label],
            });
          }
        }
      } else {
        if (step === 0) {
          console.log('jramexpinfo-->', _item);
          if (
            _item?.compProps?.label &&
            ans &&
            _item?.compProps?.id &&
            !_item?.compProps?.id?.startsWith('is')
          ) {
            expatirateInfo.push({
              title: _item?.compProps?.label,
              subTitle: ans + '',
              icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
            });
          }
        }
        if (step === 1) {
          if (
            _item?.compProps?.label &&
            ans &&
            _item?.compProps?.id &&
            !_item?.compProps?.id?.startsWith('is')
          ) {
            jamaicanInfo.push({
              title: _item?.compProps?.label,
              subTitle: ans + '',
              icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
            });
          }
        }
        if (step === 2) {
          if (
            _item?.compProps?.label &&
            ans &&
            _item?.compProps?.id &&
            !_item?.compProps?.id?.startsWith('is')
          ) {
            marriageInfo.push({
              title: _item?.compProps?.label,
              subTitle: ans + '',
              icon: 'https://i.ibb.co/ydYfzdV/check-mark-1.png',
            });
          }
        }
      }
    });
  }
  console.log(
    'getwidgetdata-->2-->',
    expatirateInfo,
    jamaicanInfo,
    marriageInfo
  );
  console.log(initialData, 'chao->');
  // const userData = data?.firstName
  //   ? {
  //       profileImage: data?.photograph?.[0]?.base64,
  //       name: `${data?.lastName ?? ''} ${data?.firstName ?? ''}`,
  //       listItems: [
  //         {
  //           key: 'name',
  //           title: 'Name',
  //           subTitle: data?.lastName ?? data?.firstName,
  //           icon: 'https://w7.pngwing.com/pngs/930/532/png-transparent-computer-icons-personal-web-page-user-name-icon-monochrome-user-tag.png',
  //         },
  //         // {
  //         //   key: 'trn',
  //         //   title: 'TRN',
  //         //   subTitle: initialData?.businessTrn,
  //         //   icon: 'https://w7.pngwing.com/pngs/930/532/png-transparent-computer-icons-personal-web-page-user-name-icon-monochrome-user-tag.png',
  //         // },
  //       ],
  //     }
  //   : {};
  if (Array.isArray(initialData?.certificate)) {
    const ans=initialData?.certificate;
    if (ans?.length > 0) {
      if (
        ans[0]?.type === 'image/png' ||
        ans[0]?.type === 'image/jpg' ||
        ans[0]?.type === 'image/jpeg'
      ) {
        certificateData.push({
          type: 'img',
          src: ans[0]?.src,
          meta: [ans[0]?.name, ans[0]?.size],
          title: [_item?.compProps?.label],
        });
      } else if (ans[0]?.type === 'application/pdf') {
        console.log('pdfcoming...');
        certificateData.push({
          type: 'pdf',
          src: ans[0]?.src,
          meta: [ans[0]?.name, ans[0]?.size],
          title: [_item?.compProps?.label],
        });
      }
    }
  }
  const profileData = data?._marriageInitialData;
  let appid = applicationNo ?? credentialNo;
  let lastModified = formatCreatedDate(data?.updatedAt || '') || '';
  let issuedon = formatCreatedDate(data?.issuedon || '') || '';
  const certificateItems = data?.certitrn
    ? [
        {
          key: 'trn',
          title: 'TRN',
          subTitle: data?.certitrn,
          icon: 'https://i.ibb.co/J7LRK9g/tax-1.png',
        },
      ]
    : [];
  console.log('jramprofile->_->', profileData,data?._initialData?.comment,data?.status);
  const widgetData = {
    profileData: {
      ...(data?.status === 'EXPIRING_SOON' || data?.status === 'EXPIRED'
      ? { submitBtnText: 'Renew' }
      : data?.status === 'READY_TO_PAY' ||
      data?.status === 'APPLICATION_APPROVED' ||
      data?.status === 'WAITING_FOR_WP_FEE'
      ? { submitBtnText: 'Proceed to Pay' }
      : data?.status === 'APPLICATION_DENIED' && data?.status !== 'APPEALED'
      ? {
        statusProps: {
          statusComp: {
            statusType: 'danger',
            title: data?._initialData?.comment
                  ? `Comment : ${data?._initialData?.comment}`
                  : '',
                },
              },
              submitBtnText: 'Appeal',
            }
        : data?.status === 'APPEALED'
        ? {
            statusProps: {
              statusComp: {
                statusType: 'primary',
                title: 'Appealed',
              },
            },
          }
          : data?.status === 'DOCUMENTATION_REJECTED'
          ? {
            statusProps: {
              statusComp: {
                statusType: 'danger',
                title: data?._initialData?.comment
                ? `Comments : ${data?._initialData?.comment}`
                : null,
              },
            },
            submitBtnText: 'Update Documents',
          }
        : data?.status === 'MISSING_INFORMATION'
        ? { statusProps: {
          statusComp: {
            statusType: 'danger',
            title: data?._initialData?.comment
            ? `Comments : ${data?._initialData?.comment}`
            : null,
          },
        },
        submitBtnText: 'Update Missing Information' }
        : {}),
        // ...(from === 'renew' &&
        // ((data?.status !== 'EXPIRED' &&
        //   USER_LEVEL.owner.includes(whoAmI) &&
        //   data?.status !== 'TERMINATED') ||
        //   data?.status === 'EXPIRING_SOON')
        //   ? { terminateText: 'Terminate' }
        //   : data?.status === 'TERMINATED'
        //   ? {
          //       statusProps: {
      //         statusComp: {
      //           statusType: 'danger',
      //           title: `Terminated - ${data?.terminateReason}`,
      //         },
      //       },
      //     }
      //   : {}),
      // ...userData,
      downloadButtonText:"Download",
      activeKey: 'expatriate-info',
      profileImage: profileData?.photograph
        ? Array.isArray(profileData?.photograph) &&
          profileData?.photograph?.length > 0 &&
          profileData?.photograph?.[0]?.src
        : '',
      name: `${profileData?.lastName ?? ''}, ${profileData?.firstName ?? ''}`,
      isDetail: true,
      listItems: [
        {
          key: 'id',
          title: 'Application ID',
          subTitle: applicationNo ?? credentialNo,
          icon: 'https://i.ibb.co/QH6mxdw/id-card-3.png',
        },
        (credentialNo) || issuedon
          ? {
              key: 'issuedOn',
              title: 'Issued on',
              subTitle: issuedon || '-',
              icon: 'https://i.ibb.co/48t2Jzj/calendar-2.png',
            }
          : {
              key: 'lastModified',
              title: 'Last Modified on',
              subTitle: lastModified || '-',
              icon: 'https://i.ibb.co/48t2Jzj/calendar-2.png',
            },
        {
          key: 'status',
          title: 'Status',
          subTitle: getFormattedStatus(data?.status,data?.displayStatus),
          icon: 'https://i.ibb.co/rx6mPgs/status-1.png',
        },
        initialData?.type === 'WORK_PERMIT'
          ? {
              key: 'applicationType',
              title: 'Application Type',
              subTitle: 'Work Permit',
              icon: 'https://i.ibb.co/9g5t0Cx/documents-2.png',
            }
          : initialData?.type === "MARRIAGE_EXEMPTION"
          ? {
              key: 'applicationType',
              title: 'Application Type',
              subTitle: 'New Marriage Exemption',
              icon: 'https://i.ibb.co/9g5t0Cx/documents-2.png',
            }
          : initialData?.type === "RENEW_MARRIAGE_EXEMPTION"
          ? {
              key: 'applicationType',
              title: 'Application Type',
              subTitle: 'Renew Marriage Exemption',
              icon: 'https://i.ibb.co/9g5t0Cx/documents-2.png',
            }
          : data?.certitrn
          ? {
              key: 'applicationType',
              title: 'Application Type',
              subTitle: 'CSME',
              icon: 'https://i.ibb.co/9g5t0Cx/documents-2.png',
            }
          : {
              key: 'applicationType',
              title: 'Application Type',
              subTitle: formatString(initialData?.type || '') || '-',
              icon: 'https://i.ibb.co/9g5t0Cx/documents-2.png',
            },
        // ...additionalItems,
        ...certificateItems,
      ],

      // submitBtnText: 'Submit',
      // checkBoxText:
      //   'I certify to the best of my knowledge and belief, that the above information is correct.',
      navItems: [
        ...renderIf(certificateData,{
          key: 'certificate',
          title: 'Certificate',
          link: '/certificate',
          type: 'navbar',
        }),
        {
          key: 'expatriate-info',
          title: 'Expatriate Information',
          link: '/expatriate-info',
          type: 'navbar',
        },
        {
          key: 'jamaican-info',
          title: 'Jamaican Information',
          link: '/jamaican-info',
          type: 'navbar',
        },
        {
          key: 'marriage-info',
          title: 'Marriage Information',
          link: '/marriage-info',
          type: 'navbar',
        },
        ..._specialNavItems,
        {
          key: 'media-carousel',
          title: 'Documents',
          link: '/documents',
          type: 'navbar',
        },
        ...renderIf(timelinedata, {
          key: 'timeline',
          title: 'Timeline',
          link: '/timeline',
          type: 'navbar',
        }),
        ...renderIf(evidenceData, {
          key: 'appeal-details',
          title: 'Appeal details',
          link: '/appeal-details',
          type: 'navbar',
        }),
      ],
    },
    itemSpecData: [
      {
        type: 'media-carousel',
        key: 'certificate',
        data: certificateData,
      },
      {
        type: 'multi-col-list',
        key: 'expatriate-info',
        numberOfColumns: '3',
        data: expatirateInfo,
      },
      {
        type: 'multi-col-list',
        key: 'jamaican-info',
        numberOfColumns: '3',
        data: jamaicanInfo,
      },
      {
        type: 'multi-col-list',
        key: 'marriage-info',
        numberOfColumns: '3',
        data: marriageInfo,
      },

      ..._specialData,
      {
        type: 'media-carousel',
        key: 'media-carousel',
        data: fileData,
      },
      {
        type: 'timeline',
        key: 'timeline',
        data: timelinedata,
      },
      {
        type: 'accordion',
        key: 'appeal-details',
        data: [
          {
            type: 'multi-col-list',
            title: `Appeal Comment`,
            numberOfColumns: '3',
            data: evidenceDatacmt || [],
          },
          {
            type: 'media-carousel',
            title: `Appeal Documents`,
            data: evidenceformatdata,
          },
        ],
      },
    ],
    paginationProps: {
      totalCount: 2,
      totalPages: 1,
      forcePage: 0,
      totalItems: 2,
    },
  };
  console.log('lo->', widgetData);

  return widgetData;
};
