import React, { useEffect } from 'react';

import { useMachine } from '@xstate/react';
import { createMachine } from 'xstate';

import { actions } from './actions';
import { MACHINE_CONFIG } from './config';
import { services } from './services';

export const resetPasswordContext = React.createContext({});
export let resetPasswordMachine: any;

const MODULE_NAME = 'machines.resetPassword';

export const useSMResetPassword = (_context: any) => {
  let _machineConfig: any = MACHINE_CONFIG;
  if (_machineConfig && _machineConfig?.context) {
    _machineConfig.context = {
      ..._machineConfig.context,
      ...(_context || {}),
    };
  }
  resetPasswordMachine = createMachine(_machineConfig, {
    services,
    actions,
  });
  const [state, send] = useMachine(resetPasswordMachine);

  return {
    resetPasswordMachine,
    resetPasswordState: state,
    resetPasswordSend: send,
  };
};
